import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { IIndividualReportCard } from '../../types'
import { getToken } from '../../utils/auth'
import Card from '../Card/Card'
import CardButton from '../CardButton'
import CardDetails from '../CardDetails'
import CardSubtitle from '../ExamDashboardComponents/CardSubtitle'
import CardTitle from '../ExamDashboardComponents/CardTitle'

type ReportCardProps = {
  className?: string
  individualReportCard: IIndividualReportCard
}

const ReportCard = ({ className, individualReportCard }: ReportCardProps) => {
  const hierarchy = individualReportCard.user.extra?.hierarchy

  return (
    <Card className={className}>
      <div>
        <CardTitle>
          <Link to={`/report-cards/individual/${individualReportCard.id}`}>
            {individualReportCard.user.name}
          </Link>
          {individualReportCard.pdfUrl && (
            <CardButton
              icon="print"
              onClick={() =>
                individualReportCard.pdfUrl &&
                window.open(`${individualReportCard.pdfUrl}?jwt=${getToken()}`)
              }
              data-testid="download-report-card-pdf"
            />
          )}
        </CardTitle>
        <CardSubtitle>{individualReportCard.collection.name}</CardSubtitle>
        <CardDetails className="mb-0">
          {hierarchy &&
            Object.values(hierarchy)
              .map((d) => d.name)
              .join(' • ')}
        </CardDetails>
      </div>
    </Card>
  )
}

export default styled(ReportCard)`
  ${CardTitle} {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`
