import React from 'react'
import { useTranslation } from 'react-i18next'

const ResultsFound = ({ resultsFound }: { resultsFound: number }) => {
  const { t } = useTranslation()
  const msg = resultsFound === 1 ? t('result found') : t('results found')
  return (
    <div>
      {resultsFound.toLocaleString()} {msg}
    </div>
  )
}

export default ResultsFound
