import React from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar } from '@fortawesome/pro-regular-svg-icons'
import { useTranslation } from 'react-i18next'
import { RoomRecord } from 'types'
import moment from 'moment'

type RoomRecordModalCardProps = {
  className?: string
  roomRecord: RoomRecord
}

const RoomRecordModalCard = ({
  className,
  roomRecord
}: RoomRecordModalCardProps) => {
  const { t } = useTranslation()

  return (
    <div className={className}>
      <div className="card-date">
        <FontAwesomeIcon icon={faCalendar}></FontAwesomeIcon>
        <p>{moment(roomRecord.createdAt).format('DD/MM/YYYY HH:mm:ss')}</p>
        <p>{' - '}</p>
        <p>{roomRecord.user.name}</p>
      </div>
      <div className="card-description">
        <p className="card-description-label">{t('Record description')}: </p>
        {roomRecord.description}
      </div>
    </div>
  )
}

export default styled(RoomRecordModalCard)`
  border: 1px solid #cecece;
  padding: 15px;
  color: #363636;
  font-size: 15px;

  .card-date {
    display: flex;
    align-items: center;
  }

  .card-date > p {
    margin: 0;
    margin-left: 5px;
    font-weight: 300;
  }

  .card-description {
    display: grid;
    grid-template-columns: 30% 70%;
    align-items: center;
    word-break: break-all;
  }

  .card-description > p.card-description-label {
    font-weight: bold;
  }
`
