import React from 'react'
import styled from 'styled-components'
import { IAnswer, IApplication } from '../types'
import CircledLink from './CircledLink'

type AnswerReviewListItemProps = {
  className?: string
  answer: IAnswer
  application?: IApplication
}

const AnswerReviewListItem = ({
  application,
  answer,
  className
}: AnswerReviewListItemProps) => {
  const { alternative, freeResponse } = answer

  let answerLabel: string | undefined
  if (alternative) {
    answerLabel = alternative.letter.toUpperCase()
  } else if (freeResponse) {
    answerLabel = `${freeResponse.substring(0, 20)}...`
  } else {
    answerLabel = '--'
  }

  return (
    <span className={className}>
      <CircledLink to={`/applications/${application?.id}/answers/${answer.id}`}>
        {answer.position}
      </CircledLink>
      <span>{answerLabel}</span>
    </span>
  )
}

export default styled(AnswerReviewListItem)`
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    font-size: 15pt;
    font-weight: 500;
    margin-left: 10px;
  }
`
