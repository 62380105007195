import React, { useCallback, useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import { AuthContext } from '../states/AuthState'
import NewSession from './NewSession'

const ProviderLogin = () => {
  const { provider, token } = useParams()
  const {
    isSubmitting,
    providerLogin,
    loginError,
    detailedErrors,
    showNewSessionPage
  } = useContext(AuthContext)
  const history = useHistory()
  const { t } = useTranslation()

  const handleProvider = useCallback(
    async (setNewClient = false) => {
      if (!provider || !token) {
        return
      }
      const authenticated = await providerLogin({
        setNewClient,
        provider,
        providerToken: token
      })
      if (authenticated) {
        history.push('/')
      }
    },
    [history, provider, token, providerLogin]
  )

  useEffect(() => {
    handleProvider()
  }, [handleProvider])

  if (showNewSessionPage) {
    return <NewSession onConfirm={() => handleProvider(true)} />
  }

  return (
    <div className="provider-login">
      {isSubmitting && <p>{t('Authenticating')}...</p>}
      {loginError && (
        <p>
          {detailedErrors?.nonFieldErrors ||
            detailedErrors?.token ||
            t('An error ocurred while authenticating.')}
        </p>
      )}
    </div>
  )
}

export default ProviderLogin
