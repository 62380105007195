import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

interface IconButtonProps {
  className?: string
  label: string
  link: string
  icon: IconProp
}

const IconButton = ({ className, label, link, icon }: IconButtonProps) => {
  const { t } = useTranslation()

  return (
    <div className={className}>
      <a href={link}>
        <FontAwesomeIcon icon={icon} size="8x" />
        <p>
          {t('Download')} {label}
        </p>
      </a>
    </div>
  )
}

export default styled(IconButton)`
  padding: 2rem;
  color: ${(props) => props.theme.brandColors[`${props.label}`]};
  align-self: center;

  a {
    text-decoration: none;
    border-radius: 100%;
    color: ${(props) => props.theme.brandColors[`${props.label}`]};
  }

  p {
    max-width: 10rem;
    border: 2px solid;
    border-radius: 50px;
    padding: 1rem 2rem;
    margin-top: 1rem;
    &:hover {
      box-shadow: 0 5px 7px 0 rgba(0, 0, 0, 0.15);
      cursor: pointer;
      transition: all 0.3s;
    }
  }
`
