import React from 'react'
import styled from 'styled-components'
import { ToastContainer, ToastContainerProps } from 'react-toastify'

const StyledToastContainer = ({
  className,
  ...rest
}: ToastContainerProps & { className?: string }) => {
  return (
    <div className={className}>
      <ToastContainer {...rest} />
    </div>
  )
}

export default styled(StyledToastContainer)`
  .Toastify__toast-container {
  }
  .Toastify__toast {
    border-radius: 7px !important;
    height: 75px !important;
    box-shadow: 0px 5px 5px 0px rgba(17, 17, 17, 0.5) !important;
    outline-right: 1rem solid white;
    padding: 0px !important;

    &-body {
      padding-left: 1rem !important;
      padding-right: 1rem !important;
      padding-top: 1rem !important;
      margin: 0px !important;
    }
  }
  .Toastify__toast--error {
  }
  .Toastify__toast--warning {
  }
  .Toastify__toast--success {
    background: #53b058 !important;
  }

  .Toastify__toast-container--top-right {
    top: 8em !important;
    right: 5em !important;
  }

  .Toastify__close-button {
    color: #033849;
    height: 100% !important;
    background: white;
    padding: 2px !important;
    margin: 0 !important;

    &:hover {
      opacity: 0.5;
    }
  }
`
