import styled from 'styled-components'

export default styled.button`
  padding: 5px;
  background-color: ${(props) => props.theme.colors.grayLight};
  color: ${(props) => props.theme.clientColors.primary};
  font-size: 15pt;
  border-radius: 50%;
  text-decoration: none;
  align-items: center;
  justify-content: center;
  display: flex;
  width: 2rem;
  height: 2rem;
  border: none;
  box-shadow: none;
  cursor: pointer;

  :hover,
  &.active {
    background-color: ${(props) => props.theme.clientColors.primary};
    color: ${(props) => props.theme.colors.grayLight};
  }

  &.highlighted:not(.active) {
    background-color: ${(props) => props.theme.colors.grayMedium};
    color: ${(props) => props.theme.clientColors.primary};
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    color: ${(props) => props.theme.colors.grayMedium};
  }
`
