import axios from 'axios'
import React, {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useReducer,
  useContext
} from 'react'
import { API_HOST } from '../consts'
import {
  childrenIsFunction,
  DashboardVideosStateAction,
  IDashboardVideosState
} from '../types'
import { DashboardVideosSocketContext } from './DashboardVideosSocketState'
const reducer = (
  state: IDashboardVideosState,
  action: DashboardVideosStateAction
): IDashboardVideosState => {
  switch (action.type) {
    case 'FETCH_APPLICATIONS':
      return { ...state, fetching: true, fetchError: '' }
    case 'FETCH_APPLICATIONS_SUCCESS':
      return {
        ...state,
        fetching: false,
        fetchError: '',
        applications: action.payload.applications,
        roomId: action.payload.roomId
      }
    case 'FETCH_APPLICATIONS_ERROR':
      return { ...state, fetching: false, fetchError: action.payload }
    default:
      return state
  }
}

const initialState = {
  fetching: false,
  applications: [],
  fetchError: '',
  roomId: -1,
  socket: null,
  roomData: null,
  pauseCandidateExam: () => {},
  resumeCandidateExam: () => {},
  sendAlert: () => {},
  candidatesConnectionStatus: {},
  candidatesMessageStatus: {},
  candidatesActivityStatus: {},
  candidatesBreakInfo: {},
  setMessageAsRead: () => undefined,
  setActivityAsRead: () => undefined,
  sendBreakResponse: () => undefined
}

export const DashboardVideosContext = createContext<IDashboardVideosState>(
  initialState
)

type DashboardVideosStateProps = {
  roomId: number
  children: Function | ReactNode
}

const DashboardVideosState = ({
  roomId,
  children
}: DashboardVideosStateProps) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const { joinRoomAsApplicator, socket } = useContext(
    DashboardVideosSocketContext
  )

  const fetch = useCallback(async () => {
    dispatch({ type: 'FETCH_APPLICATIONS' })
    try {
      const response = await axios.get(`${API_HOST}/v1/applications`, {
        params: { room: roomId, page_size: 1000 }
      })
      dispatch({
        type: 'FETCH_APPLICATIONS_SUCCESS',
        payload: { applications: response.data.results, roomId }
      })
      joinRoomAsApplicator(roomId)
    } catch (error) {
      dispatch({ type: 'FETCH_APPLICATIONS_ERROR', payload: error.toString() })
    }
  }, [socket, roomId])

  useEffect(() => {
    fetch()
  }, [fetch])

  const contextValue = {
    applications: [],
    fetching: false,
    fetchError: '',
    roomId,
    ...state
  }

  return (
    <DashboardVideosContext.Provider value={contextValue}>
      {childrenIsFunction(children) ? children(contextValue) : children}
    </DashboardVideosContext.Provider>
  )
}

export default DashboardVideosState
