import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios'
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import Button from '../components/Button'
import ToggleMenuButton from '../components/Buttons/ToggleMenuButton'
import { API_HOST } from '../consts'
import { ModalContext } from '../states/ModalState'
import { IExam, IExamDashboardApplication } from '../types'
import ApplicationStatus from './ApplicationStatus'
import Card from './Card/Card'
import ColorfulProgressBar from './ColorfulProgressBar'
import CardFooter from './ExamDashboardComponents/CardFooter'
import CardSubtitle from './ExamDashboardComponents/CardSubtitle'
import CardTitle from './ExamDashboardComponents/CardTitle'
import NextTimeWindow from './ExamDashboardComponents/NextTimeWindow'
import SetExamDateModal from './ExamDashboardComponents/SetExamDateModal'
import SubtitleDisplayIdentifier from './ExamDashboardComponents/SubtitleDisplayIdentifier'
import ProfilePicture from './ProfilePicture'
import Modal from './Modal/Modal'
import { toast } from 'react-toastify'
import moment from 'moment'
import { Tooltip } from '@material-ui/core'
import { getStatusLabel } from 'data/utils/applications'
import Flex from './Flex'
import ExamDashboardContext from 'contexts/ExamDashboardContext'

type ExamDashbhoardApplicationCardProps = {
  className?: string
  application: IExamDashboardApplication
  exam: IExam | undefined
}

const ExamDashbhoardApplicationCard = ({
  application,
  className
}: ExamDashbhoardApplicationCardProps) => {
  const { t } = useTranslation()
  const [alreadyReset, setAlreadyReset] = useState(false)
  const { showModal } = useContext(ModalContext)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { fetchApplications } = useContext(ExamDashboardContext)

  let grades = application.grades
  if (!grades) {
    grades = {
      correct: 0,
      wrong: 0,
      total: 0
    }
  }

  const examCanBeReset = () => {
    if (alreadyReset) {
      return false
    }
    const resetableExamStatuses = ['STARTED', 'FINISHED']
    return resetableExamStatuses.includes(application.status)
  }

  const collectionIsFinished = () => {
    const collectionFinishDate = application.exam.collection.endTime
    const now = moment(new Date())
    const collectionEnd = moment(collectionFinishDate)

    return collectionEnd.isSameOrBefore(now)
  }

  const examCanHaveDateChanged = () => {
    const changeableStatuses = ['AVAILABLE', 'UNAVAILABLE']
    return changeableStatuses.includes(application.status)
  }

  const handleResetApplication = async (clearAnswers) => {
    setIsModalOpen(false)
    try {
      await axios.post(`${API_HOST}/v1/applications/${application.id}/reset`, {
        clearAnswers
      })
      toast.success(
        clearAnswers
          ? t('Exam was reset successfully')
          : t('Exam time was reset successfully')
      )
      setAlreadyReset(true)
    } catch {
      toast.error(t('An error occurred. Please try again.'))
    }
  }

  const handleSetExamDateSubmit = (values: any): Promise<any> => {
    return axios
      .post(
        `${API_HOST}/v1/applications/${application.id}/set_application_dates`,
        values
      )
      .then(() => {
        fetchApplications(false)
      })
  }

  return (
    <>
      <Modal
        isOpen={isModalOpen}
        cancelText="Cancelar"
        actionText="OK"
        onAction={() => handleResetApplication(false)}
        onClose={() => setIsModalOpen(false)}
        onCancel={() => setIsModalOpen(false)}
        title={t('Are you sure you want to reset the time for this exam?')}
        /**
         * This comment was made in order to keep the future expected behavior
         */
        // title={false ? t('Are you sure you want to delete this exam?') : t('Are you sure you want to reset the time for this exam?')}
      >
        {application.user.name && ' ' && application.exam.name}
      </Modal>
      <Card className={className}>
        <ProfilePicture user={application.user} />
        <div>
          <CardTitle>
            {application.user.name}
            <span className="subtitle-small">{application.exam.name}</span>
            <Flex>
              <ApplicationStatus
                status={application.status}
                reseted={application.reseted}
              />
              <ToggleMenuButton disabled={collectionIsFinished()}>
                <Button
                  action="unstyled"
                  type="button"
                  disabled={!examCanBeReset()}
                  onClick={() => setIsModalOpen(true)}
                >
                  {t('Reset time')}
                </Button>
                <Tooltip
                  title={
                    !examCanHaveDateChanged()
                      ? `Aplicação ${t(
                          getStatusLabel(application.status)
                        ).toLowerCase()} não
                      pode ter a data modificada`
                      : ''
                  }
                >
                  <Button
                    action="unstyled"
                    type="button"
                    disabled={!examCanHaveDateChanged()}
                    onClick={() =>
                      showModal(() => (
                        <SetExamDateModal
                          onSubmit={handleSetExamDateSubmit}
                          application={application}
                        />
                      ))
                    }
                  >
                    {t('Set exam date')}
                  </Button>
                </Tooltip>
              </ToggleMenuButton>
            </Flex>
          </CardTitle>
          <CardSubtitle>
            <NextTimeWindow application={application}></NextTimeWindow>
            <SubtitleDisplayIdentifier
              publicIdentifier={application.user.publicIdentifier}
            />
          </CardSubtitle>
          <CardFooter application={application}>
            <div className="grades">
              <span>
                <FontAwesomeIcon icon="check" /> {t('Correct')}:{' '}
                {grades.correct}
              </span>
              <span>
                <FontAwesomeIcon icon="times" /> {t('Wrong')}: {grades.wrong}
              </span>
              <ColorfulProgressBar
                showProgressValues
                value={grades.correct + grades.wrong}
                max={grades.total}
              />
            </div>
          </CardFooter>
        </div>
      </Card>
    </>
  )
}

export default styled(ExamDashbhoardApplicationCard)`
  display: grid;
  grid-template-columns: 55px auto;
  grid-column-gap: 1rem;

  ${CardTitle} {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 19px;
    ${ApplicationStatus} {
      margin-right: 5px;
    }
    div {
      margin-left: 1rem;
    }
  }
  ${CardSubtitle} {
    display: grid;
    justify-content: space-between;
    margin-top: 5px;
    align-items: baseline;
    font-size: 12px;
  }

  ${CardFooter} {
    .grades {
      span {
        font-weight: 600;
        margin-right: 1rem;
      }

      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 1rem;
    }
  }

  .subtitle-small {
    font-size: 13px !important;
  }
`
