import React, { useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'

type ErrorWithMessageProps = {
  message: string
  className?: string
}

const ErrorWithMessage = ({ message, className }: ErrorWithMessageProps) => {
  const theme = useContext(ThemeContext)

  return (
    <div className={className}>
      <img alt="error" src={theme.errorWithoutSign} />
      <h1>{message}</h1>
    </div>
  )
}

export default styled(ErrorWithMessage)`
  text-align: center;
`
