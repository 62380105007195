import styled from 'styled-components'
import Page from './Page'

const ListPage = styled(Page)`
  display: grid;
  grid-template-columns: 300px auto;
  column-gap: 20px;
`

export default ListPage
