import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

type SeeResultsLinkProps = {
  reportCardId?: number
  className?: string
}

const SeeResultsLink = ({ reportCardId, className }: SeeResultsLinkProps) => {
  const { t } = useTranslation()

  if (!reportCardId) {
    return <span></span>
  }

  const resultsLink = `/report-cards/individual/${reportCardId}`

  return (
    <a className={className} href={resultsLink}>
      {t('See results')}
    </a>
  )
}

export default styled(SeeResultsLink)`
  font-weight: bold;
  color: ${(props) => props.theme.applicationStatus.AVAILABLE};
`
