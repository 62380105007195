import React from 'react'
import styled from 'styled-components'
import { useField, useFormikContext } from 'formik'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

type EnhancedTextInputProps = {
  name: string
  label?: string
  className?: string
  onChange?: any
  required?: boolean
  disabled?: boolean
}

const EnhancedTextInput = ({
  onChange,
  required,
  label,
  disabled,
  className,
  ...props
}: EnhancedTextInputProps) => {
  const [field, meta] = useField(props)
  const { setFieldValue } = useFormikContext()

  return (
    <div className={className}>
      <div className="input-type-style">
        <ReactQuill
          {...props}
          theme="snow"
          value={field.value}
          defaultValue=""
          readOnly={disabled}
          onChange={(value) => {
            setFieldValue(field.name, value)
            if (onChange) {
              onChange(value)
            }
          }}
        />
        <label
          htmlFor={field.name}
          className={`
            input-label-style
            ${meta.error && meta.touched ? 'red' : 'dim'}
            ${required ? 'input-required' : ''}
          `}
        >
          {label}
        </label>
      </div>
    </div>
  )
}

export default styled(EnhancedTextInput)`
  .input-type-style {
    margin-top: 2rem;
    position: relative;
  }
  .input-type-style .input-style,
  .input-type-style .input-error {
    width: 100%;
  }

  .input-type-style .input-style .quill .ql-toolbar,
  .input-type-style .input-error .quill .ql-toolbar {
    border-top-color: #ff5046;
    border-right-color: #ff5046;
    border-left-color: #ff5046;
  }
  .input-type-style .input-style .quill .ql-container,
  .input-type-style .input-error .quill .ql-container {
    border-left-color: #ff5046;
    border-right-color: #ff5046;
    border-bottom-color: #ff5046;
  }
  .input-type-style.input-type-border .input-label-style {
    padding-bottom: 2rem;
  }
  .input-type-style.input-type-border .input-error {
    border: 2px solid #ff5046;
  }
  .input-type-style .quill .ql-toolbar {
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
  }
  .input-type-style .quill .ql-container {
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
  }
  .input-type-style .input-style,
  .input-type-style .input-error,
  .input-type-style .dropdown__control {
    border: none;
    position: relative;
  }
  .input-type-style .input-style input,
  .input-type-style .input-error input,
  .input-type-style .dropdown__control input {
    border: none;
    width: 100%;
    background: transparent;
    font-family: 'Open Sans', sans-serif;
  }
  .input-type-style .input-style:hover:before,
  .input-type-style .input-error:hover:before,
  .input-type-style .dropdown__control:hover:before {
    border-bottom: 2px solid #3f928b;
  }
  .input-type-style .input-style:focus-within:after,
  .input-type-style .input-error:focus-within:after,
  .input-type-style .dropdown__control:focus-within:after {
    transform: scaleX(1);
  }
  .input-type-style .input-label-style {
    padding-bottom: 1rem;
    font-size: 18px;
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-10%, -55%) scale(0.9);
    font-weight: 600;
    margin-left: 4px;
  }
  .input-type-style .input-label-style.text-area-label {
    font-size: 14px;
    position: relative;
    margin-left: 0;
  }
`
