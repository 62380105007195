import React, { useContext } from 'react'
import styled from 'styled-components'
import axios from 'axios'
import { useHistory } from 'react-router-dom'
import { Formik, FormikProps } from 'formik'
import { useTranslation } from 'react-i18next'
import { EmailContext } from '../../states/EmailState'
import InputSelect from '../Inputs/InputSelect'
import InputText from '../Inputs/InputText'
import EnhancedTextInput from '../Inputs/EnhancedTextInput'
import Button from '../Button'
import Tag from '../Tag'
import { IEmailTemplate, IUser } from 'types'
import { EMAIL_API_HOST, EMAIL_PARTNER_TOKEN } from '../../consts'
import { toast } from 'react-toastify'

type EmailRecipientFormProps = {
  className?: string
  users?: IUser[]
}

type FormikInitialValues = {
  template: IEmailTemplate
  subject: string
  body: string
  users: IUser[]
}

const EmailRecipientForm = ({ className, users }: EmailRecipientFormProps) => {
  const { templates, templateLoading } = useContext(EmailContext)
  const initialValues: FormikInitialValues = {
    template: null,
    subject: '',
    body: '',
    users
  }
  const history = useHistory()
  const { t } = useTranslation()

  const handleTemplateSelection = (
    formik: FormikProps<FormikInitialValues>,
    value: IEmailTemplate
  ) => {
    formik.setValues({
      template: value,
      subject: value.subject,
      body: value.body,
      users
    })
  }

  const emailFactory = (emailData: FormikInitialValues) => {
    return {
      body: emailData.body,
      subject: emailData.subject,
      typeId: emailData.template.id,
      recipients: emailData.users.map((user) => {
        return {
          email: user.email,
          context: {
            name: user.name,
            email: user.email,
            public_identifier: user.publicIdentifier,
            access_url: user.access_url
          }
        }
      })
    }
  }

  const getEmailHeaders = () => {
    return {
      Authorization: EMAIL_PARTNER_TOKEN
    }
  }

  const handleSubmitEmail = async (
    emailData: FormikInitialValues
  ): Promise<void> => {
    try {
      const email = emailFactory(emailData)
      await axios.post(`${EMAIL_API_HOST}/v2/emails/bulk`, email, {
        headers: getEmailHeaders()
      })
      toast.success('Emails enviados com sucesso.')
    } catch (e) {
      toast.error(
        'Ocorreu um erro ao enviar os emails. Tente novamente mais tarde'
      )
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => console.log(values)}
      render={(formik) => (
        <div className={className}>
          <InputSelect
            label="Template"
            loading={templateLoading}
            options={templates}
            placeholder="Selecione um template"
            name="template"
            getOptionLabel={(option) => option.description}
            getOptionValue={(option) => option.id}
            onChange={(value: IEmailTemplate) =>
              handleTemplateSelection(formik, value)
            }
          />
          <p>{t('Select a template to continue filling out the form.')}</p>
          <InputText
            name="subject"
            label="Assunto"
            disabled={!formik.values.template}
          />
          <div className="recipients-row">
            <label>Destinatário/s:</label>
            <Tag color="#245f7a">{users.length} alunos selecionados</Tag>
          </div>
          <EnhancedTextInput
            name="body"
            label="Corpo"
            disabled={!formik.values.template}
          />
          <div className="submit-div">
            <Button
              action="default"
              onClick={() => history.push('/emails/recipients')}
            >
              Cancelar
            </Button>
            <Button
              action="primary"
              onClick={() => handleSubmitEmail(formik.values)}
            >
              Enviar
            </Button>
          </div>
        </div>
      )}
    />
  )
}

export default styled(EmailRecipientForm)`
  .recipients-row {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-width: 200px;
  }
  .recipients-row span {
    margin-top: 0.4rem;
  }
  .submit-div {
    margin-top: 1rem;
    float: right;
  }
  .submit-div button {
    margin-left: 10px;
  }
  p {
    font-size: 12px;
    font-weight: 600;
    color: #8e8e8e;
  }
`
