import { Formik } from 'formik'
import moment from 'moment'
import React, { ReactNode, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import swal from 'sweetalert'
import { ModalContext } from '../../states/ModalState'
import devices from '../../utils/devices'
import Button from '../Button'
import MaskedInput from '../Inputs/MaskedInput'
import RadioInput from '../Inputs/RadioInput'
import RadioInputGroup from '../Inputs/RadioInputGroup'
import ModalFooter from '../Modal/ModalFooter'
import Modal from '../Modal/ModalRootModal'
import { get } from 'lodash'
import QuestionMark from 'components/QuestionMark'
import Checkbox from 'components/Inputs/Checkbox'

export type FormValues = {
  startDate: string
  startHour: string
  endDate: string
  endHour: string
  minutes: string
  strategy: string
  applyToCollection: boolean
}

export type SubmitValues = {
  startTime: string
  endTime: string
  maxDuration: string
  deleteExisting: boolean
  applyToCollection: boolean
}

export interface SetTimeWindowModalProps {
  className?: string
  children?: ReactNode
  onSubmit: (values: SubmitValues) => Promise<any>
  applyToCollectionOption?: boolean
}

const SetTimeWindowModal = ({
  children,
  className,
  onSubmit,
  applyToCollectionOption
}: SetTimeWindowModalProps) => {
  const { t } = useTranslation()
  const { hideModal } = useContext(ModalContext)

  const tryParseDate = (date: string) => {
    return moment(date, 'DD/MM/YYYY').isValid()
  }

  const tryParseHour = (hour: string) => {
    return moment(hour, 'hh:mm').isValid()
  }

  const validateDate = (value: string) => {
    return tryParseDate(value)
  }

  const validateHour = (value: string) => {
    return tryParseHour(value)
  }

  const validateMinutes = (value: string) => {
    let minutesOk = true
    try {
      const minutesAux = value.replace(/_/g, '')
      minutesOk = minutesAux !== '' && +minutesAux > 0
    } catch (e) {
      minutesOk = false
    }
    return minutesOk
  }

  const validate = (values: FormValues) => {
    const invalidMsg = t('Invalid format.')
    const requiredMsg = t('This field is required.')
    const errors: { [key: string]: string } = {}
    if (!validateDate(values.startDate)) {
      errors.startDate = invalidMsg
    }
    if (!validateHour(values.startHour)) {
      errors.startHour = invalidMsg
    }
    if (!validateDate(values.endDate)) {
      errors.endDate = invalidMsg
    }
    if (!validateHour(values.endHour)) {
      errors.endHour = invalidMsg
    }
    if (!validateMinutes(values.minutes)) {
      errors.minutes = invalidMsg
    }
    if (!values.strategy) {
      errors.strategy = requiredMsg
    }
    return errors
  }

  const saveExamDates = async (values: FormValues) => {
    const minutesAux = values.minutes.replace(/_/g, '')

    const payload: SubmitValues = {
      startTime: `${values.startDate} ${values.startHour}`,
      endTime: `${values.endDate} ${values.endHour}`,
      maxDuration: minutesAux,
      deleteExisting: values.strategy === 'delete_existing',
      applyToCollection: values.applyToCollection
    }

    try {
      await onSubmit(payload)
      swal({
        icon: 'success',
        title: t('Data definida com sucesso')
      })
      setTimeout(hideModal, 1000)
    } catch (err) {
      let title = t('Could not set date. Check the data and try again')
      const errorMessage = get(err, 'response.data', false)
      if (errorMessage) {
        title = t(errorMessage[0])
      }
      swal({
        icon: 'error',
        title
      })
    }
  }

  const initialValues: FormValues = {
    startDate: '',
    startHour: '',
    endDate: '',
    endHour: '',
    minutes: '',
    strategy: 'keep_existing',
    applyToCollection: false
  }

  return (
    <Modal className={className} title={t('Set exam date')} onClose={hideModal}>
      <Formik
        onSubmit={saveExamDates}
        initialValues={initialValues}
        validate={validate}
      >
        {(formik) => (
          <form onSubmit={formik.handleSubmit}>
            <p>{children}</p>
            <>
              <div className="modal-body-wrapper">
                <label>{t('Start time')}</label>
                <MaskedInput
                  name="startDate"
                  mask="99/99/9999"
                  placeholder={t('Date')}
                />
                <MaskedInput
                  name="startHour"
                  mask="99:99"
                  placeholder={t('Hour')}
                />
                <label>{t('End time')}</label>
                <MaskedInput
                  name="endDate"
                  mask="99/99/9999"
                  placeholder={t('Date')}
                />
                <MaskedInput
                  name="endHour"
                  mask="99:99"
                  placeholder={t('Hour')}
                />
                <label>{t('Duration')}</label>
                <MaskedInput
                  name="minutes"
                  mask="999999999"
                  placeholder={t('Minutes')}
                  maskChar=""
                />
              </div>
              <div className="row-2">
                <RadioInputGroup
                  name="strategy"
                  label={
                    <>
                      {t('Existing dates')}{' '}
                      <QuestionMark
                        title={t(
                          'Choose if previous dates should be kept or removed'
                        )}
                      />
                    </>
                  }
                >
                  <RadioInput value="keep_existing" label={t('Keep')} />
                  <RadioInput value="delete_existing" label={t('Remove')} />
                </RadioInputGroup>
                {applyToCollectionOption && (
                  <Checkbox
                    name="applyToCollection"
                    label={
                      <>
                        {t('Apply to all collection applications')}{' '}
                        <QuestionMark
                          title={t(
                            'Provided dates will be applied to all candidate applications from collection'
                          )}
                        />
                      </>
                    }
                  />
                )}
              </div>
            </>
            <ModalFooter>
              <Button
                type="submit"
                action="primary"
                disabled={formik.isSubmitting}
              >
                {t('Confirm')}
              </Button>
            </ModalFooter>
          </form>
        )}
      </Formik>
    </Modal>
  )
}

export default styled(SetTimeWindowModal)`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  width: 100%;

  .modal-body-wrapper {
    display: grid;
    grid-template-columns: 50px 100px 100px;
    align-items: center;
    column-gap: 30px;
    row-gap: 10px;

    @media (max-width: ${devices.mobileL}) {
      grid-template-columns: 100%;
    }
  }

  .row-2 {
    margin-top: 2rem;
    display: grid;
    grid-template-columns: 250px 1fr;
  }

  input {
    width: 100%;
  }

  .has-error > input {
    border: 1px solid red;
  }

  ${ModalFooter} {
    display: flex;
    justify-content: space-between;
  }

  ${RadioInputGroup} {
    /* margin-top: 10px; */
  }
`
