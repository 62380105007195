import axios from 'axios'
import debounce from 'debounce-promise'
import moment from 'moment'
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState
} from 'react'
import Countdown from 'react-countdown'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import { ThemeContext } from 'styled-components'
import swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { API_HOST } from '../consts'
import db from '../db'
import {
  AnswerStateAction,
  IAlternative,
  IAnswer,
  IAnswerState,
  IItem
} from '../types'
import { ApplicationSocketContext } from './ApplicationSocketState'
import { ApplicationContext } from './ApplicationState'

type AnswerProps = {
  children: Function
}

const initialAnswerState: IAnswerState = {
  answer: undefined,
  item: undefined,
  fetchItemError: '',
  fetchingItem: false,
  remainingTime: '',
  previousAnswer: undefined,
  nextAnswer: undefined,
  updateAnswer: () => {},
  goAnswer: () => {},
  fetchingAnswer: false,
  fetchAnswerError: '',
  getAnswerFromPosition: () => undefined,
  updateFreeResponse: () => {},
  isAnswered: () => false,
  alreadyAnswered: false,
  changeAnswer: false,
  handleQuestionExpired: () => undefined
}

const reducer = (
  state: IAnswerState,
  action: AnswerStateAction
): IAnswerState => {
  switch (action.type) {
    case 'FETCH_ITEM':
      return { ...state, fetchingItem: true, fetchItemError: '' }
    case 'FETCH_ITEM_ERROR':
      return { ...state, fetchingItem: false, fetchItemError: action.payload }
    case 'FETCH_ITEM_SUCCESS':
      return {
        ...state,
        item: action.payload,
        fetchingItem: false,
        fetchItemError: ''
      }
    case 'FETCH_ANSWER':
      return { ...state, fetchingAnswer: true, fetchAnswerError: '' }
    case 'FETCH_ANSWER_ERROR':
      return {
        ...state,
        fetchingAnswer: false,
        fetchAnswerError: action.payload
      }
    case 'FETCH_ANSWER_SUCCESS':
      return { ...state, answer: action.payload }
    case 'UPDATE_ANSWER_ALTERNATIVE':
      return { ...state, answer: action.payload }
    case 'UPDATE_FREE_RESPONSE':
      return { ...state, answer: action.payload }
    case 'SET_ALREADY_ANSWERED':
      return { ...state, alreadyAnswered: action.payload }
    case 'SET_CHANGE_ANSWER':
      return { ...state, changeAnswer: action.payload }
  }
}

export const AnswerContext = createContext<IAnswerState>(initialAnswerState)

const AnswerState = ({ children }: AnswerProps) => {
  const { applicationId, answerId } = useParams()
  const {
    answers,
    dispatch: applicationContextDispatch,
    application
  } = useContext(ApplicationContext)
  const [paused, setIsPaused] = useState(false)
  const [state, dispatch] = useReducer(reducer, initialAnswerState)
  const ReactSwal = withReactContent(swal)
  const { t } = useTranslation()
  const theme = useContext(ThemeContext)
  const { answer, item, alreadyAnswered, changeAnswer } = state
  const history = useHistory()

  const {
    hasPendingBreak,
    setHasPending,
    forcePause,
    returnFromBreak,
    updateExamStatus
  } = useContext(ApplicationSocketContext)

  const displayBreakModal = useCallback(() => {
    if (!paused) {
      setIsPaused(true)
    }
    const countdown = (
      <h1>
        <Countdown
          date={Date.now() + 120000}
          renderer={({ ...time }) => {
            let label = ''
            label += time.minutes.toString().padStart(2, '0')
            label += ':'
            label += time.seconds.toString().padStart(2, '0')
            return <h2 style={{ color: 'white' }}>{label}</h2>
          }}
        />
      </h1>
    )
    ReactSwal.fire({
      title: `<span style="color: white">${t('Remaining time')}:</span>`,
      html: countdown,
      allowOutsideClick: false,
      showCancelButton: false,
      showConfirmButton: true,
      confirmButtonText: t('I am back!'),
      confirmButtonColor: theme.colors.secondary,
      backdrop: 'rgb(35, 96, 122, 1)',
      background: 'rgb(35, 96, 122, 1)',
      imageUrl: theme.restroomImg,
      imageAlt: t('Remaining time'),
      timer: 120000
    }).then((result) => {
      if (result && result.value) {
        setHasPending(false)
        setIsPaused(false)
        returnFromBreak()
      }
    })
  }, [
    ReactSwal,
    paused,
    returnFromBreak,
    setHasPending,
    t,
    theme.colors.secondary,
    theme.restroomImg
  ])

  if (paused || forcePause) {
    displayBreakModal()
  }

  // Redirects to next or previous answer
  const goAnswer = useCallback(
    (newAnswer: IAnswer) => {
      if (hasPendingBreak) {
        displayBreakModal()
        setHasPending(false)
      }
      history.push(`/applications/${applicationId}/answers/${newAnswer.id}`)
    },
    [applicationId, hasPendingBreak, setHasPending, displayBreakModal, history]
  )

  const handleQuestionExpired = async () => {
    try {
      await axios.patch(
        `${API_HOST}/v1/applications/${application.id}/answers/${answerId}/set_item_expired`
      )
      await db.answers
        .where('id')
        .equals(answer.id)
        .modify((ans) => {
          if (!ans.timeoutDate) {
            ans.timeoutDate = moment(new Date()).format('YYYY-MM-DD HH:mm')
            ans._changed = 1
          }
        })

      const nextAnswer = answers.find(
        (element) =>
          element.position > answer.position && element.timeoutDate === null
      )

      if (!nextAnswer) {
        history.push(`/applications/${application.id}/review`)
      } else {
        goAnswer(nextAnswer)
      }
    } catch (e) {
      console.log(e)
    }
  }

  // Fetches item from api ou local db
  const fetchItem = useCallback(async () => {
    if (!answer) {
      return
    }

    if (answer.item.id === item?.id) {
      return
    }

    // Looks for item in local db
    let newItem: IItem | undefined
    try {
      newItem = await db.items.where({ id: answer.item.id }).first()
    } catch (_) {
      // If unable do get item, just ignore it
    }

    // If item not in local db, fetches from api and adds to local db
    if (!newItem) {
      try {
        dispatch({ type: 'FETCH_ITEM' })
        const response = await axios.get(answer.item._url)
        newItem = response.data
        if (newItem) {
          try {
            await db.items.put(newItem)
          } catch (_) {
            // If unable to add item, just ignore it
          }
        }
      } catch (e) {
        dispatch({ type: 'FETCH_ITEM_ERROR', payload: e })
        return
      }
    }

    dispatch({ type: 'FETCH_ITEM_SUCCESS', payload: newItem })
  }, [answer, item])

  const addAnswerLog = useCallback(
    debounce(async () => {
      if (application) {
        const answers = await db.answers
          .where({ 'application.id': application.id })
          .toArray()
        const payload = {
          answers: answers.reduce((acc, d) => {
            return {
              ...acc,
              [d.position.toString()]:
                d.alternative?.letter || d.freeResponse || undefined
            }
          }, {})
        }
        return axios.post(
          `${API_HOST}/v1/applications/${application.id}/answer_logs`,
          payload
        )
      }

      return Promise.resolve()
    }, 1000),
    [application]
  )

  const updateAnswer = async (alternative: IAlternative) => {
    if (!answer) {
      return
    }

    const isRemovingAnswer = alternative.id === answer.alternative?.id
    if (isRemovingAnswer) {
      return
    }
    const newAlternative = {
      ...alternative,
      content: undefined
    }

    const newAnswer = {
      ...answer,
      // Since content may be too big, it is not necessary to store it in answer
      alternative: newAlternative,
      _changed: 1
    }

    // Using modify here so it does not overwrite the seconds
    await db.answers
      .where('id')
      .equals(answer.id)
      .modify((ans) => {
        ans.alternative = newAlternative
        ans._changed = 1
      })

    addAnswerLog()

    dispatch({ type: 'UPDATE_ANSWER_ALTERNATIVE', payload: newAnswer })
    applicationContextDispatch({
      type: 'UPDATE_ANSWER_IN_ANSWERS',
      payload: newAnswer
    })
  }

  const updateFreeResponse = async (freeResponse: string) => {
    if (!answer) {
      return
    }

    if (item?.freeResponseMaxLength !== undefined) {
      freeResponse = freeResponse.substring(0, item.freeResponseMaxLength)
    }

    const newAnswer = {
      ...answer,
      freeResponse,
      _changed: 1
    }

    // Using modify here so it does not overwrite the seconds
    await db.answers
      .where('id')
      .equals(answer.id)
      .modify((ans) => {
        ans.freeResponse = freeResponse
        ans._changed = 1
      })

    addAnswerLog()

    dispatch({ type: 'UPDATE_FREE_RESPONSE', payload: newAnswer })
    applicationContextDispatch({
      type: 'UPDATE_ANSWER_IN_ANSWERS',
      payload: newAnswer
    })
  }

  const isAnswered = (ans: IAnswer) => {
    return !!(ans.alternative || ans.freeResponse)
  }

  const getAnswerFromPosition = useCallback(
    (position: number): IAnswer | undefined => {
      return answers?.find((d) => d.position === position) || undefined
    },
    [answers]
  )

  const getFirstNotAnswered = useCallback((): IAnswer | undefined => {
    return answers?.find((a) => !isAnswered(a)) || undefined
  }, [answers])

  useEffect(() => {
    // Prevents from running this effect if the user simply
    // changed the alternative/free response
    if (answerId && +answerId === answer?.id) {
      return
    }

    // Updates current, previous and next answers on answerId change
    ;(async () => {
      let newAnswer

      if (answerId && answers) {
        newAnswer = await db.answers.where({ id: +answerId }).first()
        if (newAnswer) {
          dispatch({
            type: 'SET_ALREADY_ANSWERED',
            payload: isAnswered(newAnswer)
          })
        }
      } else {
        newAnswer = undefined
      }

      dispatch({ type: 'FETCH_ANSWER_SUCCESS', payload: newAnswer })
    })()

    // Scrolls page to top
    window.scrollTo(0, 0)
  }, [answerId, answers, answer, getAnswerFromPosition])

  useEffect(() => {
    if (!application?.exam.canBrowseAcrossItems && alreadyAnswered) {
      dispatch({ type: 'SET_CHANGE_ANSWER', payload: true })
    }
  }, [alreadyAnswered, application])

  useEffect(() => {
    if (
      !application?.exam.canBrowseAcrossItems &&
      changeAnswer &&
      answers?.length > 0
    ) {
      const firstNotAnswered = getFirstNotAnswered()

      if (firstNotAnswered) {
        dispatch({ type: 'SET_CHANGE_ANSWER', payload: false })
        goAnswer(firstNotAnswered)
      } else {
        if (application) {
          history.push(`/applications/${application.id}/review`)
        }
      }
    }
  }, [
    application,
    answers,
    changeAnswer,
    answerId,
    getFirstNotAnswered,
    goAnswer,
    history
  ])

  useEffect(() => {
    fetchItem()
  }, [fetchItem])

  useEffect(() => {
    if (!application) {
      return
    }
    const shouldLoadAnswer = application.status === 'STARTED'

    // TODO: Feedback user
    if (!shouldLoadAnswer) {
      history.push(`/applications/${application.id}/instructions`)
    }
  }, [application, history])

  // Creates a timer that is incremented by 1 each second
  const timerRef = useRef(0)
  useEffect(() => {
    const interval = setInterval(() => {
      timerRef.current += 1
    }, 1000)
    return () => {
      timerRef.current = 0
      clearInterval(interval)
    }
  }, [])

  useEffect(() => {
    const interval = setInterval(async () => {
      if (!answer) {
        return
      }

      try {
        // Looks for the current answer and updates the seconds field
        // using the page timer
        await db.answers
          .where('id')
          .equals(answer.id)
          .modify((ans) => {
            ans.seconds = (ans.seconds || 0) + timerRef.current
            ans._changed = 1
          })

        timerRef.current = 0
      } catch (_) {
        // If operation failed, do not reset the timer so
        // it tries again after the next interval
      }
    }, 1000)

    return () => clearInterval(interval)
  }, [answer])

  useEffect(() => {
    if (!answer || !application) {
      return
    }
    updateExamStatus({
      currentQuestion: answer.position,
      examStartDate: application.startedAt,
      finished: false
    })
  }, [answer, application, updateExamStatus])

  const contextValue = {
    ...state,
    goAnswer,
    updateAnswer,
    fetchItem,
    getAnswerFromPosition,
    updateFreeResponse,
    isAnswered,
    handleQuestionExpired
  }

  return (
    <AnswerContext.Provider value={contextValue}>
      {children(contextValue)}
    </AnswerContext.Provider>
  )
}

export default AnswerState
