import { useFormikContext } from 'formik'
import React, { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import Button from './Button'
import LightBorderCard from './Card/LightBorderCard'
import HierarchyFilter from './HierarchyFilter'

type HierarchyFilterBoxProps = {
  providerCodename?: string
  title?: string
  children?: ReactNode
  className?: string
  distinct?: boolean
}

const HierarchyFilterBox = ({
  providerCodename,
  children,
  className,
  distinct = true,
  title = 'Filters'
}: HierarchyFilterBoxProps) => {
  const { t } = useTranslation()
  const formik = useFormikContext()

  return (
    <form className={className} onSubmit={formik.handleSubmit}>
      <LightBorderCard title={title}>
        <HierarchyFilter
          distinct={distinct}
          providerCodename={providerCodename}
        />
        {children}
        <Button type="submit" action="secondary">
          {t('APPLY')}
        </Button>
      </LightBorderCard>
    </form>
  )
}

export default styled(HierarchyFilterBox)``
