import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import Skeleton from 'react-loading-skeleton'
import styled from 'styled-components'
import Button from '../components/Button'
import Error from '../components/Error'
import Html from '../components/Html'
import RemainingTime from '../components/RemainingTime'
import { ApplicationContext } from '../states/ApplicationState'
import { AuthContext } from '../states/AuthState'
import { formatDateTimeString, formatDuration } from '../utils/date'
import InstructionsCard from '../components/Card/InstructionsCard'

type InstructionsProps = {
  className?: string
}

const Instructions = ({ className }: InstructionsProps) => {
  const {
    application,
    startApplication,
    resumeApplication,
    fetchingApplication,
    startingApplication,
    startApplicationError,
    secondsToTimeout,
    remainingTimeInitialDate
  } = useContext(ApplicationContext)
  const { user } = useContext(AuthContext)
  const { t } = useTranslation()

  const exam = application?.exam
  const timeWindows = exam?.timeWindows || []
  const timeWindow = timeWindows?.length > 0 ? timeWindows[0] : undefined
  const timeAllowedPreview = formatDuration((timeWindow?.maxDuration || 0) * 60)

  const renderWelcomeMessage = () => {
    if (fetchingApplication) {
      return (
        <p>
          <Skeleton width={200} height={15} />
        </p>
      )
    }

    const messages = {
      STARTED() {
        const startedAt = application?.startedAt
          ? formatDateTimeString(application.startedAt)
          : '-'
        return (
          <>
            <p>
              {t('You started your exam at {{time}}.', { time: startedAt })}
            </p>
            <p>
              {t('You have')}{' '}
              <strong>
                <RemainingTime
                  initial={remainingTimeInitialDate}
                  secondsToTimeout={secondsToTimeout}
                  allowHideRemainingTime={false}
                />
              </strong>{' '}
              {t('left to submit it.')}
            </p>
          </>
        )
      },

      FINISHED() {
        return <p>{t('This exam has already been submitted.')}</p>
      },

      STOPPED() {
        return <p>{t('This exam has been stopped. Please wait.')}</p>
      },

      AVAILABLE() {
        return (
          <>
            <p>
              {t('After starting your exam, you will have')}
              <strong> {timeAllowedPreview} </strong>
              {t('to submit it', { context: 'fs' })}
            </p>
          </>
        )
      },

      AVAILABLE_SOON() {
        return <p>{t('This exam will be available soon.')}</p>
      },

      UNAVAILABLE() {
        return <p>{t('This exam is unavailable.')}</p>
      },

      UNKNOWN() {
        return <p>{t('Exam status is unknown. Please wait.')}</p>
      }
    }

    return (messages[application?.status || 'UNKNOWN'] || messages.UNKNOWN)()
  }

  const renderGoToAnswersButton = () => {
    const buttons = {
      STARTED() {
        return (
          <Button
            action="primary"
            isLoading={startingApplication}
            onClick={resumeApplication}
          >
            {t('resume')}
          </Button>
        )
      },

      FINISHED() {
        return null
      },

      STOPPED() {
        return null
      },

      AVAILABLE() {
        return (
          <Button
            action="primary"
            isLoading={startingApplication}
            onClick={startApplication}
          >
            {t('start')}
          </Button>
        )
      },

      AVAILABLE_SOON() {
        return null
      },

      UNAVAILABLE() {
        return null
      },

      UNKNOWN() {
        return null
      }
    }

    return (buttons[application?.status || 'UNKNOWN'] || buttons.UNKNOWN)()
  }

  return (
    <div className={className}>
      <div className="exam-welcome">
        <h1>Olá, {user?.name}</h1>
        {renderWelcomeMessage()}
        <InstructionsCard>
          <h3>{t('Instructions')}</h3>
          <span>
            {fetchingApplication ? (
              <Skeleton height={200} />
            ) : (
              <Html>
                {exam?.instructions || t('No instructions available')}
              </Html>
            )}
          </span>
        </InstructionsCard>
        {renderGoToAnswersButton()}
        {startApplicationError && (
          <p>
            <Error>{startApplicationError}</Error>
          </p>
        )}
      </div>
    </div>
  )
}

export default styled(Instructions)`
  background: ${(props) => props.theme.colors.grayLight};
  min-height: 100vh;
  padding-top: 3rem;
  padding-bottom: 2rem;

  .exam-welcome {
    max-width: 50rem;
    margin: auto;
    text-align: center;
    padding: 1rem;

    h1 {
      color: ${(props) => props.theme.clientColors.text};
      font-size: 24pt;
      margin-top: 0px;
      font-weight: 600;
    }

    p {
      color: $font-color;
      font-size: 12pt;
    }
  }

  button {
    margin-top: 2rem;
  }
`
