import styled from 'styled-components'

const Card = styled.div`
  padding: 15px;
  background-color: white;
  border: 2px solid ${(props) => props.theme.colors.grayLight};
  border-radius: 5px;

  :hover {
    ${(props) =>
      props.onClick &&
      `
      background-color: ${props.theme.colors.grayLight};
    `}
  }
`

export default Card
