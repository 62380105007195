import React, { useState, useCallback, useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import Modal from '../Modal/Modal'
import RoomRecordModalCard from './RoomRecordModalCard'
import { RollbarErrorTracking } from 'utils/rollbar'
import { toast } from 'react-toastify'
import axios from 'axios'
import { API_HOST } from 'consts'
import { RoomRecord } from 'types'
import Skeleton from 'react-loading-skeleton'
import { AuthContext } from 'states/AuthState'

type RoomRecordModalProps = {
  isOpen: boolean
  setIsOpen: Function
  roomId: number
  className?: string
}

const RoomRecordModal = ({
  isOpen,
  setIsOpen,
  roomId,
  className
}: RoomRecordModalProps) => {
  const { t } = useTranslation()
  const { user } = useContext(AuthContext)

  const [isSaving, setIsSaving] = useState(false)
  const [isFetching, setIsFetching] = useState(false)
  const [roomRecords, setRoomRecords] = useState([])
  const [hasError, setHasError] = useState(false)
  const [description, setDescription] = useState('')

  const fetchRoomRecords = useCallback(async () => {
    if (!isOpen) {
      return
    }
    try {
      setIsFetching(true)
      const result = await axios.get(
        `${API_HOST}/v1/room_event_record?roomId=${roomId}`
      )
      const records = result.data as RoomRecord[]
      setRoomRecords(records)
    } catch (e) {
      RollbarErrorTracking.LogError(e)
      toast.error(t('An error occurred. Please try again.'))
    } finally {
      setIsFetching(false)
    }
  }, [isOpen])

  const saveRoomRecord = async () => {
    try {
      const result = await axios.post(`${API_HOST}/v1/room_event_record`, {
        description,
        room: roomId,
        user: user.id
      })
      if (result.status === 200 || result.status === 201) {
        setDescription('')
        setIsSaving(false)
        fetchRoomRecords()
      }
    } catch (e) {
      RollbarErrorTracking.LogError(e)
      toast.error(t('An error occurred. Please try again.'))
    } finally {
      setIsFetching(false)
    }
  }

  const handleSubmit = () => {
    setHasError(false)
    setIsSaving(true)
    if (!description) {
      setHasError(true)
      setIsSaving(false)
      return
    }
    saveRoomRecord()
  }

  useEffect(() => {
    fetchRoomRecords()
  }, [fetchRoomRecords])

  return (
    <Modal
      className={className}
      onClose={() => setIsOpen(false)}
      onCancel={() => setIsOpen(false)}
      onAction={handleSubmit}
      isOpen={isOpen}
      title={t('Records history')}
      actionDisabled={isSaving}
      actionText={'Salvar'}
    >
      <div className="existent-records">
        <p className="subtitle">{t('History of saved records')}</p>
        {isFetching && <Skeleton count={3} height={100}></Skeleton>}
        {roomRecords.length === 0 && t('No results found.')}
        {roomRecords.map((record, index) => {
          return (
            <RoomRecordModalCard
              key={index}
              roomRecord={record}
            ></RoomRecordModalCard>
          )
        })}
      </div>
      <p className="subtitle">{t('New record')}</p>
      <textarea
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        rows={8}
        placeholder={t('Record description')}
        className={`record-description ${hasError ? 'has-error' : ''}`}
        maxLength={200}
      ></textarea>
    </Modal>
  )
}

export default styled(RoomRecordModal)`
  .react-loading-skeleton {
    margin-bottom: 10px;
  }

  .subtitle {
    font-size: 17px;
    color: #033849;
    font-weight: bold;
  }

  .existent-records {
    max-height: 500px;
    overflow-y: auto;
  }

  .existent-records > ${RoomRecordModalCard} {
    margin-bottom: 10px;
  }

  .record-description {
    border-radius: 5px;
    border: 1px solid #d0d0d0;
    resize: none;
    font-family: Helvetica;
    padding: 5px;
  }

  .has-error {
    border: 1px solid red;
  }
`
