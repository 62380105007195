import React, { ReactNode } from 'react'
import styled from 'styled-components'

type PageProps = {
  title?: string
  children: ReactNode
  className?: string
  customWidth?: number
}

const Page = ({ title, children, className }: PageProps) => {
  return (
    <section className={className}>
      {title && <h1>{title}</h1>}
      <>{children}</>
    </section>
  )
}

export default styled(Page)`
  padding: 20px;
  max-width: ${(props) => props.customWidth || 1000}px;
  margin: auto;
`
