/* eslint-disable react/prop-types */

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { IApplication } from '../types'
import { formatDateTimeString, formatDuration } from '../utils/date'
import ApplicationStatus from './ApplicationStatus'
import Card from './Card/Card'
import CardDetails from './CardDetails'
import CardSubtitle from './ExamDashboardComponents/CardSubtitle'
import CardTitle from './ExamDashboardComponents/CardTitle'
import ExamInfoLabel from './ExamInfoLabel'
import RemainingTime from './RemainingTime'
import SeeResultsLink from './SeeResultsLink'
import { VideoStreamingContext } from '../states/VideoStreamingState'
import swal from 'sweetalert2'

type ApplicationCardProps = {
  className?: string
  application: IApplication
}

const ApplicationCard = ({ application, className }: ApplicationCardProps) => {
  const { t } = useTranslation()
  const history = useHistory()

  const { hasPermissionError, granted, triedToAskForPermission } = useContext(
    VideoStreamingContext
  )

  let availableFrom: string | undefined
  let availableTo: string | undefined
  let maxDuration: string | undefined

  const { startedAt, finishedAt } = application

  let timeWindows
  if (application.timeWindows && application.timeWindows.length > 0) {
    timeWindows = application.timeWindows
  } else {
    timeWindows = application.exam.timeWindows
  }

  const shouldRenderLink = ['AVAILABLE', 'STARTED'].includes(application.status)

  // According to the image in the task card, the See Results
  // label is the same color as the Available status
  const renderDuration = () => {
    switch (application.status) {
      case 'STARTED':
        return (
          <ExamInfoLabel label="Remaining time" icon={['far', 'clock']}>
            <RemainingTime
              initial={Date.now()}
              allowHideRemainingTime={false}
              secondsToTimeout={application.secondsToTimeoutWhenLoaded}
            />
          </ExamInfoLabel>
        )
      case 'FINISHED':
        return null
      default:
        return (
          <ExamInfoLabel label="Time" icon={['far', 'clock']}>
            {maxDuration}
          </ExamInfoLabel>
        )
    }
  }

  const handleCardClick = () => {
    if (triedToAskForPermission && (!granted || hasPermissionError)) {
      swal.fire({
        icon: 'error',
        text: t(
          'You refused to grant the necessary permissions, therefore you cannot proceed on the exams.'
        )
      })
      return
    }
    history.push(application.instructionsUrl)
  }

  return (
    <Card
      className={`${className} ${shouldRenderLink ? 'available' : ''}`}
      onClick={() => handleCardClick()}
    >
      <CardTitle>
        {shouldRenderLink ? (
          <u>{application.title}</u>
        ) : (
          <strong>{application.title}</strong>
        )}
        <ApplicationStatus
          status={application.status}
          reseted={application.reseted}
        />
      </CardTitle>
      <CardDetails>
        {timeWindows ? (
          timeWindows.map((tw) => {
            availableFrom = formatDateTimeString(tw.startTime)
            availableTo = formatDateTimeString(tw.endTime)
            maxDuration = formatDuration(tw.maxDuration * 60)
            return (
              <div className="available-time" key={tw.startTime}>
                <FontAwesomeIcon icon={['far', 'calendar-exclamation']} />
                <>
                  {' '}
                  {t('Available')}: {availableFrom} {t('to')} {availableTo}
                </>
              </div>
            )
          })
        ) : (
          <> {t('Time unavailable')}</>
        )}
      </CardDetails>
      <CardSubtitle>
        {
          <ExamInfoLabel label="Start time" icon={['far', 'calendar-alt']}>
            {startedAt && formatDateTimeString(startedAt)}
          </ExamInfoLabel>
        }
        {
          <ExamInfoLabel label="End time" icon={['far', 'calendar-alt']}>
            {finishedAt && formatDateTimeString(finishedAt)}
          </ExamInfoLabel>
        }
        {renderDuration()}
        {<SeeResultsLink reportCardId={application.reportCardId} />}
      </CardSubtitle>
    </Card>
  )
}

export default styled(ApplicationCard)`
  cursor: not-allowed;
  &.available {
    cursor: pointer;
  }
  ${CardTitle} {
    color: ${(props) => props.theme.clientColors.text};
    margin-bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    a {
      margin-right: 15px;
    }

    strong {
      margin-right: 15px;
    }

    a:visited {
      color: unset;
    }

    a:not(:visited) {
      color: unset;
    }
  }

  .available-time {
    margin-top: 10px;
  }

  ${CardSubtitle} {
    display: grid;
    grid-template-columns: 1fr 1fr 1.5fr;
    column-gap: 1rem;
    margin-top: 1rem;
  }
`
