import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import useHierarchyCategories from '../../hooks/useHierarchyCategories'
import usePreference from '../../hooks/usePreference'
import { IStringPreference } from '../../types'
import FilterValue from '../DataDisplay/FilterValue'
import FilterValueGroup from '../DataDisplay/FilterValueGroup'
import Separator from './Separator'
import SetTimeWindowModal from './SetTimeWindowModal'

export interface SetBulkTimeWindowModalProps {
  className?: string
  filters: string[]
  totalApplications: number | undefined
  onSubmit: (values: any) => Promise<any>
}

const SetBulkTimeWindowModal = ({
  filters,
  totalApplications,
  onSubmit,
  ...rest
}: SetBulkTimeWindowModalProps) => {
  const { t } = useTranslation()
  const hierarchyCodenamesP = usePreference<IStringPreference>(
    'Application__RequiredFiltersSetBulkTimeWindow'
  )
  const hierarchyCodenames = hierarchyCodenamesP?.value
    ? hierarchyCodenamesP?.value.split(',')
    : []
  const [hierarchyCategories] = useHierarchyCategories()
  const hierarchyNames = hierarchyCodenames.map(
    (d) => hierarchyCategories.find((f) => f.codename === d)?.name || d
  )

  return (
    <SetTimeWindowModal onSubmit={onSubmit} {...rest}>
      <p>
        {t(
          'Use the list filters to choose the applications you want to set a new date.'
        )}
      </p>
      <p
        style={{
          visibility: hierarchyCodenames.length === 0 ? 'hidden' : 'visible'
        }}
      >
        <strong>{hierarchyNames.join(', ')}</strong> {t('must be selected.')}
      </p>
      {filters.length > 0 && (
        <>
          <small className="mb-1">
            {t(
              'All records that meet the following filters will be considered'
            )}
          </small>
          <FilterValueGroup>
            {filters.map((d, i) => (
              <FilterValue key={d + i}>{d}</FilterValue>
            ))}
          </FilterValueGroup>
        </>
      )}
      {filters.length === 0 && <small>{t('No filters selected')}</small>}
      <p>
        <strong>
          {t('This action will affect {{ num }} applications.', {
            num: totalApplications?.toLocaleString()
          })}
        </strong>
      </p>
      <Separator />
    </SetTimeWindowModal>
  )
}

export default styled(SetBulkTimeWindowModal)`
  ${FilterValueGroup} {
    margin-top: 10px;
  }
`
