import React, { createContext, useEffect, useState } from 'react'
import { IConfig } from '../types'

type ConfigStateProps = {
  children: React.ReactNode
}

const initialConfig = {
  syncAnswerInterval: 7000,
  fetchNotificationInterval: 15000,
  syncApplicationsExamDashboardInterval: 90000,
  allowHideRemainingTimeAnswerPage: true,
  showSecondsInRemainingTime: true,
  disableItemTextHighlight: true
}

export const ConfigContext = createContext(initialConfig)

export const ConfigState = ({ children }: ConfigStateProps) => {
  const [config] = useState<IConfig>(initialConfig)

  useEffect(() => {
    // TODO: Fetch config from API
  }, [])

  return (
    <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>
  )
}

export default ConfigState
