import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { AuthContext } from '../states/AuthState'
import Breadcrumb from './Breadcrumb'
import usePreference from 'hooks/usePreference'
import { IBooleanPreference } from 'types'

const ApplicationBreadcrumb = () => {
  const { t } = useTranslation()
  const emailsEnabled = usePreference<IBooleanPreference>(
    'Application__EmailFlowEnabled'
  )

  const items = [
    {
      label: t('Exam dashboard'),
      children: [
        {
          label: t('Real time stats'),
          children: [],
          path: '/exams/dashboard'
        },
        {
          label: t('Access logs'),
          children: [],
          disabled: true
        },
        {
          label: t('Reset logs'),
          children: [],
          disabled: true
        }
      ]
    },
    {
      label: t('Results'),
      children: [
        {
          label: t('See reports'),
          children: [],
          path: '/report-cards/individual'
        },
        {
          label: t('Grades reports'),
          children: [],
          path: '/grades/download',
          disabled: false
        }
        // {
        //   label: t('TCT Analysis'),
        //   children: [],
        //   disabled: true
        // },
        // {
        //   label: t('TRI Analysis'),
        //   children: [],
        //   disabled: true
        // }
      ]
    },
    // {
    //   label: t('Resource'),
    //   children: [
    //     {
    //       label: t('New resource'),
    //       children: [],
    //       disabled: true
    //     },
    //     {
    //       label: t('Check resource'),
    //       children: [],
    //       disabled: true
    //     }
    //   ]
    // },
    {
      label: t('Configurations'),
      children: [
        {
          label: t('Exam scheduling'),
          children: [],
          disabled: true
        },
        {
          label: t('Exam grouping'),
          children: [],
          disabled: true
        },
        {
          label: t('Users'),
          children: [],
          disabled: true
        }
      ]
    },
    {
      label: t('Emails'),
      isEmail: true,
      allowed_groups: ['ADMINISTRADOR'],
      children: [
        {
          label: t('Send emails'),
          children: [],
          path: '/emails/recipients'
        }
      ]
    },
    {
      label: t('Imports'),
      allowed_groups: ['ADMINISTRADOR'],
      children: [
        {
          label: t('Exams'),
          children: [],
          path: '/imports/exam'
        },
        {
          label: t('Students'),
          children: [],
          path: '/imports/students'
        }
      ]
    }
  ]

  const { hasGroup } = useContext(AuthContext)

  const canSeeBreadCrumb =
    hasGroup('ADMINISTRADOR') || hasGroup('COORDINATOR') || hasGroup('PROCTOR')

  const filtered_items = items.filter((item) => {
    if (item.isEmail) {
      return emailsEnabled && emailsEnabled.value
    }

    if (item.allowed_groups) {
      return item.allowed_groups.some((group) => hasGroup(group))
    }

    return true
  })

  return <Breadcrumb items={canSeeBreadCrumb ? filtered_items : []} />
}

export default ApplicationBreadcrumb
