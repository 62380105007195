import React, { useState } from 'react'
import axios from 'axios'
import { useTranslation } from 'react-i18next'
import { get } from 'lodash'
import { API_HOST } from '../consts'
import { getStringListFromObject } from '../utils/list'
import { downloadFile } from '../utils/files'
import ImportBase from '../components/Imports/ImportBase'
import PromiseButton from '../components/PromiseButton'

const ImportStudentPage = () => {
  const { t } = useTranslation()
  const [file, setFile] = useState(new Blob())
  const [errors, setErrors] = useState([])
  const [loading, setLoading] = useState(false)
  const [successMessage, setSuccessMessage] = useState('')
  const importedMessage = t('Students successfully imported!')

  const handleSubmit = async () => {
    setErrors([])
    setSuccessMessage('')
    if (file) {
      setLoading(true)
      const form = new FormData()
      form.append('file', file)
      const headers = { 'Content-Type': 'form/multipart-form-data' }
      try {
        await axios.post(`${API_HOST}/v1/import_user`, form, { headers })
        setSuccessMessage(importedMessage)
      } catch (err) {
        const errList = getStringListFromObject(
          get(err, 'response.data', {})
        ) as []
        setErrors(errList)
      }
      setLoading(false)
    }
  }

  const handleDownloadExempleFile = async () => {
    return downloadFile({
      url: `${API_HOST}/v1/import_user_exemple_file`,
      params: { format: 'excel' }
    })
  }

  const instructions = (
    <PromiseButton
      icon="download"
      action="secondary"
      onClick={handleDownloadExempleFile}
    >
      {t('Exemple file').toUpperCase()}
    </PromiseButton>
  )

  return (
    <ImportBase
      title={t('Import students')}
      instructions={instructions}
      onSubmit={handleSubmit}
      onFileChange={(event) => setFile(get(event, 'target.files[0]', {}))}
      errors={errors}
      loading={loading}
      successMessage={successMessage}
    />
  )
}

export default ImportStudentPage
