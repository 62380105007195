import axios from 'axios'
import debounce from 'debounce-promise'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { API_HOST } from '../../consts'
import InputAsync from './InputAsync'

const UserInput = ({
  basic,
  params,
  ...props
}: {
  params?: any
  basic?: boolean
}) => {
  const { t } = useTranslation()

  const fetchUsers = useCallback(
    debounce(async (search) => {
      const response = await axios.get(
        `${API_HOST}/v1/users${basic ? '/basic' : ''}`,
        {
          params: {
            search,
            ...params
          }
        }
      )
      return response.data.results
    }, 500),
    [params]
  )

  return (
    <InputAsync
      key={JSON.stringify(params)}
      defaultOptions
      loadOptions={fetchUsers}
      label={t('User')}
      name="user"
      getOptionValue={(d) => d.id}
      getOptionLabel={(d) => d.name}
      {...props}
    />
  )
}

export default UserInput
