import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state'
import React, { ReactNode } from 'react'
import Button, { ButtonProps } from '../Button'

interface ToggleMenuButtonProps extends ButtonProps {
  children: ReactNode
  className?: string
  icon?: IconDefinition
  disabled?: boolean
  action?: 'default' | 'primary' | 'secondary' | 'icon' | 'unstyled' | 'link'
}

const ToggleMenuButton = ({
  children,
  icon,
  className,
  disabled = false,
  action = 'icon',
  ...rest
}: ToggleMenuButtonProps) => {
  let enabledElements = 0

  React.Children.forEach(children, (child) => {
    /* eslint-disable dot-notation */
    if (child && !child['props'].disabled) {
      enabledElements++
    }
  })

  return (
    <PopupState variant="popover" popupId="demo-popup-menu">
      {(popupState) => (
        <React.Fragment>
          <Button
            type="button"
            disabled={disabled}
            action={action}
            {...rest}
            {...bindTrigger(popupState)}
          >
            {icon ? (
              <FontAwesomeIcon className={className} icon={icon} />
            ) : (
              <FontAwesomeIcon icon={['fas', 'ellipsis-v']} />
            )}
          </Button>
          {!disabled && (
            <Menu {...bindMenu(popupState)}>
              {enabledElements > 0 &&
                children &&
                React.Children.map(children, (actionItem) => {
                  return (
                    <MenuItem onClick={popupState.close}>{actionItem}</MenuItem>
                  )
                })}
            </Menu>
          )}
        </React.Fragment>
      )}
    </PopupState>
  )
}

export default ToggleMenuButton
