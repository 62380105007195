import React from 'react'
import styled from 'styled-components'
import ReviewButton from '../Buttons/ReviewButton'
import ContactApplicatorButton from '../ApplicationToolbarComponents/ContactApplicatorButton'
import { VIDEO_RECORDING_ENABLED } from '../../consts'

type ApplicationToolbarProps = {
  className?: string
}

const ApplicationToolbar = ({ className }: ApplicationToolbarProps) => (
  <div className={className}>
    {VIDEO_RECORDING_ENABLED && (
      <>
        <ContactApplicatorButton className="button-left" />
      </>
    )}
    <ReviewButton />
  </div>
)

export default styled(ApplicationToolbar)`
  margin-bottom: 10px;
  text-align: right;

  .button-left {
    float: left;
  }
`
