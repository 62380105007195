import styled from 'styled-components'

const CardTitle = styled.h4`
  margin-top: 0px;
  margin-bottom: 10px;

  a {
    text-decoration: underline;
    color: unset;
  }
`

export default CardTitle
