import React, { ReactNode } from 'react'
import Button from '../Button'
import ModalFooter from './ModalFooter'
import Dialog from '@material-ui/core/Dialog'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import ModalHeader from './ModalHeader'
type ModalProps = {
  className?: string
  isOpen: boolean
  onAction?: () => void
  actionText?: string
  onClose?: () => void
  onCancel?: () => void
  cancelText?: string
  children?: ReactNode
  actionDisabled?: boolean
  disableBackdropClick?: boolean
  title?: string
}
const Modal = ({
  className,
  isOpen,
  onAction,
  actionText = 'Ok',
  onClose,
  onCancel,
  cancelText = 'Cancel',
  children,
  actionDisabled,
  disableBackdropClick,
  title = ''
}: ModalProps) => {
  const { t } = useTranslation()
  return (
    <Dialog
      className={className}
      onClose={onClose}
      open={isOpen}
      disableBackdropClick={disableBackdropClick}
      disableEscapeKeyDown={disableBackdropClick}
    >
      <ModalHeader title={title} onClose={onClose} />
      {children}
      {(onAction || onCancel) && (
        <ModalFooter>
          {onAction && (
            <Button
              disabled={actionDisabled}
              action="primary"
              onClick={onAction}
              data-testid="modal-action-button"
            >
              {actionText}
            </Button>
          )}
          {onCancel && (
            <Button
              disabled={actionDisabled}
              action="secondary"
              onClick={onCancel}
              data-testid="modal-cancel-button"
            >
              {t(cancelText)}
            </Button>
          )}
        </ModalFooter>
      )}
    </Dialog>
  )
}
export default styled(Modal)`
  .MuiDialog-paper {
    color: ${(props) => props.theme.colors.text};
    padding: 2rem;
    max-width: 1000px;
    width: 700px;
  }
`
