import React, { useState } from 'react'
import axios from 'axios'
import { useTranslation } from 'react-i18next'
import { get } from 'lodash'
import { API_HOST } from '../consts'
import { getStringListFromObject } from '../utils/list'
import ImportBase from '../components/Imports/ImportBase'

const ImportExamPage = () => {
  const { t } = useTranslation()
  const [file, setFile] = useState(new Blob())
  const [errors, setErrors] = useState([])
  const [successMessage, setSuccessMessage] = useState('')
  const importedMessage = t('Exam successfully imported!')

  const createExam = (data) => {
    axios
      .post(`${API_HOST}/v1/exams`, data)
      .then(() => setSuccessMessage(importedMessage))
      .catch((err) => {
        const errList = getStringListFromObject(
          get(err, 'response.data', {})
        ) as []
        setErrors(errList)
      })
  }

  const handleSubmit = () => {
    setErrors([])
    setSuccessMessage('')
    if (file) {
      if (file.type === 'application/json') {
        const reader = new FileReader()
        reader.onload = (event) => {
          const jsonString = event?.target?.result
          if (jsonString && typeof jsonString === 'string') {
            try {
              const data = JSON.parse(jsonString)
              createExam(data)
            } catch {
              setErrors([t('JSON format is invalid.')])
            }
          }
        }
        reader.readAsText(file)
      } else {
        setErrors([t('File should be .json.')])
      }
    }
  }

  const instructions = (
    <span>
      {t(
        'Receive a .json file with the content of the exam. This file can be generated by exporting a exam in the item creation system.'
      )}
    </span>
  )

  return (
    <ImportBase
      title={t('Import exam')}
      instructions={instructions}
      onSubmit={handleSubmit}
      onFileChange={(event) => setFile(get(event, 'target.files[0]', {}))}
      errors={errors}
      successMessage={successMessage}
    />
  )
}

export default ImportExamPage
