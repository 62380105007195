import { get } from 'lodash'
import React, { useContext } from 'react'
import styled from 'styled-components'
import { IndividualReportCardContext } from '../../states/IndividualReportCardState'

type ReportCardHeaderProps = {
  className?: string
}

const ReportCardHeader = ({ className }: ReportCardHeaderProps) => {
  const { individualReportCard } = useContext(IndividualReportCardContext)
  return (
    <div className={className}>
      {individualReportCard && (
        <>
          <span>{individualReportCard.user.name}</span>
          <span>
            {get(individualReportCard.user, 'extra.hierarchy.unity.name')} |{' '}
            {get(individualReportCard.user, 'extra.hierarchy.class.name')} |{' '}
            {individualReportCard.collection.name}
          </span>
        </>
      )}
    </div>
  )
}

export default styled(ReportCardHeader)`
  font-size: 10pt;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
`
