import axios from 'axios'
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState
} from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Modal from '../components/Modal/Modal'
import { API_HOST, VIDEO_RECORDING_ENABLED } from '../consts'
import db from '../db'
import {
  IAnswer,
  IApplication,
  IApplicationState,
  IApplicationStateAction,
  IApplicationConfiguration
} from '../types'
import { ApplicationSocketContext } from './ApplicationSocketState'
import { VideoStreamingContext } from './VideoStreamingState'

type ApplicationProps = {
  children: Function
}

const reducer = (
  state: IApplicationState,
  action: IApplicationStateAction
): IApplicationState => {
  switch (action.type) {
    case 'FETCH_APPLICATION':
      return {
        ...state,
        fetchingApplication: true,
        fetchApplicationError: false
      }
    case 'FETCH_APPLICATION_SUCCESS':
      return {
        ...state,
        fetchingApplication: false,
        fetchApplicationError: false,
        remainingTimeInitialDate: Date.now(),
        ...action.payload
      }
    case 'FETCH_APPLICATION_ERROR':
      return {
        ...state,
        fetchingApplication: false,
        fetchApplicationError: true
      }
    case 'FETCH_ANSWERS':
      return { ...state, fetchingAnswers: true, fetchAnswersError: false }
    case 'FETCH_ANSWERS_SUCCESS':
      return {
        ...state,
        fetchingAnswers: false,
        fetchAnswersError: false,
        answers: action.payload
      }
    case 'FETCH_ANSWERS_ERROR':
      return { ...state, fetchingAnswers: false, fetchAnswersError: true }
    case 'UPDATE_ANSWER_IN_ANSWERS':
      return {
        ...state,
        answers: state.answers.map((d) =>
          d.id === action.payload.id ? action.payload : d
        )
      }
    case 'START_APPLICATION':
      return { ...state, startingApplication: true, startApplicationError: '' }
    case 'START_APPLICATION_SUCCESS':
      if (state.application) {
        state.application.status = 'STARTED'
      }
      return {
        ...state,
        startingApplication: false,
        startApplicationError: '',
        secondsToTimeout: action.payload,
        remainingTimeInitialDate: Date.now()
      }
    case 'START_APPLICATION_ERROR':
      return {
        ...state,
        startingApplication: false,
        startApplicationError: action.payload
      }
    case 'FINISH_APPLICATION':
      return {
        ...state,
        finishingApplication: true,
        finishApplicationError: ''
      }
    case 'FINISH_APPLICATION_SUCCESS':
      return {
        ...state,
        finishingApplication: false,
        finishApplicationError: ''
      }
    case 'FINISH_APPLICATION_ERROR':
      return {
        ...state,
        finishingApplication: false,
        finishApplicationError: action.payload
      }
    default:
      return state
  }
}

const initialState: IApplicationState = {
  answers: [],
  application: undefined,
  fetchAnswersError: false,
  fetchApplicationError: false,
  fetchingApplication: false,
  fetchingAnswers: false,
  dispatch: () => {},
  startApplication: () => {},
  resumeApplication: () => {},
  finishApplication: () => {},
  startingApplication: false,
  startApplicationError: '',
  fetchAnswers: () => {},
  finishingApplication: false,
  finishApplicationError: '',
  secondsToTimeout: undefined,
  remainingTimeInitialDate: undefined,
  handleTimeout: () => {}
}

export const ApplicationContext = createContext<IApplicationState>(initialState)

const ApplicationState = ({ children }: ApplicationProps) => {
  const { applicationId } = useParams()
  const history = useHistory()
  const { t } = useTranslation()
  const [state, dispatch] = useReducer(reducer, initialState)
  const [reviewModal, setReviewModal] = useState(false)
  const [unansweredItems, setUnansweredItems] = useState(0)
  const ReactSwal = withReactContent(swal)
  const {
    updateExamStatus,
    setCameraAccepted,
    joinRoomAsCandidate
  } = useContext(ApplicationSocketContext)

  const { permissionsAreOk } = useContext(VideoStreamingContext)

  const { application, answers } = state

  // Fetches application from api
  const fetchApplication = useCallback(async () => {
    if (!applicationId) {
      return
    }

    dispatch({ type: 'FETCH_APPLICATION' })

    let newApplication: IApplication | undefined
    let secondsToTimeout: number | undefined

    try {
      // Tries to fetch application from server
      const response = await axios.get(
        `${API_HOST}/v1/applications/${applicationId}`
      )
      newApplication = response.data
      secondsToTimeout = response.data.secondsToTimeout

      if (newApplication) {
        // Saves/updates application in local db
        await db.applications.put(newApplication)
      }
    } catch (error) {
      const status = error?.response?.status

      switch (status) {
        case 401:
        case 403:
        case 404:
          history.push('/applications')
          return
      }

      // If an error occurs, look up in local db
      newApplication = await db.applications
        .where({ id: +applicationId })
        .first()
    }

    if (newApplication) {
      dispatch({
        type: 'FETCH_APPLICATION_SUCCESS',
        payload: {
          secondsToTimeout,
          application: newApplication
        }
      })
    } else {
      dispatch({ type: 'FETCH_APPLICATION_ERROR' })
    }

    return newApplication
  }, [applicationId, history])

  // Fetches answers from api or local db
  const fetchAnswers = useCallback(
    async (forceAnswerUpdates = false) => {
      if (!application) {
        return
      }

      // If needed, update answers in local db
      if (forceAnswerUpdates) {
        db.answers.where({ 'application.id': +application.id }).delete()
      }

      let newAnswers: IAnswer[]

      // This allows the server to request the client to fetch updated answers
      let { shouldUpdateAnswers } = application

      // If not requested by the server, check if there are answers in local db
      if (!shouldUpdateAnswers) {
        const hasAnswersInDb = !!(await db.answers
          .where({ 'application.id': application.id })
          .count())
        shouldUpdateAnswers = !hasAnswersInDb
      }

      // If needed, fetches answers and updates local db
      // This will overwrite answers not syncronized with the server!
      if (shouldUpdateAnswers) {
        const params = { ordering: 'position' }
        const response = await axios.get(
          `${API_HOST}/v1/applications/${application.id}/answers`,
          { params }
        )
        newAnswers = response.data.map((newAnswer: IAnswer) => ({
          ...newAnswer,
          _changed: 0
        }))

        await db.transaction('rw', db.answers, async () => {
          await db.answers.where({ 'application.id': application.id }).delete()
          await db.answers.bulkAdd(newAnswers)
        })

        // Tells the server that answers have been updated
        // axios.patch(`${API_HOST}/v1/applications/${application.id}/`, { shouldUpdateAnswers: false })
      } else {
        newAnswers = await db.answers
          .filter((d) => d.application.id === application.id)
          .sortBy('position')
      }

      dispatch({ type: 'FETCH_ANSWERS_SUCCESS', payload: newAnswers })

      return newAnswers
    },
    [application]
  )

  const getSyncAnswersPayload = useCallback(async () => {
    if (!application) {
      return []
    }
    const changedAnswers = await db.answers
      .where({ 'application.id': application.id, _changed: 1 })
      .toArray()

    if (changedAnswers.length === 0) {
      return []
    }

    return changedAnswers.map((answer) => ({
      id: answer.id,
      alternativeId: answer.alternative?.id,
      freeResponse: answer.freeResponse,
      seconds: answer.seconds
    }))
  }, [application])

  const goAnswer = (newAnswer: IAnswer) => {
    history.push(`/applications/${applicationId}/answers/${newAnswer.id}`)
  }

  // Goes to last answered answer, or to first answer if none was answered
  const resumeApplication = () => {
    const answer =
      answers.find((d) => d.lastAnswered) ||
      answers.find((d) => d.position === 1)

    if (answer) {
      goAnswer(answer)
    }
  }

  const resumeShuffledItemsApplication = async () => {
    /*
      This method exists because the items are shuffled on starting application
      then the answers should be fetched again.
    */
    const newAnswers = await fetchAnswers(true)
    if (newAnswers) {
      const firstAnswer = newAnswers.find((a) => a.position === 1)

      if (firstAnswer) {
        goAnswer(firstAnswer)
      }
    }
  }

  const startApplication = async () => {
    if (!application) {
      return
    }

    dispatch({ type: 'START_APPLICATION' })
    try {
      const response = await axios.post(
        `${API_HOST}/v1/applications/${applicationId}/start`
      )
      dispatch({
        type: 'START_APPLICATION_SUCCESS',
        payload: response.data.secondsToTimeout
      })

      if (application.exam.shuffleItems) {
        resumeShuffledItemsApplication()
      } else {
        resumeApplication()
      }
    } catch (e) {
      dispatch({
        type: 'START_APPLICATION_ERROR',
        payload: t('An error occurred. Please try again.')
      })
    }
  }

  const removeItems = () => {
    const itemIds = answers.map((ans) => ans.item.id)
    return db.items.where('id').anyOf(itemIds).delete()
  }

  const removeAnswers = () => {
    const answerIds = answers.map((ans) => ans.id)
    return db.answers.where('id').anyOf(answerIds).delete()
  }

  const handleFinishApplication = async () => {
    if (!application) {
      return
    }

    dispatch({ type: 'FINISH_APPLICATION' })
    updateExamStatus({ finished: true })
    const payload = await getSyncAnswersPayload()
    try {
      await axios.post(
        `${API_HOST}/v1/applications/${applicationId}/finish`,
        payload
      )

      try {
        await removeItems()
      } catch (_) {
        // If unable to remove items, just ignore it
      }

      try {
        await removeAnswers()
      } catch (_) {
        // If unable to remove answers, just ignore it
      }

      dispatch({ type: 'FINISH_APPLICATION_SUCCESS' })
      const collectionId = application.exam?.collection?.id
      if (collectionId) {
        history.push(`/applications/?collection=${collectionId}`)
      } else {
        history.push('/applications')
      }
      return true
    } catch (e) {
      dispatch({
        type: 'FINISH_APPLICATION_ERROR',
        payload: t('An error occurred. Please try again.')
      })
      return false
    }
  }

  // If application reaches timeout open warn user and finish application
  const handleTimeout = async () => {
    ReactSwal({
      title: t('The exam has timed out'),
      text: t(
        'Your responses will be saved and you will be redirected to the list of exams.'
      ),
      icon: 'warning',
      dangerMode: true
    })
    const finished = await handleFinishApplication()
    if (!finished) {
      ReactSwal({
        title: t('An error occurred'),
        text: t('Please check your internet connection and try again.'),
        icon: 'error',
        buttons: {
          confirm: {
            text: t('Try again')
          }
        },
        dangerMode: true
      }).then(() => {
        handleTimeout()
      })
    }
  }

  const getUnansweredItems = () => {
    if (!application) {
      return
    }
    return db.answers
      .filter((d) => d.application.id === application.id)
      .filter((d) => !d.freeResponse)
      .filter((d) => !d.alternative)
      .count()
  }

  const finishApplication = async () => {
    setReviewModal(true)
    const items = await getUnansweredItems()
    setUnansweredItems(items)
  }

  const checkForCameraPermissions = async () => {
    if (!VIDEO_RECORDING_ENABLED) {
      return
    }
    if (!application || !application.exam || !application.exam.collection) {
      return
    }
    const collection = application.exam.collection
    if (!collection.applicationConfiguration) {
      return
    }
    try {
      const result = await axios.get(
        `${API_HOST}/v1/application_configuration/${collection.applicationConfiguration}`
      )
      const configuration = result.data as IApplicationConfiguration
      if (!configuration.requiresVideo) {
        return
      }
    } catch (e) {
      return
    }
    const permissionsOk = await permissionsAreOk()
    if (permissionsOk) {
      setCameraAccepted(true)
      return
    }

    setCameraAccepted(false)
    swal
      .fire({
        icon: 'error',
        text: t(
          'You refused to grant the necessary permissions, therefore you cannot proceed on the exams.'
        ),
        backdrop: 'rgb(0, 0, 0, 1)',
        allowOutsideClick: false
      })
      .then((result) => {
        if (result.value) {
          history.replace('/applications')
        }
      })
  }

  const joinRoom = () => {
    if (!application) {
      return
    }
    joinRoomAsCandidate(application.roomId)
  }

  // Fetches application on component mount
  useEffect(() => {
    fetchApplication()
  }, [fetchApplication])

  // Fetches answers when application is defined
  useEffect(() => {
    fetchAnswers()
  }, [fetchAnswers])

  useEffect(() => {
    checkForCameraPermissions()
    joinRoom()
  }, [application, checkForCameraPermissions])

  if (reviewModal) {
    return (
      <Modal
        isOpen={reviewModal}
        cancelText="Continuar prova"
        actionText="Concluir"
        onAction={() => handleFinishApplication()}
        onClose={() => setReviewModal(false)}
        onCancel={() => setReviewModal(false)}
        title={t('Are you sure you want to finish this exam?')}
      >
        {unansweredItems > 0 &&
          t('You have {{unansweredItems}} questions without an answer.', {
            unansweredItems
          })}
      </Modal>
    )
  }

  const contextValue = {
    ...state,
    dispatch,
    fetchApplication,
    fetchAnswers,
    startApplication,
    resumeApplication,
    finishApplication,
    handleTimeout
  }

  return (
    <ApplicationContext.Provider value={contextValue}>
      {children(contextValue)}
    </ApplicationContext.Provider>
  )
}

export default ApplicationState
