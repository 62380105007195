import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { get } from 'lodash'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import Skeleton from 'react-loading-skeleton'
import styled from 'styled-components'
import { IndividualReportCardContext } from '../../states/IndividualReportCardState'
import Badge from '../Badge'
import ReportCardItem from './ReportCardItem'

type ExamsTableProps = {
  className?: string
}

const ExamsTable = ({ className }: ExamsTableProps) => {
  const { individualReportCard } = useContext(IndividualReportCardContext)
  const { t } = useTranslation()

  if (individualReportCard === undefined) {
    return (
      <div className={className}>
        <Skeleton height={500} />
      </div>
    )
  }

  return (
    <div className={className}>
      <div className="header">
        <div>
          <span>{t('Exams')}</span>
        </div>
        <div>
          <span>{t('Your score')}</span>
        </div>
        <div>
          <span>{t('All students overall score')}</span>
        </div>
      </div>
      {individualReportCard.exams.map((e, index) => {
        return (
          <div className="row" key={e.id}>
            <div>
              <span>
                <span className="exam-prefix">• P{index + 1}</span>
                <span>{`- ${get(e, 'exam.name')}`}</span>
                <span className="exam-details">
                  ({t('Total Items')}: {e.totalItens})
                </span>
              </span>
              <ReportCardItem items={get(e, 'exam.items', [])} />
            </div>
            <div className="user-result">
              <span>{e.generalGrade}</span>
              {e.generalGrade >= e.classGrade && <Badge type="positive" />}
            </div>
            <div>
              <span>{e.classGrade}</span>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default styled(ExamsTable)`
  margin-top: 10px;
  margin-bottom: 2rem;

  .header,
  .row {
    display: inline-grid;
    grid-template-columns: 2fr 1fr 1fr;
    align-content: center;
    justify-content: start;
    width: 100%;

    div {
      display: flex;
      margin: 2px;
      display: grid;
      align-content: center;
      padding-left: 2rem;
    }
  }

  .header {
    div {
      color: white;
      font-weight: 550;
      background-color: ${(props) => props.theme.clientColors.secondary};
    }
  }

  .user-result {
    display: flex !important;
    align-items: center;

    span {
      margin-right: 10px;
    }
  }

  .row {
    div {
      background-color: #f5f5f5;
      font-weight: 550;
    }
    .exam-details {
      font-weight: 500;
      font-size: 12px;
      margin-left: 10px;
    }
  }

  .exam-prefix {
    font-size: 14px;
    margin-right: 8px;
    font-weight: 800;
  }
`
