import styled from 'styled-components'

export default styled.span`
  position: -webkit-sticky;
  position: fixed;
  bottom: 10px;
  right: 10px;
  width: 15rem;
  padding: 20px;
  z-index: 1;
  background: ${(props) => props.theme.colors.danger};
  color: ${(props) => props.theme.colors.grayLight};
`
