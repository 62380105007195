import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faChrome as fabChrome,
  faFirefoxBrowser as fabFirefoxBrowser,
  faOpera as fabOpera,
  faSafari as fabSafari
} from '@fortawesome/free-brands-svg-icons'
import {
  faCalendarAlt as farCalendarAlt,
  faClock as farClock
} from '@fortawesome/free-regular-svg-icons'
import {
  faArrowLeft,
  faArrowRight,
  faCalendar,
  faCheck,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircleNotch,
  faClock,
  faDownload,
  faEllipsisV,
  faEye,
  faEyeSlash,
  faHourglassHalf,
  faPrint,
  faSignOutAlt,
  faThumbsDown,
  faThumbsUp,
  faTimes,
  faQuestionCircle
} from '@fortawesome/free-solid-svg-icons'
import {
  faCalendarExclamation as farCalendarExclamation,
  faCheck as farCheck,
  faHorizontalRule as farHorizontalRule,
  faMinusCircle as farMinusCircle,
  faPause as farPause,
  faPlay as farPlay
} from '@fortawesome/pro-regular-svg-icons'

library.add(
  faCalendar,
  faHourglassHalf,
  faChevronRight,
  faChevronLeft,
  faCircleNotch,
  faEye,
  faEyeSlash,
  faSignOutAlt,
  farCalendarExclamation,
  farClock,
  farCalendarAlt,
  farCheck,
  farPause,
  farPlay,
  farMinusCircle,
  farHorizontalRule,
  fabFirefoxBrowser,
  fabChrome,
  fabSafari,
  fabOpera,
  faClock,
  faThumbsUp,
  faThumbsDown,
  faPrint,
  faTimes,
  faCheck,
  faEllipsisV,
  faDownload,
  faArrowRight,
  faArrowLeft,
  faChevronUp,
  faChevronDown,
  faQuestionCircle
)
