import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import styled from 'styled-components'
import Button, { ButtonProps } from './Button'

export interface PromiseButtonProps extends ButtonProps {
  onClick?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => Promise<any>
  icon?: IconProp
}

const PromiseButton = ({
  className,
  icon,
  children,
  onClick,
  ...rest
}: PromiseButtonProps) => {
  const [promiseIsLoading, setPromiseIsLoading] = useState(false)

  return (
    <Button
      className={className}
      disabled={promiseIsLoading}
      onClick={(event) => {
        if (!onClick) {
          return
        }

        setPromiseIsLoading(true)
        const result = onClick(event)
        if (result !== undefined) {
          result
            .then((resolved) => {
              setPromiseIsLoading(false)
              return resolved
            })
            .catch((error) => {
              setPromiseIsLoading(false)
              throw error
            })
        }
      }}
      {...rest}
    >
      {icon &&
        (promiseIsLoading ? (
          <FontAwesomeIcon spin icon="circle-notch" />
        ) : (
          <FontAwesomeIcon icon={icon} />
        ))}{' '}
      {children}
    </Button>
  )
}

export default styled(PromiseButton)``
