import React from 'react'
import styled from 'styled-components'
import { ICollection } from 'types'

type ExamDashboardExamInfoProps = {
  className?: string
  collection: ICollection
}

const ExamDashboardExamInfo = ({
  className,
  collection
}: ExamDashboardExamInfoProps) => {
  return (
    <div className={className}>
      <span className="exam-dashboard-title">
        <span>{collection && collection.name}</span>
      </span>
    </div>
  )
}

export default styled(ExamDashboardExamInfo)`
  font-size: 14px;
  .exam-dashboard-title {
    color: ${(props) => props.theme.clientColors.text};
    font-size: 30px;
  }

  .exam-dashboard-title > span {
    font-weight: bolder;
  }
`
