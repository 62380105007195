import AgoraRTC from 'agora-rtc-sdk'
import { AGORA_API_KEY, CLOUD_RECORDING_LAMBDA_URL } from './consts'
import { IUser } from 'types'
import { RollbarErrorTracking } from 'utils/rollbar'
import Axios from 'axios'

class VideoStreamingManager {
  private stream: any
  private client: any
  private user: IUser
  private isRecording = false

  public startStreamingForUser(user: IUser) {
    if (!user || this.isRecording) {
      return
    }
    this.isRecording = true
    this.user = user
    this.stream = AgoraRTC.createStream({
      streamID: user.id,
      audio: true,
      video: true,
      screen: false
    })
    this.client = AgoraRTC.createClient({ mode: 'live', codec: 'h264' })
    this.handleStream()
  }

  public destroy() {
    this.isRecording = false
    try {
      this.client && this.client.unpublish(this.stream)
      this.stream && this.stream.stop()
      this.stream && this.stream.close()
    } catch (e) {
      console.log(e)
    }
  }

  async startCloudRecording() {
    try {
      await Axios.post(CLOUD_RECORDING_LAMBDA_URL, {
        userId: this.user.id,
        provider: this.user.provider.codename
      })
    } catch (e) {
      RollbarErrorTracking.LogError(e)
    }
  }

  async handleStream() {
    const agoraStream = this.stream
    const agoraClient = this.client
    const user = this.user
    try {
      const channelName = `${user.provider.codename}_${user.id}`
      // If agora didn't initialize for some reason,
      // give up on the connection
      if (!agoraStream || !agoraClient) {
        return console.error('Tried to stream with no stream')
      }
      await this.startCloudRecording()
      agoraStream.init(
        () => {
          agoraClient.init(
            AGORA_API_KEY,
            () => {
              agoraClient.join(
                null,
                channelName,
                user.id,
                () => {
                  agoraClient.publish(agoraStream)
                },
                (agoraClientJoinError) => {
                  console.error('Agora client join error', agoraClientJoinError)
                }
              )
            },
            (agoraClientInitError) => {
              console.error('Agora client init error', agoraClientInitError)
            }
          )
        },
        (agoraStreamInitError) => {
          console.error('Agora stream init error', agoraStreamInitError)
        }
      )
    } catch (e) {
      console.log(e)
    }
  }
}

const streamingManager = new VideoStreamingManager()
export default streamingManager
