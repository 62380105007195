import React, { ReactNode } from 'react'
import styled from 'styled-components'

type ImportPageInfoProps = {
  className?: string
  title: string | ''
  instructions: ReactNode
}

const ImportPageInfo = ({
  className,
  title,
  instructions
}: ImportPageInfoProps) => {
  return (
    <div className={className}>
      <span className="import-page-title">
        <span>{title}</span>
      </span>
      <div className="info">
        <div>{instructions}</div>
      </div>
    </div>
  )
}

export default styled(ImportPageInfo)`
  font-size: 14px;
  margin-bottom: 1rem;
  .import-page-title {
    color: ${(props) => props.theme.clientColors.text};
    font-size: 30px;
  }

  .import-page-title > span {
    font-weight: bolder;
  }

  .info {
    display: grid;
    max-width: 600px;
    margin-top: 12px;
  }

  .info > div {
    grid-template-rows: 20px;
  }

  .info > div > span:first-child {
    font-weight: 600;
  }
`
