import React, { useContext } from 'react'
import { NetworkContext } from '../states/NetworkState'
import ErrorAlert from './ErrorAlert'
import SuccessAlert from './SuccessAlert'

const OfflineMessage = () => {
  const { offlineMessage, isConnected } = useContext(NetworkContext)

  if (!offlineMessage) {
    return null
  }

  return isConnected ? (
    <SuccessAlert>{offlineMessage}</SuccessAlert>
  ) : (
    <ErrorAlert>{offlineMessage}</ErrorAlert>
  )
}

export default OfflineMessage
