import axios from 'axios'
import { groupBy, sortBy } from 'lodash'
import { useContext, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { API_HOST } from '../consts'
import db from '../db'
import { ConfigContext } from '../states/ConfigState'

const syncAnswers = async () => {
  // Gets all unsynced answers
  const answers = await db.answers.where({ _changed: 1 }).toArray()

  // Groups answers by application
  const sortedAnswers = sortBy(answers, (d) => d.application.id)
  const answersByApplication = groupBy(sortedAnswers, (d) => d.application.id)

  // For each application with unsynced answers,
  // makes an api call to sync the answers
  Object.entries(answersByApplication).forEach(async (entry) => {
    try {
      const [applicationId, applicationAnswers] = entry

      const payload = applicationAnswers.map((answer) => ({
        id: answer.id,
        alternativeId: answer.alternative?.id,
        freeResponse: answer.freeResponse,
        seconds: answer.seconds
      }))

      await axios.put(
        `${API_HOST}/v1/applications/${applicationId}/answers/batch`,
        payload
      )

      const applicationAnswerIds = applicationAnswers.map((d) => d.id)

      // If unable to modify, the only problem is that the api call will be
      // made again in the next interval
      db.answers.where('id').anyOf(applicationAnswerIds).modify({ _changed: 0 })
    } catch (e) {
      console.log(e.toString())
    }
  })
}

const useAnswerSync = () => {
  const { syncAnswerInterval } = useContext(ConfigContext)
  const location = useLocation()

  useEffect(() => {
    if (location.pathname.startsWith('/applications')) {
      const interval = setInterval(syncAnswers, syncAnswerInterval)
      return () => clearInterval(interval)
    }
  }, [location.pathname, syncAnswerInterval])
}

export default useAnswerSync
export { syncAnswers }
