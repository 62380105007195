import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useContext } from 'react'
import { Link, useHistory } from 'react-router-dom'
import styled, { ThemeContext } from 'styled-components'
import { AuthContext } from '../states/AuthState'
import Button from './Button'
import { ApplicationContext } from '../states/ApplicationState'
import swal from 'sweetalert'
import { useTranslation } from 'react-i18next'
import ApplicationBreadcrumb from './ApplicationBreadcrumb'
import streamingManager from 'videoStreaming'

type HeaderProps = {
  className?: string
}

const Header = ({ className }: HeaderProps) => {
  const theme = useContext(ThemeContext)
  const { user, logout } = useContext(AuthContext)
  const { application } = useContext(ApplicationContext)
  const history = useHistory()
  const { t } = useTranslation()

  const navLogout = () => {
    const cancelButton = t('NO')
    const confirmButton = t('YES')

    swal({
      title: t('Are you sure you want to leave the testing site?'),
      icon: 'warning',
      buttons: [cancelButton, confirmButton],
      dangerMode: true
    }).then((confirm) => {
      if (confirm) {
        logout().finally(() => {
          streamingManager.destroy()
          history.push('/login')
          window.location.reload()
        })
      }
    })
  }

  return (
    <div className={className}>
      <nav>
        <ul>
          <li>
            <Link to="/">
              <img height={70} src={theme.logoImg} alt={theme.name} />
            </Link>
          </li>
          <li>
            <p>{user?.name}</p>
            {application && (
              <p>
                <strong>{application?.exam.name}</strong>
              </p>
            )}
          </li>
          <li>
            <Button action="unstyled" onClick={navLogout}>
              <FontAwesomeIcon icon="sign-out-alt" />
            </Button>
          </li>
        </ul>
      </nav>
      <ApplicationBreadcrumb />
    </div>
  )
}

export default styled(Header)`
  background: white;
  width: 100%;

  nav {
    max-width: 1366px;
    margin: auto;
  }

  ul {
    margin: 0;
    display: grid;
    grid-template-columns: 8rem auto 3rem;
    list-style: none;
    align-items: center;
    text-align: right;
    padding: 5px 20px;

    li {
      align-items: center;
      p {
        margin: 0;
        line-height: 1.5;
      }
    }
  }
`
