import axios, { AxiosRequestConfig } from 'axios'

export const getFilenameFromHeader = (headers: any) => {
  const contentDisposition: string = headers['content-disposition']
  if (!contentDisposition) {
    return undefined
  }
  const [, filename] = contentDisposition
    .replace(/"/g, '')
    .split('attachment; filename=')
  return filename
}

export const downloadFile = async (axiosConfig: AxiosRequestConfig) => {
  const response = await axios({ responseType: 'blob', ...axiosConfig })
  const url = window.URL.createObjectURL(new Blob([response.data]))
  const link = document.createElement('a')
  link.href = url
  const filename = getFilenameFromHeader(response.headers) || 'filename'
  link.setAttribute('download', filename)
  document.body.appendChild(link)
  link.click()
  // Removes blob from memory
  window.URL.revokeObjectURL(link.href)
  return true
}
