import { FormControl, FormLabel, RadioGroup } from '@material-ui/core'
import { useField, useFormikContext } from 'formik'
import React, { ReactNode } from 'react'
import styled from 'styled-components'

type RadioInputGroupProps = {
  name: string
  label?: ReactNode
  children: ReactNode
  className?: string
}

const RadioInputGroup = ({
  className,
  name,
  label,
  children
}: RadioInputGroupProps) => {
  const { setFieldValue } = useFormikContext()
  const [field] = useField({
    name
  })

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFieldValue(field.name, event.target.value)
  }

  return (
    <FormControl component="fieldset" className={className}>
      {label && <FormLabel component="legend">{label}</FormLabel>}
      <RadioGroup value={field.value} onChange={handleChange}>
        {children}
      </RadioGroup>
    </FormControl>
  )
}

export default styled(RadioInputGroup)``
