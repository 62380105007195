import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import Skeleton from 'react-loading-skeleton'
import styled from 'styled-components'
import { IndividualReportCardContext } from '../../states/IndividualReportCardState'
import Badge from '../Badge'
import ColorfulProgressBar from '../ColorfulProgressBar'

type ComparativeBoardProps = {
  className?: string
}

const ComparativeBoard = ({ className }: ComparativeBoardProps) => {
  const { t } = useTranslation()
  const { individualReportCard, fetchingIndividualReportCard } = useContext(
    IndividualReportCardContext
  )

  const renderComparativeResult = (userResult: number, classResult: number) => {
    const getMessage = (userResult: number, classResult: number) => {
      if (userResult > classResult) {
        return 'You are above average'
      }
      if (userResult < classResult) {
        return 'You are below average'
      }
      return 'You are average'
    }

    const message = getMessage(userResult, classResult)
    const badgeType = userResult >= classResult ? 'positive' : 'negative'

    return (
      <div className={`icon ${badgeType}`}>
        <Badge type={badgeType} />
        <span>{t(message)}</span>
      </div>
    )
  }

  return (
    <>
      <h2>{t('Overall Comparative')}</h2>
      {fetchingIndividualReportCard ? (
        <Skeleton height={110} />
      ) : (
        <div className={className}>
          <div className="user-result">
            <label>{t('Your overall result')}</label>
            <ColorfulProgressBar
              showValues
              max={individualReportCard?.totalItens}
              value={individualReportCard?.generalGrade}
            />
          </div>
          <div className="class-result">
            <label>{t('All students result')}</label>
            <ColorfulProgressBar
              showValues
              max={individualReportCard?.totalItens}
              value={individualReportCard?.classGrade}
            />
          </div>
          <div className="comparative-result">
            <label>{t('Regarding all students')}:</label>
            {renderComparativeResult(
              individualReportCard?.generalGrade || 0,
              individualReportCard?.classGrade || 0
            )}
          </div>
        </div>
      )}
    </>
  )
}

export default styled(ComparativeBoard)`
  display: flex;
  align-items: stretch;
  border: 0.05em solid #dddddd;
  background-color: ${(props) => props.theme.colors.grayLight};
  padding: 1rem;

  label {
    font-size: 11pt;
    font-weight: 600;
    margin-bottom: 10px;
    display: block;
  }

  .user-result {
    flex: 1;
  }

  .class-result {
    flex: 2;
  }

  .comparative-result {
    .icon {
      display: flex;
      align-items: center;
      span {
        margin-left: 10px;
      }
    }
    .negative {
      color: ${(props) => props.theme.reportColors.negative};
    }
    .positive {
      color: ${(props) => props.theme.reportColors.positive};
    }
  }
`
