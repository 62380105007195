import axios from 'axios'
import { Formik, FormikValues } from 'formik'
import { get } from 'lodash'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import swal from 'sweetalert'
import { API_HOST } from '../../consts'
import Button from '../Button'
import UserInput from '../Inputs/UserInput'
import Modal from '../Modal/Modal'
import NewStudentForm from './NewStudentForm'
import useQuery from 'hooks/useQuery'

type AddStudentModalProps = {
  isOpen: boolean
  setIsOpen: Function
  providerCodename?: string
  className?: string
}

const AddStudentModal = ({
  isOpen,
  setIsOpen,
  providerCodename = '',
  className
}: AddStudentModalProps) => {
  const [isNewStudent, setIsNewStudent] = useState(false)
  const { t } = useTranslation()
  const query = useQuery()

  const collectionId = +(query.get('collection') || '')

  const handleClose = () => {
    setIsOpen(false)
    setIsNewStudent(false)
  }

  const addStudent = async (values: FormikValues) => {
    // add existing student to exam
    try {
      const payload = {
        user: {
          id: values.user.id
        },
        collection: collectionId
      }
      await axios.post(`${API_HOST}/v1/applications`, payload)
      swal({
        icon: 'success',
        title: t('User added to the collection!')
      })
      setIsOpen(false)
    } catch (_) {
      console.log(_)
    }
  }

  const registerNewStudent = () => {
    // open module to fill out form for new student
    setIsOpen(false)
    setIsNewStudent(true)
  }

  const addNewStudent = async (values: FormikValues, { setFieldError }) => {
    // post new student form info using form values
    const { name, public_identifier, email, ...hierarchyCategories } = values
    const hierarchy = Object.entries(hierarchyCategories).reduce(
      (acc, entry) => {
        const [key, value] = entry
        return {
          ...acc,
          [key]: {
            value: value.value,
            name: value.name
          }
        }
      },
      {}
    )

    const payload = {
      user: {
        name,
        public_identifier,
        email,
        extra: JSON.stringify({
          hierarchy
        })
      },
      collection: collectionId
    }
    try {
      await axios.post(`${API_HOST}/v1/applications`, payload)
      setIsNewStudent(false)
      swal({
        icon: 'success',
        title: t('User created and added to the collection!')
      })
    } catch (err) {
      setFieldError('email', get(err, 'response.data.user.email.0'))
      setFieldError(
        'public_identifier',
        get(err, 'response.data.user.public_identifier.0')
      )
    }
  }

  const validateAddStudentForm = (values) => {
    const errors: { [key: string]: string } = {}
    const requiredMsg = t('This field is required.')
    if (!values.user) {
      errors.user = requiredMsg
    }
    return errors
  }

  const validateNewStudentForm = (values) => {
    const errors: { [key: string]: string } = {}
    const requiredMsg = t('This field is required.')
    const { name, public_identifier, ...hierarchy } = values

    if (!name) {
      errors.name = requiredMsg
    }
    if (!public_identifier) {
      errors.public_identifier = requiredMsg
    }
    Object.entries(hierarchy).forEach((entry) => {
      const [key, value] = entry
      if (!value) {
        errors[key] = requiredMsg
      }
    })
    return errors
  }

  return (
    <div className={className}>
      {/* add existing student */}
      <Formik
        initialValues={{}}
        onSubmit={addStudent}
        validate={validateAddStudentForm}
      >
        {(formik) => (
          <Modal
            isOpen={isOpen}
            onAction={formik.handleSubmit}
            onClose={handleClose}
            onCancel={handleClose}
            actionDisabled={formik.isSubmitting}
            actionText={t('Add student')}
            title={t('Add Student')}
          >
            <div data-testid="add-student-modal">
              <div className="form-grid">
                <UserInput />
              </div>
              <Button onClick={registerNewStudent} action="link">
                {t(
                  'Didn’t find the student? Click here to register a new student.'
                )}
              </Button>
            </div>
          </Modal>
        )}
      </Formik>

      {/* create new student */}
      <Formik
        initialValues={{}}
        onSubmit={addNewStudent}
        validate={validateNewStudentForm}
      >
        {(formik) => (
          <Modal
            isOpen={isNewStudent}
            onClose={handleClose}
            onAction={formik.submitForm}
            onCancel={handleClose}
            actionDisabled={formik.isSubmitting}
            title={t('Add New Student')}
          >
            <NewStudentForm providerCodename={providerCodename} />
          </Modal>
        )}
      </Formik>
    </div>
  )
}

export default styled(AddStudentModal)`
  .form-grid {
    display: grid;
    row-gap: 1rem;
    grid-auto-rows: max-content;
  }
`
