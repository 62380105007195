import classnames from 'classnames'
import { useField, useFormikContext } from 'formik'
import React from 'react'
import { OptionTypeBase } from 'react-select'
import AsyncSelect from 'react-select/async'
import styled from 'styled-components'

interface InputAsyncProps extends OptionTypeBase {
  loadOptions: (search?: any) => Promise<any>
  className?: string
  label?: string
  name: string
  help?: string
  defaultOptions?: boolean
  placeholder?: string
  getOptionLabel?: (option: any) => string
  getOptionValue?: (option: any) => string
  onChange?: Function
  required?: boolean
}

const InputAsync = ({
  help,
  label,
  className,
  defaultOptions,
  placeholder,
  loadOptions,
  name,
  onChange,
  required,
  ...rest
}: InputAsyncProps) => {
  const { setFieldValue, submitCount } = useFormikContext()
  const [field, meta] = useField({
    name
  })
  const error = submitCount > 0 ? meta.error : undefined

  return (
    <div className={className}>
      {label && (
        <span
          className={classnames('input-label', { 'input-required': required })}
        >
          {label}
        </span>
      )}
      <AsyncSelect
        className="inner-field"
        isClearable
        placeholder={placeholder || 'Buscar...'}
        loadingMessage={() => 'Carregando...'}
        noOptionsMessage={() => 'Nenhum resultado encontrado'}
        cacheOptions={false}
        defaultOptions={defaultOptions}
        loadOptions={loadOptions}
        onChange={(value) => {
          setFieldValue(field.name, value)
          onChange && onChange(value)
        }}
        {...rest}
      />
      {error && <span className="input-helper red">{error}</span>}
      {error && help && <br />}
      {help && <span className="input-helper">{help}</span>}
    </div>
  )
}

export default styled(InputAsync)`
  .input-label {
    font-weight: bolder;
  }

  .inner-field {
    margin-top: 5px;
  }

  .input-required {
    &:after {
      content: '*';
    }
  }
`
