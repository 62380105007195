import React, { ReactNode, useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'
import Page from './Page'

type LogoPageProps = {
  className?: string
  children: ReactNode
}

const LogoPage = ({ className, children }: LogoPageProps) => {
  const theme = useContext(ThemeContext)

  return (
    <Page className={className}>
      {/* TODO: Populate alt props with real provider name */}
      <img src={theme.logoImg} width={200} alt={theme.name} />
      {children}
    </Page>
  )
}

export default styled(LogoPage)`
  text-align: center;

  img {
    margin-top: 100px;
    margin-bottom: 50px;
  }
`
