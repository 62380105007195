import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import swal from 'sweetalert2'
import { AnswerContext } from '../states/AnswerState'
import { ApplicationContext } from '../states/ApplicationState'
import { IAnswer } from '../types'
import Button from './Button'
import ReviewButton from './Buttons/ReviewButton'

type ChangeAnswerButtonsProps = {
  className: string
}

const ChangeAnswerButtons = ({
  className
}: Partial<ChangeAnswerButtonsProps>) => {
  const { answer, goAnswer, getAnswerFromPosition, isAnswered } = useContext(
    AnswerContext
  )
  const { application } = useContext(ApplicationContext)
  const { t } = useTranslation()
  const previousAnswer = answer
    ? getAnswerFromPosition(answer.position - 1)
    : undefined
  const nextAnswer = answer
    ? getAnswerFromPosition(answer.position + 1)
    : undefined

  const handleRedirect = async (answer: IAnswer) => {
    if (!answer) {
      return
    }

    goAnswer(answer)
  }

  const handleNextAnswer = () => {
    if (
      answer &&
      !isAnswered(answer) &&
      !application.exam.canBrowseAcrossItems
    ) {
      swal.fire({
        icon: 'warning',
        title: t('Please answer the question to go to the next item')
      })
    } else {
      if (nextAnswer) {
        handleRedirect(nextAnswer)
      }
    }
  }

  return (
    <div className={className}>
      {/*
        Divs here are necessary to always render previous button on the left
        and next button on the right
      */}
      <div>
        {application?.exam.canBrowseAcrossItems && (
          <Button
            action="primary"
            type="button"
            onClick={() => {
              handleRedirect(previousAnswer)
            }}
            style={{ visibility: previousAnswer ? 'visible' : 'hidden' }}
          >
            {t('previous')}
          </Button>
        )}
      </div>
      <div>
        <Button
          action="primary"
          type="button"
          onClick={() => {
            nextAnswer && handleNextAnswer()
          }}
          style={{
            visibility: nextAnswer ? 'visible' : 'hidden',
            display: nextAnswer ? '' : 'none'
          }}
        >
          {t('next', { context: 'fs' })}
        </Button>
        <ReviewButton
          style={{ display: nextAnswer ? 'none' : '' }}
          canNavigateOnExamItems={false}
        />
      </div>
    </div>
  )
}

export default styled(ChangeAnswerButtons)`
  display: flex;
  justify-content: space-between;
`
