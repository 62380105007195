import axios from 'axios'
import { useFormikContext } from 'formik'
import { get } from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { API_HOST } from '../consts'
import { IHierarchyCategory } from '../types'
import HierarchyInputAsync from './Inputs/HierarchyInputAsync'

type HierarchyFilterBoxProps = {
  providerCodename?: string
  className?: string
  distinct?: boolean
  required?: string[]
}

const HierarchyFilter = ({
  providerCodename,
  distinct = true,
  required = []
}: HierarchyFilterBoxProps) => {
  const [hierarchyCategories, setHierarchyCategories] = useState<
    IHierarchyCategory[]
  >([])
  const formik = useFormikContext()

  const fetchHierarchyCategories = useCallback(async () => {
    try {
      const response = await axios.get<IHierarchyCategory[]>(
        `${API_HOST}/v1/provider/${providerCodename}/hierarchy_category`
      )
      setHierarchyCategories(
        response.data.map((category) => ({
          ...category,
          parent: response.data.find((c) => c.id === category.parentId)
        }))
      )
    } catch (_) {}
  }, [providerCodename])

  useEffect(() => {
    fetchHierarchyCategories()
  }, [fetchHierarchyCategories])

  const getCategoryParentId = useCallback(
    (category: IHierarchyCategory) => {
      const parentCategory = hierarchyCategories.find(
        (c) => c.id === category.parentId
      )
      if (!parentCategory) {
        return undefined
      }

      const parentId = get(formik.values, `${parentCategory.codename}.id`)
      return parentId
    },
    [hierarchyCategories, formik.values]
  )

  return (
    <>
      {hierarchyCategories.map((category: IHierarchyCategory) => (
        <HierarchyInputAsync
          required={required.find((d) => d === category.codename) !== undefined}
          distinct={distinct}
          key={category.codename}
          category={category}
          parentId={getCategoryParentId(category)}
        />
      ))}
    </>
  )
}

export default styled(HierarchyFilter)``
