import React, { useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'
import Stripe from './Stripe'

type FooterProps = {
  className?: string
}

const Footer = ({ className }: FooterProps) => {
  const theme = useContext(ThemeContext)
  const { footerMessage } = theme

  return (
    <Stripe className={className}>
      <small>{footerMessage}</small>
    </Stripe>
  )
}

export default styled(Footer)`
  background-color: ${(props) => props.theme.clientColors.secondary};
  position: absolute;
  bottom: 0;
  width: 100%;

  small {
    font-weight: bold;
    font-size: 12px;
    color: white;
  }
  div {
    flex: 1;
    justify-content: space-between;
    padding: 20px;
  }
`
