import { useContext, useEffect } from 'react'
import GoogleAnalytics from 'react-ga'
import { useLocation } from 'react-router-dom'
import { GA_TRACKING_CODE } from '../consts'
import { AuthContext } from '../states/AuthState'

const useGoogleAnalytics = () => {
  const location = useLocation()
  const trackingCode = GA_TRACKING_CODE
  const { user } = useContext(AuthContext)

  const trackPage = (page: string) => {
    GoogleAnalytics.set({
      page
    })
    GoogleAnalytics.pageview(page)
  }

  useEffect(() => {
    if (trackingCode) {
      GoogleAnalytics.initialize(trackingCode)
    }
  }, [trackingCode])

  useEffect(() => {
    GoogleAnalytics.set({ userId: user?.id })
  }, [user])

  useEffect(() => {
    if (trackingCode) {
      const page = location.pathname + location.search
      trackPage(page)
    }
  }, [location.pathname, location.search, trackingCode])
}

export default useGoogleAnalytics
