import axios from 'axios'
import debounce from 'debounce-promise'
import { useCallback, useEffect, useReducer } from 'react'
import { IListState, ListStateAction } from '../types'

export const initialState: IListState = {
  results: [],
  count: 0,
  numPages: 0,
  next: undefined,
  previous: undefined,
  page: 1,
  pageSize: 50,
  isLoading: false,
  hasError: false,
  filterParams: {},
  ordering: '',
  search: '',
  handleFilter: () => null,
  handlePageChange: () => null,
  handlePageSizeChange: () => null
}

const reducer = (state: IListState, action: ListStateAction): IListState => {
  switch (action.type) {
    case 'FETCH_LIST':
      return { ...state, isLoading: true, hasError: false }
    case 'FETCH_LIST_SUCCESS':
      return { ...state, isLoading: false, hasError: false, ...action.payload }
    case 'FETCH_LIST_ERROR':
      return { ...state, isLoading: false, hasError: true }
    case 'HANDLE_FILTER':
      return { ...state, filterParams: action.payload }
    case 'CHANGE_PAGE':
      return { ...state, page: action.payload }
    case 'CHANGE_PAGE_SIZE':
      return { ...state, pageSize: action.payload }
    case 'CHANGE_SEARCH':
      return { ...state, search: action.payload }
    default:
      return state
  }
}

export type UseListProps = {
  api: string
  defaultPageSize?: number
  defaultOrdering?: string
  fetchInterval?: number
}

export const useList = ({
  api,
  defaultPageSize,
  defaultOrdering,
  fetchInterval
}: UseListProps) => {
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    pageSize: defaultPageSize || initialState.pageSize,
    ordering: defaultOrdering || initialState.ordering
  })

  const handleFilter = (filterParams?: any) => {
    dispatch({ type: 'HANDLE_FILTER', payload: filterParams })
  }

  const handleSearchChange = useCallback(
    debounce((search: string | undefined) => {
      dispatch({ type: 'CHANGE_SEARCH', payload: search })
    }, 500),
    []
  )

  const handlePageChange = (page: number) => {
    dispatch({ type: 'CHANGE_PAGE', payload: page })
  }

  const handlePageSizeChange = (page: number) => {
    dispatch({ type: 'CHANGE_PAGE_SIZE', payload: page })
  }

  const { page, filterParams, pageSize, ordering, search } = state

  const fetchList = useCallback(
    async (shouldDispatchFetching = true) => {
      if (shouldDispatchFetching) {
        dispatch({ type: 'FETCH_LIST' })
      }
      try {
        const params = {
          ...filterParams,
          page,
          page_size: pageSize,
          ordering,
          search
        }
        const response = await axios.get(api, { params })
        if (response.data.results) {
          dispatch({ type: 'FETCH_LIST_SUCCESS', payload: response.data })
        } else {
          dispatch({
            type: 'FETCH_LIST_SUCCESS',
            payload: { results: response.data }
          })
        }
      } catch (_) {
        dispatch({ type: 'FETCH_LIST_ERROR' })
      }
    },
    [api, page, filterParams, pageSize, ordering, search]
  )

  useEffect(() => {
    fetchList()
    if (fetchInterval) {
      const fetchIntervalFunction = setInterval(
        () => fetchList(false),
        fetchInterval
      )
      return () => clearInterval(fetchIntervalFunction)
    }
  }, [fetchList])

  const value = {
    ...state,
    fetchList,
    handleFilter,
    handlePageChange,
    handlePageSizeChange,
    handleSearchChange
  }

  return value
}

export default useList
