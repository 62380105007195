import React, { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import Separator from '../ExamDashboardComponents/Separator'
type LightBorderCardProps = {
  children?: ReactNode
  title?: string
  className?: string
}

const LightBorderCard = ({
  children,
  title,
  className
}: LightBorderCardProps) => {
  const { t } = useTranslation()

  return (
    <div className={className}>
      {title && (
        <>
          <p className="header">{t(title)}</p>
          <Separator />
        </>
      )}
      <div className="body">{children}</div>
    </div>
  )
}

export default styled(LightBorderCard)`
  background-color: white;
  border-radius: 5px;
  border: 1px solid #cecece;
  padding: 20px;

  .header {
    font-weight: 600;
    margin: 0;
    font-size: 16px;
    max-height: 250px;
  }

  ${Separator} {
    margin-bottom: 20px;
  }

  .body {
    display: grid;
    row-gap: 15px;
    grid-auto-rows: max-content;
  }
`
