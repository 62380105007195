import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state'
import React, { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '../Button'

type ActionMenuButtonProps = {
  children?: ReactNode
  className?: string
  label?: string
}

const ActionMenuButton = ({ children, label }: ActionMenuButtonProps) => {
  const { t } = useTranslation()
  let enabledElements = 0

  React.Children.forEach(children, (child) => {
    /* eslint-disable dot-notation */
    if (child && !child['props'].disabled) {
      enabledElements++
    }
  })

  return (
    <PopupState variant="popover" popupId="demo-popup-menu">
      {(popupState) => (
        <React.Fragment>
          <Button action="secondary" type="button" {...bindTrigger(popupState)}>
            {label}
            <span className="mr-1" />
            {popupState.isOpen ? (
              <FontAwesomeIcon icon="chevron-up" />
            ) : (
              <FontAwesomeIcon icon="chevron-down" />
            )}
          </Button>
          <Menu {...bindMenu(popupState)}>
            {enabledElements > 0 ? (
              children &&
              React.Children.map(children, (actionItem) => {
                return (
                  <MenuItem onClick={popupState.close}>{actionItem}</MenuItem>
                )
              })
            ) : (
              <MenuItem>{t('No action available')}</MenuItem>
            )}
          </Menu>
        </React.Fragment>
      )}
    </PopupState>
  )
}

export default ActionMenuButton
