import React, { useContext, useState, useEffect } from 'react'
import Page from '../components/Page'
import styled, { ThemeContext } from 'styled-components'
import { useTranslation } from 'react-i18next'
import Button from '../components/Button'
import { VideoStreamingContext } from '../states/VideoStreamingState'
import { useHistory } from 'react-router-dom'

type CameraPermissionProps = {
  className?: string
}

const CameraPermission = ({ className }: CameraPermissionProps) => {
  const { t } = useTranslation()
  const history = useHistory()
  const theme = useContext(ThemeContext)
  const { setPermissionGranted, granted, hasPermissionError } = useContext(
    VideoStreamingContext
  )
  const [isRequestingPermission, setIsRequestingPermission] = useState(false)
  const [videoPreviewElement, setVideoPreviewElement] = useState(undefined)

  const handleCancelClick = () => {
    setPermissionGranted(false)
    history.replace('/applications')
  }

  const handleConfirmClick = () => {
    setIsRequestingPermission(true)
    setPermissionGranted(true)
  }

  const getCameraPreview = () => {
    if (hasPermissionError || !granted || !videoPreviewElement) {
      return
    }

    navigator.mediaDevices.getUserMedia({ video: true }).then((stream) => {
      videoPreviewElement.srcObject = stream
    })
  }

  if (hasPermissionError || granted === false) {
    history.replace('/applications')
  }

  useEffect(() => {
    getCameraPreview()
  }, [granted, hasPermissionError, videoPreviewElement])

  if (hasPermissionError === false && granted === true) {
    return (
      <Page customWidth={1366} className={className}>
        <div className="centered preview-container">
          <div className="video-preview">
            <video
              style={{ width: '100%' }}
              autoPlay
              ref={(elem) => setVideoPreviewElement(elem)}
            ></video>
          </div>
          <div className="info-container">
            <p className="title">{t('All set!')}</p>
            <p className="description">
              {t(
                'Before going forward, check your camera. Please make sure it is align in order to show your entire face frontally.'
              )}
            </p>
            <p className="description">
              {t('If you find any issues, contact the technical support.')}
            </p>
            <p className="description">
              {t(
                'Click on the button below to go to the exams selection area.'
              )}
            </p>
            <div className="buttons-container">
              <Button
                className="roundish-button allow-button"
                onClick={() => history.replace('/applications')}
              >
                {t('Exams')}
              </Button>
            </div>
          </div>
        </div>
      </Page>
    )
  }

  return (
    <Page customWidth={1366} className={className}>
      <div className="centered">
        <img src={theme.permissionsImg} />
        <p className="title">{t('Permission to use camera and microphone')}</p>
        <p className="description">
          {t(
            'To proceed on this exam(s), you will need to grant us permission to access your camera and microphone. If you refuse, you will not me able to proceed.'
          )}
        </p>
        <div className="buttons-container">
          <Button
            className="roundish-button cancel-button"
            isLoading={isRequestingPermission}
            onClick={handleCancelClick}
          >
            {t('Cancel')}
          </Button>
          <Button
            className="roundish-button allow-button"
            isLoading={isRequestingPermission}
            onClick={handleConfirmClick}
          >
            {t('Allow')}
          </Button>
        </div>
      </div>
    </Page>
  )
}

export default styled(CameraPermission)`
  display: flex;
  justify-content: center;
  text-align: center;

  .centered {
    width: 400px;
  }

  .preview-container {
    display: grid;
    width: 100%;
    grid-template-columns: 50% 50%;
  }

  .info-container {
    padding-left: 10px;
    padding-right: 10px;
  }

  .title {
    color: #23607a;
    font: Semibold 35px/49px Open Sans;
    font-size: 35px;
    margin-top: 0;
  }

  .description {
    font-size: 18px;
  }

  .roundish-button {
    margin: 10px;
    box-shadow: none;
    border: 0.6px solid #95989a;
    font: Bold 16px/30px Open Sans;
    width: 130px;
    height: 35px;
    padding: 0;
  }

  .cancel-button {
    background-color: white;
  }

  .allow-button {
    background-color: #1a3a60;
    color: white;
  }

  @media screen and (max-width: 800px) {
    .preview-container {
      grid-template-columns: none;
    }
  }
`
