import React from 'react'
import { DashboardPreFilter } from 'types'
import Card from './Card/Card'
import CardTitle from './ExamDashboardComponents/CardTitle'
import moment from 'moment'
import ApplicationStatus from './ApplicationStatus'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faIdCard, faUserGraduate } from '@fortawesome/pro-regular-svg-icons'
import CardSubtitle from './ExamDashboardComponents/CardSubtitle'
import { useHistory } from 'react-router-dom'
import classNames from 'classnames'

type DashboardPreFilterCardProps = {
  className?: string
  dashboardPreFilter: DashboardPreFilter
}

const DashBoardPreFilterCard = ({
  className,
  dashboardPreFilter
}: DashboardPreFilterCardProps) => {
  const { t } = useTranslation()
  const history = useHistory()

  const getStatus = () => {
    const examStartMoment = moment(dashboardPreFilter.startTime)
    const examFinishMoment = moment(dashboardPreFilter.endTime)
    const momentNow = moment(new Date())

    if (momentNow.isAfter(examFinishMoment)) {
      return 'FINISHED'
    } else if (
      examStartMoment.isBefore(momentNow) &&
      examFinishMoment.isAfter(momentNow)
    ) {
      return 'AVAILABLE'
    } else if (examStartMoment.isAfter(momentNow)) {
      return 'UNAVAILABLE'
    } else {
      return 'UNKNOWN'
    }
  }

  // const getReadableDate = (date: any) => {
  //   return moment(date).format('DD/MM/YYYY HH:mm')
  // }

  const handleClick = () => {
    if (!dashboardPreFilter.totalApplications) {
      return
    }
    history.push(`/exams/dashboard/list?collection=${dashboardPreFilter.id}`)
  }

  return (
    <Card
      onClick={handleClick}
      className={classNames(className, {
        disabled: dashboardPreFilter.totalApplications === 0
      })}
    >
      <CardTitle>
        <div className="card-title">{dashboardPreFilter.name}</div>
        <div>
          <ApplicationStatus status={getStatus()}></ApplicationStatus>
        </div>
      </CardTitle>
      {/* <CardSubtitle>
        <FontAwesomeIcon icon={faUserGraduate}></FontAwesomeIcon>{' '}
        {dashboardPreFilter.totalApplications || 0} {t('participants')}
      </CardSubtitle> */}
      <div className="card-footer">
        {/* <span>
          <p>{t('From')}:</p> <FontAwesomeIcon icon={faCalendar}></FontAwesomeIcon>{getReadableDate(dashboardPreFilter.startTime)}
        </span>
        <span>
          <p>{t('to')}</p> <FontAwesomeIcon icon={faCalendar}></FontAwesomeIcon>{getReadableDate(dashboardPreFilter.endTime)}
        </span> */}
        <span>
          <p>{t('Total exams')}:</p>{' '}
          <FontAwesomeIcon icon={faIdCard}></FontAwesomeIcon>
          {dashboardPreFilter.totalExams}
        </span>
      </div>
    </Card>
  )
}

export default styled(DashBoardPreFilterCard)`
  ${CardTitle} {
    display: flex;
    justify-content: space-between;
    font-size: 20px;
    color: #236179;
  }

  .card-title {
    text-decoration: underline;
  }

  ${CardSubtitle} {
    font-weight: bold;
    font-size: 12px;
  }

  cursor: pointer;
  &.disabled {
    opacity: 80%;
    cursor: not-allowed;
  }

  .card-footer {
    display: grid;
    grid-template-columns: 25% 25% 40%;
    font-weight: bold;
    font-size: 14px;
  }

  .card-footer > span {
    display: flex;
    align-items: center;
    column-gap: 15px;
  }

  .card-footer > span > p {
    color: #236179;
  }
`
