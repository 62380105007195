import { VIDEO_RECORDING_ENABLED } from 'consts'
import { useCallback, useContext, useEffect, useState } from 'react'
import { ApplicationSocketContext } from './ApplicationSocketState'

const FrameCaptureState = ({ children }) => {
  const [video, setVideo] = useState(undefined)
  const { socket } = useContext(ApplicationSocketContext)

  const captureFrame = () => {
    if (!video) {
      return
    }
    const canvas = document.createElement('canvas')
    canvas.width = video.videoWidth
    canvas.height = video.videoHeight
    canvas.getContext('2d').drawImage(video, 0, 0)
    // const data = canvas.toDataURL('image/png')
    // sendCameraFrame(data)
  }

  const createVideoHolder = (stream) => {
    const videoElement = document.createElement('video')
    videoElement.autoplay = true
    videoElement.srcObject = stream
    videoElement.width = 320
    videoElement.height = 180
    setVideo(videoElement)
  }

  const getCameraStreamInput = useCallback(() => {
    if (!socket) {
      return
    }
    navigator.mediaDevices
      .getUserMedia({
        audio: false,
        video: {
          width: 320,
          height: 180
        }
      })
      .then(createVideoHolder)
      .catch((e) => {
        console.error(e)
      })
  }, [socket])

  useEffect(() => {
    if (!VIDEO_RECORDING_ENABLED) {
      return
    }
    getCameraStreamInput()
  }, [getCameraStreamInput])

  useEffect(() => {
    if (!VIDEO_RECORDING_ENABLED || !video) {
      return
    }
    const interval = setInterval(captureFrame, 1000 / 1)

    return () => {
      clearInterval(interval)
    }
  }, [video])

  return children()
}

export default FrameCaptureState
