import { Formik, FormikValues } from 'formik'
import React, { useContext, useState, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Skeleton from 'react-loading-skeleton'
import styled from 'styled-components'
import Button from '../components/Button'
import ActionMenuButton from '../components/Buttons/ActionMenuButton'
import CardList from '../components/CardList'
import CardListToolbar from '../components/CardListToolbar'
import ExamDashboardApplicationCard from '../components/ExamDashboardApplicationCard'
import SetBulkTimeWindowModal from '../components/ExamDashboardComponents/SetBulkTimeWindowModal'
import HierarchyFilterBox from '../components/HierarchyFilterBox'
import PaginationFooter from '../components/PaginationFooter'
import ResultsFound from '../components/ResultsFound'
import { AuthContext } from '../states/AuthState'
import ExamDashboardState from '../states/ExamDashboardState'
import { ModalContext } from '../states/ModalState'
import { IExamDashboardState, ICollection } from '../types'
import devices from '../utils/devices'
import UserInput from 'components/Inputs/UserInput'
import useQuery from 'hooks/useQuery'
import { RollbarErrorTracking } from 'utils/rollbar'
import axios from 'axios'
import { API_HOST } from 'consts'
import moment from 'moment'

type ExamDashboardProps = {
  className?: string
}

const ExamDashboard = ({ className }: ExamDashboardProps) => {
  const { user, hasGroup } = useContext(AuthContext)
  const { t } = useTranslation()
  const { showModal } = useContext(ModalContext)
  const [collection, setCollection] = useState<ICollection>(undefined)
  const query = useQuery()
  const collectionId = +(query.get('collection') || '')

  const formInitialValues: FormikValues = {
    collection: undefined
  }

  const fetchCollection = useCallback(async () => {
    try {
      const response = await axios.get(
        `${API_HOST}/v1/collections/${collectionId}`
      )
      const collection = response.data
      setCollection(collection)
    } catch (e) {
      RollbarErrorTracking.LogError(e)
    }
  }, [collectionId])

  useEffect(() => {
    fetchCollection()
  }, [fetchCollection])

  const collectionIsFinished = () => {
    if (!collection) {
      return false
    }

    const now = moment(new Date())
    const collectionEnd = moment(collection.endTime)

    return collectionEnd.isSameOrBefore(now)
  }

  const shouldShowButton = () => {
    return hasGroup('ADMINISTRADOR') || hasGroup('COORDINATOR')
  }

  return (
    <ExamDashboardState>
      {({
        applications,
        exam,
        fetchingApplications,
        onFilter,
        totalApplications,
        handlePageChange,
        onPageSizeChange,
        numPages,
        pageSize,
        getVerboseFilters,
        setBulkTimeWindow
      }: IExamDashboardState) => (
        <div className={className}>
          <div className="parent-wrapper">
            <div className="filter-wrapper">
              <Formik initialValues={formInitialValues} onSubmit={onFilter}>
                {() => (
                  <HierarchyFilterBox
                    distinct={false}
                    providerCodename={user?.provider?.codename}
                  >
                    <UserInput basic />
                  </HierarchyFilterBox>
                )}
              </Formik>
            </div>
            <div className="card-list-wrapper">
              <CardList>
                {fetchingApplications && (
                  <Skeleton height={150} count={pageSize} />
                )}
                <CardListToolbar>
                  {totalApplications !== undefined && (
                    <ResultsFound resultsFound={totalApplications} />
                  )}
                  {!collectionIsFinished() && (
                    <ActionMenuButton label={t('Actions')}>
                      {!collectionIsFinished() && shouldShowButton() && (
                        <Button
                          action="unstyled"
                          onClick={() =>
                            showModal(() => (
                              <SetBulkTimeWindowModal
                                filters={getVerboseFilters()}
                                totalApplications={totalApplications}
                                onSubmit={setBulkTimeWindow}
                              />
                            ))
                          }
                        >
                          {`${t(
                            'Set exam dates'
                          )} (${totalApplications?.toLocaleString()})`}
                        </Button>
                      )}
                    </ActionMenuButton>
                  )}
                </CardListToolbar>
                {!fetchingApplications &&
                  applications.map((application) => (
                    <ExamDashboardApplicationCard
                      key={application.id}
                      application={application}
                      exam={exam}
                    />
                  ))}
              </CardList>
              <PaginationFooter
                pageSize={pageSize}
                pageCount={numPages}
                onPageChange={handlePageChange}
                onPageSizeChange={onPageSizeChange}
                disablePageSizeChange
              >
                <p>
                  Mostrando {applications.length} de {totalApplications}{' '}
                  registros.
                </p>
              </PaginationFooter>
            </div>
          </div>
        </div>
      )}
    </ExamDashboardState>
  )
}

export default styled(ExamDashboard)`
  .parent-wrapper {
    display: flex;
    column-gap: 20px;
  }

  @media (max-width: ${devices.mobileL}) {
    .parent-wrapper {
      flex-direction: column;
      row-gap: 20px;
      column-gap: 0;
    }
  }

  .filter-wrapper {
    max-width: 400px;

    @media (min-width: ${devices.tablet}) {
      min-width: 400px;
      column-gap: 20px;
    }
  }

  .card-list-wrapper {
    padding-left: 1rem;
    flex-grow: 6;
  }
`
