import styled from 'styled-components'
import React, { ReactNode } from 'react'

type FlexProps = {
  className?: string
  alignItems?: AlignSetting
  children: ReactNode
}

const Flex = ({ className, children }: FlexProps) => (
  <div className={className}>{children}</div>
)

export default styled(Flex)`
  display: flex;
  align-items: ${(props) => props.alignItems || 'center'};
`
