export const API_HOST =
  process.env.REACT_APP_API_HOST || 'http://localhost:8000'
export const SOCKET_HOST =
  process.env.REACT_APP_SOCKET_HOST || 'https://exams-websocket.educat.net.br'
export const GA_TRACKING_CODE = process.env.REACT_APP_GA_TRACKING_CODE
export const DEFAULT_LOGO = process.env.REACT_APP_DEFAULT_LOGO || undefined
export const VIDEO_RECORDING_ENABLED =
  process.env.REACT_APP_VIDEO_RECORDING_ENABLED || false
export const AGORA_API_KEY = process.env.REACT_APP_AGORA_API_KEY
export const FIREBASE_CONFIG = process.env.REACT_APP_FIREBASE_CONFIG
export const COORDINATOR_ALLOWED_IN_DASHBOARD =
  process.env.COORDINATOR_ALLOWED_IN_DASHBOARD || true
export const DASHBOARD_API_HOST =
  process.env.REACT_APP_DASHBOARD_API_HOST || API_HOST
export const CLOUD_RECORDING_LAMBDA_URL =
  process.env.CLOUD_RECORDING_LAMBDA_URL || undefined
export const EMAIL_API_HOST =
  process.env.REACT_APP_EMAIL_API_HOST || 'http://localhost:3001'
export const EMAIL_PARTNER_TOKEN =
  process.env.EMAIL_PARTNER_TOKEN || 'd898abef-8db9-4fbd-a185-06a5493c210f'
