import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

const resources = {
  pt_BR: {
    translation: {
      Exams: 'Provas',
      previous: 'anterior',
      next: 'próximo',
      next_fs: 'próxima',
      Available: 'Disponível',
      'remaining character_p': 'caracteres restantes',
      'remaining character': 'caractere restante',
      'Duration unavailable': 'Duração indisponível',
      to: 'a',
      'This exam has already been submitted.': 'Esta prova já foi enviada.',
      'Your exam has already been started.': 'Sua prova já foi iniciada.',
      'After starting your exam, you will have':
        'Após o início da prova, você terá',
      'to submit it_fs': 'para enviá-la',
      'submit answers': 'enviar respostas',
      'Review your answers before submitting.':
        'Revise suas respostas antes de enviar.',
      'Time unavailable': 'Horário indisponível',
      'Question {{position}} from {{total}}':
        'Questão {{position}} de {{total}}',
      'An error ocurred while loading this question.':
        'Ocorreu um erro ao carregar esta questão.',
      'Please verify your Internet connection and try again.':
        'Por favor verifique sua conexão à Internet e tente novamente.',
      'An error ocurred while authenticating.':
        'Ocorreu um erro ao autenticar.',
      Authenticating: 'Autenticando',
      Instructions: 'Instruções',
      'No instructions available': 'Instruções indisponíveis',
      resume: 'continuar',
      start: 'iniciar',
      'Write your answer': 'Escreva sua resposta',
      'Free response': 'Discursiva',
      'Turned in': 'Entregue',
      Continue: 'Continuar',
      Start: 'Iniciar',
      'Available soon': 'Disponível em breve',
      Unavailable: 'Indisponível',
      Unknown: 'Status desconhecido',
      Stopped: 'Interrompida',
      'Exam status is unknown. Please wait.':
        'O status da prova é desconhecido. Por favor aguarde.',
      'This exam has been stopped. Please wait.':
        'A prova foi interrompida. Por favor aguarde.',
      'An error occurred. Please try again.':
        'Um erro ocorreu. Por favor tente novamente.',
      'go back': 'voltar',
      'Search for candidate...': 'Buscar por candidato...',
      Dashboard: 'Dashboard',
      'Start time': 'Início',
      'End time': 'Término',
      Duration: 'Duração',
      login: 'entrar',
      'Remaining time': 'Tempo restante',
      'Question remaining time': 'Tempo restante para esta questão',
      'You have': 'Você possui',
      'left to submit it.': 'restantes para entregá-la.',
      'You started your exam at {{time}}.':
        'Você iniciou sua prova em {{time}}.',
      Time: 'Tempo',
      Finished: 'Finalizada',
      Started: 'Iniciada',
      Cancel: 'Cancelar',
      'Finish exam': 'Concluir prova',
      'Are you sure you want to finish this exam?':
        'Você tem certeza que deseja concluir essa prova?',
      'You have {{unansweredItems}} questions without an answer.':
        'Você tem {{unansweredItems}} itens sem resposta.',
      'review answers': 'revisar respostas',
      exit: 'sair',
      'Do you wish to start a new session?': 'Deseja iniciar uma nova sessão?',
      'You will be disconnected from your other session.':
        'Você será desconectado da sua outra sessão.',
      continue: 'continuar',
      cancel: 'cancelar',
      'Are you sure you want to leave the testing site?':
        'Você realmente deseja sair da plataforma de provas?',
      YES: 'SIM',
      NO: 'NÃO',
      Yes: 'Sim',
      No: 'Não',
      'The exam has timed out': 'A prova atingiu seu tempo limite',
      'Your responses will be saved and you will be redirected to the list of exams.':
        'Suas respostas serão salvas e você será redirecionado para a lista de provas.',
      'An error occurred': 'Ocorreu um erro',
      'Please check your internet connection and try again.':
        'Favor verificar sua conexão com a internet e tentar novamente.',
      'Try again': 'Tentar novamente',
      'Oops!': 'Ops!',
      'An unexpected error occured.': 'Ocorreu um erro inesperado.',
      'RETURN TO ORIGINAL PAGE': 'VOLTAR À PÁGINA INICIAL',
      'Your browser is incompatible with our system':
        'Seu navegador é incompatível com o nosso sistema',
      'Download the latest version of Google Chrome, Safari, Firefox or Opera.':
        'Faça o download da última versão do Google Chrome, Safari, Firefox ou Opera.',
      Download: 'Download do',
      en_us: 'pt_br',
      'https://www.apple.com/safari/': 'https://www.apple.com/br/safari/',
      'en-US': 'pt-BR',
      'https://www.opera.com/': 'https://www.opera.com/pt-br',
      'Could not connect to the server, try again later.':
        'Não foi possível conectar ao servidor, tente novamente mais tarde.',
      'There are no exams available at the moment.':
        'Não existem provas disponíveis no momento.',
      'Individual Report Card': 'Boletim Individual',
      'Individual Report Cards': 'Boletins Individuais',
      'Overall Comparative': 'Comparativo Geral',
      'Your overall result': 'Seu conhecimento geral',
      'Your class result': 'Conhecimento geral da turma',
      'Regarding your class you are': 'Em relação a sua turma você está',
      'You are above average': 'Você está acima da média',
      'You are below average': 'Você está abaixo da média',
      'Comparative Chart': 'Gráfico Comparativo',
      'Chart legend': 'Legendas do Gráfico',
      You: 'Você',
      'Your class': 'Sua turma',
      'Lost internet connection. Please re-establish your connection to proceed.':
        'Não há conexão com a internet. Restabeleça sua conexão para prosseguir.',
      'Internet connection successfully reestablished':
        'Conexão com a internet reestabelecida com sucesso',
      Grade: 'Nota',
      Grades: 'Notas',
      Exam: 'Prova',
      'Your score': 'Seus acertos',
      'Your class overall score': 'Média de acertos da sua turma',
      'See results': 'Ver resultados',
      '% of correct answers': '% de acertos',
      'Scores quantity': 'Nº de acertos',
      Collection: 'Desafio',
      'Filter by a collection to download all report cards from it.':
        'Filtre por um desafio para fazer o download dos boletins.',
      APPLY: 'APLICAR',
      'Total Items': 'Total de Itens',
      User: 'Usuário',
      'You are average': 'Você está na média',
      'All students result': 'Conhecimento geral de todos os respondentes',
      'Regarding all students': 'Em relação a todos os respondentes',
      'All students overall score': 'Média de acerto de todos os respondentes',
      'All students': 'Total de respondentes',
      'Students overall score': 'Média dos respondentes',
      Correct: 'Acertos',
      Wrong: 'Erros',
      Identifier: 'Identificador',
      Filters: 'Filtros',
      Date: 'Data',
      'Exam value': 'Valor da prova',
      'Exam dashboard': 'Painel da prova',
      'Exam time': 'Horário de prova',
      'Are you sure you want to delete this exam?':
        'Tem certeza de que deseja reiniciar esta prova?',
      'Are you sure you want to reset the time for this exam?':
        'Tem certeza de que deseja reiniciar o cronômetro desta prova?',
      'Exam was reset successfully': 'A prova foi redefinida com sucesso!',
      'Exam time was reset successfully':
        'O cronômetro da prova foi reiniciado com sucesso!',
      'No changes have been made.': 'Nenhuma alteração foi feita.',
      'Check to clear answers':
        'Ao marcar esta caixa, todas as respostas do respondente também serão apagadas.',
      'Reset exam': 'Reiniciar prova',
      'Reset time': 'Reiniciar cronômetro',
      Name: 'Nome',
      'Add Student': 'Adicionar Aluno',
      'Add New Student': 'Adicionar Novo Aluno',
      'No results found.': 'Nenhum resultado encontrado.',
      'results found': 'resultados',
      'result found': 'resultado',
      'Didn’t find the student? Click here to register a new student.':
        'Não encontrou o aluno? Clique aqui para cadastrá-lo.',
      'Real time stats': 'Estatísticas em tempo real',
      'Access logs': 'Log de acessos',
      'Reset logs': 'Log de resets',
      Results: 'Resultados',
      'See reports': 'Consulta de boletins',
      'Grades reports': 'Relatório de notas',
      'TCT Analysis': 'Análise TCT',
      'TRI Analysis': 'Análise TRI',
      Resource: 'Recurso',
      'New resource': 'Novo recurso',
      'Check resource': 'Acompanhar recurso',
      Configurations: 'Configurações',
      'Exam scheduling': 'Agendamento de provas',
      'Exam grouping': 'Agrupamento de provas',
      Users: 'Usuários',
      'Filter by "{{name}}" first': 'Filtre por "{{name}}" primeiro',
      'No results found': 'Nenhum resultado encontrado',
      'Action finished successfully': 'Ação finalizada com sucesso',
      'There was an error while executing the action':
        'Houve um erro ao realizar a ação',
      'No time window available': 'Nenhum horário de prova disponível',
      'This field is required.': 'Este campo é obrigatório.',
      'Invalid format.': 'Formato inválido.',
      'User created and added to the collection!':
        'Usuário criado e adicionado ao desafio!',
      'User added to the collection!': 'Usuário adicionado ao desafio!',
      'Set exam date': 'Definir data da prova',
      Hour: 'Hora',
      minutes: 'minutos',
      'Exam date set successfully': 'Data definida com sucesso',
      'Could not set date. Check the data and try again':
        'Näo foi possível definir a data da prova. Verifique os dados digitados e tente novamente',
      'Question with the highest success rate':
        'Questão mais acertada da prova',
      'Question with the highest failure rate': 'Questão mais errada da prova',
      'See statistics': 'Ver estatísticas',
      'See videos': 'Ver vídeos',
      'See list': 'Ver lista',
      'Average time per question': 'Tempo médio por questão',
      'Success rate per question': '% de acertos por item',
      'of students got it right': 'dos alunos acertaram',
      Students: 'Alunos',
      'Alert candidate': 'Alertar candidato',
      'Pause exam': 'Pausar prova',
      'Resume exam': 'Retomar prova',
      'Your exam was paused': 'Sua prova foi pausada',
      Reason: 'Motivo',
      Disconnected: 'Desconectado',
      'Paused by applicator':
        'Pausado pelo aplicador. Por favor aguarde novas orientações.',
      Paused: 'Pausado',
      'Send alert': 'Enviar alerta',
      'Type your message here': 'Digite sua mensagem aqui',
      'Cannot send an empty alert':
        'Não é possível enviar um alerta sem mensagem',
      'Message sent successfully': 'Mensagem enviada com sucesso',
      Attention: 'Atenção',
      'Contact applicator': 'Comunicar com aplicador',
      'Send message': 'Enviar mensagem',
      'Time in exam': 'Tempo de prova',
      'Total answered': 'Total respondido',
      'Current item': 'Item atual',
      'Bathroom break': 'Pausa para ir ao banheiro',
      'What do you want to do?': 'O que deseja fazer?',
      'Waiting for applicator confirmation...':
        'Aguardando confirmação do aplicador',
      'Your request was sent. You will be notified when there is a response.':
        'Seu pedido foi enviado. Você será notificado quando houver uma resposta.',
      'The applicator rejected your break request.':
        'O aplicador rejeitou seu pedido de pausa.',
      'The applicator approved your break request.':
        'O aplicador aceitou seu pedido de pausa.',
      'Your exam will be paused when you finish the current question.':
        'Sua prova será pausada quando você terminar sua questão atual.',
      'You have 2 minutes to come back': 'Você tem 2 minutos para voltar',
      'I am back!': 'Voltei!',
      'Additional info': 'Informações adicionais',
      seconds: 'segundos',
      'Loading...': 'Carregando...',
      'Select a room': 'Selecione uma sala',
      'Please answer the question to go to the next item':
        'Por favor responda a questão para ir para a próxima',
      Imports: 'Importações',
      Import: 'Importar',
      'Import exam': 'Importar prova',
      'Import students': 'Importar alunos',
      'Receive a .json file with the content of the exam. This file can be generated by exporting a exam in the item creation system.':
        'Recebe um arquivo .json com o conteúdo da prova. Esse arquivo pode ser gerado exportando uma prova no sistema de criação de itens.',
      'JSON format is invalid.': 'JSON está em um formato inválido.',
      'File should be .json.': 'Arquivo deve ser no formato .json.',
      'Exam successfully imported!': 'Prova importada com sucesso!',
      Username: 'Usuário',
      Password: 'Senha',
      Actions: 'Ações',
      'This action will affect {{ num }} applications.':
        'Esta ação afetará {{ num }} aplicações.',
      'All records that meet the following filters will be considered':
        'Todos os registros que satisfazem os filtros abaixo serão considerados.',
      'Set exam dates': 'Definir data da prova',
      'No filters selected': 'Nenhum filtro selecionado',
      'Use the list filters to choose the applications you want to set a new date.':
        'Utilize os filtros da lista para escolher as aplicações que deseja definir uma nova data.',
      'must be selected.': 'devem ser selecionados.',
      of: 'de',
      'Select new time window for ': 'Selecione uma nova data de prova para ',
      Confirm: 'Confirmar',
      Minutes: 'Minutos',
      'Invalid format': 'Formato inválido',
      'Keep existing dates': 'Manter datas existentes',
      'Remove existing dates': 'Remover datas existentes',
      'Candidate in the bathroom': 'Candidato no banheiro',
      'Do you want to approve the bathroom request?':
        'Aceitar a solicitação do aluno de ir ao banheiro?',
      'Permission to use camera and microphone':
        'Permissões para uso de câmera e microfone',
      'You have been assigned one or more exams which require camera and microphone access as a mandatory permission. If you refuse to grant, you will not be able to proceed.':
        'Foi designado a você um ou mais exames que são monitorados remotamente por câmera e microfone de forma mandatória. Se você recusar em conceder acesso, não poderá realizá-los.',
      'You refused to grant the necessary permissions, therefore you cannot proceed on the exams.':
        'Você recusou as permissões necessárias, então não poderá prosseguir nas provas.',
      'To proceed on this exam(s), you will need to grant us permission to access your camera and microphone. If you refuse, you will not me able to proceed.':
        'Para prosseguir nesta(s) prova(s), você precisará nos dar permissão para acessar sua câmera e microfone. Se você recusar o acesso, não será possível continuar.',
      Allow: 'Permitir',
      'All set!': 'Tudo certo!',
      'Answer key': 'Gabarito',
      'Before going forward, check your camera. Please make sure it is align in order to show your entire face frontally.':
        'Antes de avançar, cheque sua câmera. Por favor alinhe-a de maneira a mostrar seu rosto completamente de maneira frontal.',
      'If you find any issues, contact the technical support.':
        'Se você encontrar algum problema, contate o suporte técnico.',
      'Click on the button below to go to the exams selection area.':
        'Clique no botão abaixo para ir para a área de seleção de exames.',
      'Considered right': 'Considerado acerto',
      'Exam started': 'Prova iniciada',
      'Exam finished': 'Prova finalizada',
      'Last item acessed': 'Último item acessado',
      'This candidate is not currently on the exam':
        'Este candidato não está realizando a prova',
      'Looking up': 'Olhando para cima',
      'Looking down': 'Olhando para baixo',
      'Looking right': 'Olhando para a direita',
      'Looking left': 'Olhando para a esquerda',
      'Candidate not found': 'Candidato não encontrado',
      'Multiple people found': 'Múltiplas pessoas encontradas',
      'Already exist exam to this date.':
        'Já existe prova marcada para esta data.',
      'See feedback': 'Visualizar gabarito',
      'Here you can see what you answered, but you will not be able to change any answer.':
        'Aqui você pode ver o que você respondeu, mas não será possível alterar nenhuma resposta.',
      'Camera permission pending': 'Permissão de acesso à câmera pendente',
      'Send answer': 'Responder',
      'You are constantly looking away from the exam. This may lead to interruption of your application.':
        'Você está desviando o olhar da prova constantemente, isto pode levar a interrupção de sua aplicação.',
      'You got up without authorization, this may lead to interruption of your application.':
        'Você levantou sem autorização, isto pode levar a interrupção de sua aplicação.',
      'It is prohibited to use any electronics during the exam aside from the one used for the exam.':
        'É proibido utilizar equipamentos eletrônicos durante a prova, exceto pelo equipamento usado para realizar a mesma.',
      'Your camera is not properly positioned in order to give full sight of your face, please reposition it.':
        'Sua câmera não está corretamente posicionada de modo a dar visão total do seu rosto, favor reposicioná-la.',
      'CANCELLED ITEM': 'ITEM ANULADO',
      'Correct answer': 'Resposta correta',
      'Wrong answer': 'Resposta incorreta',
      'Not answered': 'Não respondido',
      'The applicator has requested for your window to be reloaded. This is a mandatory action and will not affect your answers. Click ok to continue.':
        'O aplicador requisitou que sua janela seja recarregada. Esta é uma ação mandatória e não vai afetar suas respostas. Clique ok para continuar.',
      'Send reload request': 'Enviar pedido de recarregamento',
      Absent: 'Ausentes',
      'In progress': 'Em andamento',
      'Total students': 'Total',
      'Not enough data to calculate': 'Não há dados suficientes para calcular',
      Question: 'Questão',
      'Records history': 'Registro de ocorrências',
      Room: 'Sala',
      'History of saved records': 'Histórico de registros efetuados',
      'New record': 'Novo registro',
      'Record description': 'Descrição da ocorrência',
      From: 'De',
      'Total exams': 'Total de provas',
      participante: 'participants',
      participants: 'participantes',
      'Search...': 'Pesquisar...',
      'Students successfully imported!': 'Alunos importados com sucesso!',
      Importing: 'Importando',
      'Exemple file': 'Arquivo de exemplo',
      'time reseted': 'tempo reiniciado',
      Keep: 'Manter',
      Remove: 'Remover',
      'Existing dates': 'Datas existentes',
      'Apply to all collection applications':
        'Aplicar a todas as provas do desafio',
      'Choose if previous dates should be kept or removed':
        'Escolha se as datas anteriores devem ser mantidas ou removidas',
      'Provided dates will be applied to all candidate applications from collection':
        'As datas fornecidas serão replicadas a todas as provas do desafio deste candidato',
      'This exam had its time restarted':
        'Essa prova teve o seu tempo reiniciado',
      'Send emails': 'Enviar emails',
      'Select the recipients': 'Selecione os destinatários',
      'Select a template to continue filling out the form.':
        'Selecione um template para continuar preenchendo o formulário',
      'Question warning':
        'Você não poderá alterar as suas respostas. Tenha certeza antes de avançar.',
      'Dont show again': 'Não exibir novamente',
      'Your answer': 'Sua resposta'
    }
  }
}

i18n.use(initReactI18next).init({
  resources,
  lng: 'pt_BR',
  keySeparator: false,
  interpolation: {
    escapeValue: false
  }
})

export default i18n
