import Dexie from 'dexie'
import { IAnswer, IApplication, IItem } from './types'

class ExamsDatabase extends Dexie {
  public answers: Dexie.Table<IAnswer, number>
  public items: Dexie.Table<IItem, number>
  public applications: Dexie.Table<IApplication, number>

  public constructor() {
    super('exams')
    this.version(1).stores({
      answers: 'id,position,application.id,_changed,[application.id+_changed]',
      applications: 'id,user.id',
      items: 'id'
    })
    this.answers = this.table('answers')
    this.applications = this.table('applications')
    this.items = this.table('items')
  }
}

const db = new ExamsDatabase()

export default db
