import React, { useContext, useState, useRef } from 'react'
import styled from 'styled-components'
import VideoIcon from './VideoIcon'
import CandidateBreakComponent from './CandidateBreakComponent'
import CandidateMessagePopover from './CandidateMessagePopover'
import { DashboardVideosSocketContext } from '../../states/DashboardVideosSocketState'
import { IExamDashboardApplication } from '../../types'
import { faQuestion, faRestroom } from '@fortawesome/free-solid-svg-icons'

export type VideoIconQueueProps = {
  className?: string
  application: IExamDashboardApplication
  question: string
  breakStatus: string
}

const VideoIconQueue = ({
  className,
  application,
  question,
  breakStatus
}: VideoIconQueueProps) => {
  const { setMessageAsRead, sendBreakResponse } = useContext(
    DashboardVideosSocketContext
  )

  const [popoverOpen, setPopoverOpen] = useState(false)
  const [breakPopoverOpen, setBreakPopoverOpen] = useState(false)
  const iconPopoverRef = useRef<any>(null)
  const breakIconPopoverRef = useRef<any>(null)

  const getMargin = () => {
    let itemCount = -1
    if (question) {
      itemCount++
    }

    if (breakStatus) {
      itemCount++
    }

    return { marginLeft: itemCount * 35 + 'px' }
  }

  return (
    <div className={'icon-row ' + className}>
      <div className="icons">
        {question && iconPopoverRef !== null && (
          <>
            <div ref={iconPopoverRef} onClick={() => setPopoverOpen(true)}>
              <VideoIcon type="question" pulse icon={faQuestion} />
            </div>
            <CandidateMessagePopover
              onClose={() => {
                setPopoverOpen(false)
                setMessageAsRead(application.user.id)
              }}
              anchorElement={iconPopoverRef.current}
              open={popoverOpen}
              question={{ message: question, when: new Date().toISOString() }}
            />
          </>
        )}
        {breakStatus &&
          breakStatus === 'PENDING' &&
          breakIconPopoverRef !== null && (
            <>
              <div
                ref={breakIconPopoverRef}
                style={getMargin()}
                onClick={() => setBreakPopoverOpen(true)}
              >
                <VideoIcon type="restroom" pulse icon={faRestroom} />
              </div>
              <CandidateBreakComponent
                onClose={(result) => {
                  setBreakPopoverOpen(false)
                  sendBreakResponse(application.user.id, result)
                }}
                anchorElement={breakIconPopoverRef.current}
                open={breakPopoverOpen}
              />
            </>
          )}
        {breakStatus && breakStatus === 'CONFIRMED' && (
          <VideoIcon type="restroomAccepted" icon={faRestroom} />
        )}
      </div>
    </div>
  )
}

export default styled(VideoIconQueue)`
  position: absolute;
  right: 2.5rem;
  margin-right: 5px;
  z-index: 999;
  top: 5px;

  .icons {
    display: flex;
    flex-direction: row-reverse;
  }
`
