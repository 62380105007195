import { Link } from 'react-router-dom'
import styled from 'styled-components'

export default styled(Link)`
  padding: 5px;
  background-color: ${(props) => props.theme.colors.grayLight};
  color: ${(props) => props.theme.clientColors.primary};
  font-size: 15pt;
  border-radius: 50%;
  text-decoration: none;
  align-items: center;
  justify-content: center;
  display: flex;
  width: 2rem;
  height: 2rem;

  :visited,
  :not(:visited) {
    color: ${(props) => props.theme.clientColors.primary};
  }

  :hover {
    background-color: ${(props) => props.theme.clientColors.primary};
    color: ${(props) => props.theme.colors.grayLight};
  }
`
