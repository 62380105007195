import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import SearchBar from '../SearchBar'

type SearchBarWrapperProps = {
  className?: string
  searchTerm?: string
  onSearchChange: (event: ChangeEvent<HTMLInputElement>) => void
  placeholder?: string
}

const SearchBarWrapper = ({
  className,
  searchTerm,
  onSearchChange,
  placeholder
}: SearchBarWrapperProps) => {
  const { t } = useTranslation()

  return (
    <div className={className}>
      <SearchBar
        placeholder={
          placeholder ? t(placeholder) : t('Search for candidate...')
        }
        value={searchTerm}
        onChange={onSearchChange}
      />
      <div className="icon-wrapper">
        <FontAwesomeIcon className="search-bar-icon" icon={faSearch} />
      </div>
    </div>
  )
}

export default styled(SearchBarWrapper)`
  display: flex;
  margin-bottom: 20px;
  align-items: center;

  ${SearchBar} {
    flex-grow: 9;
  }

  .icon-wrapper {
    font-size: 20px;
    flex-grow: 1;
    text-align: center;
    padding-top: 7px;
    height: 33px;
    color: white;
    background-color: #c6c6c6;
    cursor: pointer;
  }
`
