import classnames from 'classnames'
import React, { TextareaHTMLAttributes } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

interface TextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  value: string
  maxLength: number | undefined
  className?: string
}

const TextArea = ({ value, maxLength, className, ...rest }: TextAreaProps) => {
  const { t } = useTranslation()

  let remainingChars: number | undefined
  if (maxLength !== undefined) {
    remainingChars = maxLength - value.length
  }

  return (
    <div className={className}>
      <textarea value={value} maxLength={maxLength || null} {...rest} />
      {maxLength !== undefined && (
        <p
          className={classnames({ 'max-length-reached': remainingChars === 0 })}
        >
          {remainingChars}{' '}
          {t('remaining character', {
            context: remainingChars !== 1 ? 'p' : undefined
          })}
        </p>
      )}
    </div>
  )
}

export default styled(TextArea)`
  textarea {
    width: 100%;
    max-width: 100%;
    font-size: 16px;
    padding: 5px;
    line-height: 1.5;
    background-color: white;
    border: 2px solid #f5f5f5;
    border-radius: 5px;

    &:disabled {
      cursor: no-drop;
      background-color: ${(props) => props.theme.colors.grayMedium};
    }
  }

  p {
    text-align: right;

    &.max-length-reached {
      font-weight: 600;
    }
  }
`
