import React, { ReactNode } from 'react'
import styled from 'styled-components'

type StripeProps = {
  children?: ReactNode
  className?: string
  title?: string
}

const Stripe = ({ children, title, className }: StripeProps) => {
  return (
    <div className={className}>
      <div>
        <strong>{title}</strong>
        {children}
      </div>
    </div>
  )
}

export default styled(Stripe)`
  background-color: ${(props) =>
    props.theme.clientColors.accent || props.theme.clientColors.secondary};

  strong {
    font-weight: bold;
    font-size: 14px;
    color: white;
  }

  div {
    padding: 10px;
    max-width: 1366px;
    margin: auto;
  }
`
