import React, { useContext } from 'react'
import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import Footer from '../components/Footer'
import Header from '../components/Header'
import OfflineMessage from '../components/OfflineMessage'
import { AuthContext } from '../states/AuthState'
import { isAuthenticated } from '../utils/auth'

interface PrivateRouteProps extends RouteProps {
  className?: string
  withFooter?: boolean
  withHeader?: boolean
  withOfflineWarn?: boolean
  allowedGroups?: string[]
}

const PrivateRoute = ({
  className,
  withFooter,
  withHeader,
  withOfflineWarn,
  allowedGroups = [],
  ...props
}: PrivateRouteProps) => {
  const location = useLocation()
  const { hasGroup } = useContext(AuthContext)

  const hasPermission = () => {
    if (allowedGroups.length === 0) {
      return true
    }

    return allowedGroups.some((g) => hasGroup(g))
  }

  if (isAuthenticated()) {
    if (hasPermission()) {
      return (
        <div className={className}>
          {withHeader && <Header />}
          {withOfflineWarn && <OfflineMessage />}
          <Route {...props} />
          {withFooter && <Footer />}
        </div>
      )
    }
    return <Redirect to="/not-found" />
  }

  const next = location.pathname
  const redirect = `/login${next ? `?next=${next}` : ''}`
  return <Redirect to={redirect} />
}

PrivateRoute.defaultProps = {
  withFooter: true,
  withHeader: true,
  withOfflineWarn: true
} as Partial<PrivateRouteProps>

export default styled(PrivateRoute)`
  position: relative;
  min-height: 100vh;
  padding-bottom: 3rem;
`
