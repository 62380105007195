export type HumanReadableHour = {
  hours: any
  minutes: any
}

export const formatDateTimeString = (dateTimeString: string) => {
  const parts = dateTimeString
    .split(/([0-9]{4})-([0-9]{2})-([0-9]{2})T([0-9]{2}):([0-9]{2}):([0-9]{2})/)
    .slice(1, -1)

  const [year, month, day, hours, minutes] = parts

  return `${day}/${month}/${year} ${hours}:${minutes}`
}

export const dateTimeToDate = (dateTimeString: string) => {
  const parts = dateTimeString
    .split(/([0-9]{4})-([0-9]{2})-([0-9]{2})T([0-9]{2}):([0-9]{2}):([0-9]{2})/)
    .slice(1, -1)

  const [year, month, day] = parts

  return `${day}/${month}/${year}`
}

export const dateTimeToHour = (dateTimeString: string) => {
  const parts = dateTimeString
    .split(/([0-9]{4})-([0-9]{2})-([0-9]{2})T([0-9]{2}):([0-9]{2}):([0-9]{2})/)
    .slice(1, -1)

  const [, , , hours, minutes] = parts

  return `${hours}:${minutes}`
}

export const differenceBetweenHours = (start?: string, end?: string) => {
  // we can't and shouldn't
  // calculate without start date.
  if (!start) {
    return null
  }

  if (!end) {
    end = new Date().toISOString()
  }
  const startDate = new Date(start).valueOf()
  const endDate = new Date(end).valueOf()
  const diff = (endDate - startDate) / 1000 / 60 / 60
  return diff.toFixed(2)
}

export const diffBetweenHoursReadable = (
  start?: string,
  end?: string
): HumanReadableHour | null => {
  if (!start) {
    return null
  }
  if (!end) {
    end = new Date().toISOString()
  }
  const timeStart = new Date(start).getTime()
  const timeEnd = new Date(end).getTime()
  const hourDiff = timeEnd - timeStart // in ms
  const minDiff = hourDiff / 60 / 1000 // in minutes
  const hDiff = hourDiff / 3600 / 1000 // in hours
  const finalHours = Math.floor(hDiff)
  const finalMinutes = minDiff - 60 * finalHours
  return {
    hours: finalHours.toFixed(0),
    minutes: finalMinutes.toFixed(0)
  }
}

// Formats seconds to {days}d{hours}h{minutes}min
export const formatDuration = (seconds: number) => {
  let delta = seconds

  const days = Math.floor(delta / 86400)
  delta -= days * 86400

  const hours = Math.floor(delta / 3600) % 24
  delta -= hours * 3600

  const minutes = Math.floor(delta / 60) % 60
  delta -= minutes * 60

  let value = ''
  if (days) {
    value += `${days}d`
  }
  if (hours) {
    value += `${hours}h`
  }
  if (minutes) {
    value += `${minutes}min`
  }
  if (!value) {
    value += '0min'
  }

  return value
}
