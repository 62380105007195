import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { ThemeContext } from 'styled-components'
import Tag from './Tag'
import { getStatusLabel } from 'data/utils/applications'

type ApplicationStatusProps = {
  status: string
  reseted?: boolean
  className?: string
}

const ApplicationStatus = ({
  status,
  reseted,
  className
}: ApplicationStatusProps) => {
  const { t } = useTranslation()
  const theme = useContext(ThemeContext)
  const color =
    theme.applicationStatus[status] || theme.applicationStatus.UNKNOWN

  return (
    <Tag color={color} className={className}>
      {t(getStatusLabel(status))}
      {reseted ? ` (${t('time reseted')})` : ''}
    </Tag>
  )
}

export default styled(ApplicationStatus)``
