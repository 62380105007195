const useBrowserCompatible = () => {
  return true

  // switch (browserName) {
  //   case 'Chrome':
  //     return (isAndroid && +browserVersion >= 80) | +browserVersion >= 79

  //   case 'Safari':
  //     return browserVersion === 'TP' | +browserVersion >= 12.4

  //   case 'Firefox':
  //     return +browserVersion >= 72

  //   case 'Opera':
  //     return true

  //   case 'Samsung Internet':
  //     return +browserVersion >= 10.1

  //   case 'UC Browser':
  //     return +browserVersion >= 12.12

  //   default:
  //     return false
  // }
}

export default useBrowserCompatible
