import React from 'react'
import styled from 'styled-components'
import { IUser } from '../../types'
import Card from '../Card/Card'
import CheckBoxInput from '../Inputs/CheckBoxInput'
import CardSubtitle from '../ExamDashboardComponents/CardSubtitle'
import CardTitle from '../ExamDashboardComponents/CardTitle'

type RecipientCardProps = {
  className?: string
  user: IUser
}

const RecipientCard = ({ className, user }: RecipientCardProps) => {
  return (
    <Card className={className}>
      <div>
        <CardTitle>
          <CheckBoxInput name={`users.${user.id}`} value={user} />
          {user.name}
        </CardTitle>
        <CardSubtitle>{user.email}</CardSubtitle>
      </div>
    </Card>
  )
}

export default styled(RecipientCard)``
