export const getStringListFromObject = (object) => {
  /*
    Receives an object and return the object data in an string list

    ex:
      receives { 'name': 'lars', 'age': 31 }
      returns ['name: lars', 'age: 31']
  */
  const result = [] as string[]
  if (object && typeof object === 'object') {
    const keys = Object.keys(object)
    keys.forEach((key) => {
      const value = object[key]
      if (typeof value === 'object') {
        const list = getStringListFromObject(value)
        list.forEach((item) => {
          result.push(`${key}: ${item}`)
        })
      } else {
        result.push(`${key}: ${object[key]}`)
      }
    })
  }
  return result
}
