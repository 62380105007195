import Axios from 'axios'
import { FormikValues } from 'formik'
import { get, isEmpty } from 'lodash'
import React, {
  ChangeEvent,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useReducer
} from 'react'
import { API_HOST, DASHBOARD_API_HOST } from '../consts'
import useList, { initialState as useListInitialState } from '../hooks/useList'
import {
  childrenIsFunction,
  ExamDashboardStateAction,
  IExamDashboardState,
  IHierarchy
} from '../types'
import { ConfigContext } from './ConfigState'
import useQuery from 'hooks/useQuery'
import ExamDashboardContext, {
  initialState
} from 'contexts/ExamDashboardContext'

type ExamDashboardStateProps = {
  children: Function | ReactNode
}

const reducer = (
  state: IExamDashboardState,
  action: ExamDashboardStateAction
): IExamDashboardState => {
  switch (action.type) {
    case 'FETCH_EXAM':
      return { ...state, fetchingExam: true, fetchExamError: '' }
    case 'FETCH_EXAM_SUCCESS':
      return {
        ...state,
        fetchingExam: false,
        fetchExamError: '',
        exam: action.payload
      }
    case 'FETCH_EXAM_ERROR':
      return { ...state, fetchingExam: false, fetchExamError: action.payload }
    case 'FETCH_APPLICATIONS':
      return {
        ...state,
        fetchingApplications: true,
        fetchApplicationsError: ''
      }
    case 'FETCH_APPLICATIONS_SUCCESS':
      return {
        ...state,
        fetchingApplications: false,
        fetchApplicationsError: '',
        applications: action.payload
      }
    case 'FETCH_APPLICATIONS_ERROR':
      return {
        ...state,
        fetchingApplications: false,
        fetchApplicationsError: action.payload
      }
    case 'ON_SEARCH_CHANGE':
      return { ...state, search: action.payload }
    case 'ON_FILTER_CHANGE':
      return { ...state, ...action.payload }
    default:
      return state
  }
}

const ExamDashboardState = ({ children }: ExamDashboardStateProps) => {
  const query = useQuery()
  const collectionId = +(query.get('collection') || '')

  const { syncApplicationsExamDashboardInterval } = useContext(ConfigContext)
  const [state, dispatch] = useReducer(reducer, initialState)
  const {
    isLoading: fetchingApplications,
    results: applications,
    fetchList: fetchApplications,
    count: totalApplications,
    handleFilter,
    handleSearchChange,
    handlePageChange,
    handlePageSizeChange,
    pageSize,
    numPages
  } = useList({
    api: `${DASHBOARD_API_HOST}/v1/applications/dashboard?collection=${collectionId}`,
    defaultPageSize: 10
  })

  const onPageSizeChange = (event: any) => {
    handlePageSizeChange(event.target.value)
  }

  const syncApplications = useCallback(() => {
    fetchApplications(false)
  }, [fetchApplications])

  // Syncs answers with the server in a set interval
  useEffect(() => {
    const timer = setInterval(
      syncApplications,
      syncApplicationsExamDashboardInterval
    )
    return () => clearTimeout(timer)
  }, [syncApplications, syncApplicationsExamDashboardInterval])

  const onSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch({ type: 'ON_SEARCH_CHANGE', payload: event.target.value })
    handleSearchChange(event.target.value)
  }

  const mapHierarchy = (hierarchy: {
    [key: string]: IHierarchy | undefined
  }) => {
    // Maps hierarchy filter to how the api expects
    if (!hierarchy) {
      return undefined
    }

    const value = Object.entries(hierarchy)
      .filter((entry) => entry[1])
      .reduce(
        (acc, entry) => ({
          ...acc,
          [entry[0]]: { value: get(entry[1], 'value') }
        }),
        {}
      )

    // If no value selected, returns undefined
    // so it is not added in query string by axios
    return isEmpty(value) ? undefined : value
  }

  const mapFilters = (values: FormikValues) => {
    // Maps filters from how they are saved in form to how the api expects
    const { collection, user, ...hierarchy } = values
    return {
      collection: get(collection, 'id'),
      user: get(user, 'id'),
      hierarchy: mapHierarchy(hierarchy)
    }
  }

  const onFilter = (values: FormikValues) => {
    const filterParams = mapFilters(values)
    const payload = {
      filterParams,
      appliedFilters: values
    }
    dispatch({ type: 'ON_FILTER_CHANGE', payload })
    handleFilter(filterParams)
  }

  const setBulkTimeWindow = (values: FormikValues) => {
    return Axios.post(
      `${API_HOST}/v1/applications/bulk_set_application_dates`,
      {
        search: state.search,
        ...values,
        ...mapFilters(state.appliedFilters),
        collection: collectionId
      }
    ).then(() => {
      fetchApplications(false)
    })
  }

  const getVerboseFilters = () => {
    const verboseFilters = Object.entries(state.appliedFilters)
      .filter((entry) => entry[1])
      .map((entry) => entry[1].name)
    if (state.search) {
      verboseFilters.push(state.search)
    }
    return verboseFilters
  }

  const contextValue = {
    ...state,
    fetchingApplications,
    applications,
    totalApplications,
    onSearchChange,
    onFilter,
    handlePageChange,
    onPageSizeChange,
    numPages,
    pageSize,
    setBulkTimeWindow,
    getVerboseFilters,
    fetchApplications
  }

  return (
    <ExamDashboardContext.Provider value={contextValue}>
      {childrenIsFunction(children) ? children(contextValue) : children}
    </ExamDashboardContext.Provider>
  )
}

export default ExamDashboardState
