import styled from 'styled-components'

const Separator = styled.hr`
  height: 1px;
  border: 0;
  width: 100%;
  border-top: 1px solid #ccc;
`

export default Separator
