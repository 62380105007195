import { Formik, FormikValues } from 'formik'
import { get, isEmpty } from 'lodash'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import Skeleton from 'react-loading-skeleton'
import styled from 'styled-components'
import CardList from '../components/CardList'
import HierarchyFilterBox from '../components/HierarchyFilterBox'
import CollectionInput from '../components/Inputs/CollectionInput'
import UserInput from '../components/Inputs/UserInput'
import ListPage from '../components/ListPage'
import PaginationFooter from '../components/PaginationFooter'
import ReportCard from '../components/ReportCard/ReportCard'
import Stripe from '../components/Stripe'
import { AuthContext } from '../states/AuthState'
import IndividualReportCardListState from '../states/IndividualReportCardListState'
import { FormValues, IHierarchy } from 'types'

type IndividualReportCardListProps = {
  className?: string
}

const IndividualReportCardList = ({
  className
}: IndividualReportCardListProps) => {
  const { t } = useTranslation()
  const { user } = useContext(AuthContext)
  const formInitialValues: FormValues = {
    collection: undefined
  }

  const mapHierarchy = (hierarchy: {
    [key: string]: IHierarchy | undefined
  }) => {
    // Maps hierarchy filter to how the api expects
    if (!hierarchy) {
      return undefined
    }

    const value = Object.entries(hierarchy)
      .filter((entry) => entry[1])
      .reduce(
        (acc, entry) => ({
          ...acc,
          [entry[0]]: { name: get(entry[1], 'name') }
        }),
        {}
      )

    // If no value selected, returns undefined
    // so it is not added in query string by axios
    return isEmpty(value) ? undefined : value
  }

  const mapFilters = (values: FormValues) => {
    // Maps filters from how they are saved in form to how the api expects
    const { collection, user, ...hierarchy } = values
    return {
      collection: get(collection, 'id'),
      user: get(user, 'id'),
      hierarchy: mapHierarchy(hierarchy)
    }
  }

  const getUserInputParams = (values: FormikValues) => {
    return { hierarchy: mapFilters(values).hierarchy }
  }

  return (
    <IndividualReportCardListState>
      {({
        results,
        numPages,
        handleFilter,
        handlePageChange,
        handlePageSizeChange,
        count,
        isLoading,
        pageSize
      }) => (
        <div className={className}>
          <Stripe title={t('Individual Report Cards')} />
          <ListPage>
            <Formik
              initialValues={formInitialValues}
              onSubmit={(values) => handleFilter(mapFilters(values))}
            >
              {(formik) => (
                <>
                  <HierarchyFilterBox
                    providerCodename={user?.provider?.codename}
                  >
                    <CollectionInput />
                    <UserInput params={getUserInputParams(formik.values)} />
                  </HierarchyFilterBox>
                  <div>
                    <CardList>
                      {isLoading ? (
                        <Skeleton height={110} count={pageSize} />
                      ) : (
                        results.map((r) => (
                          <ReportCard key={r.id} individualReportCard={r} />
                        ))
                      )}
                    </CardList>
                    <PaginationFooter
                      pageSize={pageSize}
                      pageCount={numPages}
                      onPageChange={handlePageChange}
                      onPageSizeChange={handlePageSizeChange}
                    >
                      <p>
                        Mostrando {results.length} de {count} registros.
                      </p>
                    </PaginationFooter>
                  </div>
                </>
              )}
            </Formik>
          </ListPage>
        </div>
      )}
    </IndividualReportCardListState>
  )
}

export default styled(IndividualReportCardList)``
