import React, { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { Formik, Form } from 'formik'
import Page from '../Page'
import Button from '../Button'
import Error from '../Error'
import ImportPageInfo from './ImportPageInfo'

type ImportBaseProps = {
  onSubmit: (params?: any) => void
  onFileChange: (params?: any) => void
  instructions: ReactNode
  title: string | ''
  errors: string[]
  successMessage?: string | ''
  loading?: boolean
}

const ImportBase = ({
  onSubmit,
  onFileChange,
  instructions,
  title,
  errors,
  successMessage,
  loading
}: ImportBaseProps) => {
  const { t } = useTranslation()

  return (
    <Page customWidth={1366}>
      <ImportPageInfo title={title} instructions={instructions} />
      <Formik initialValues={{}} onSubmit={onSubmit}>
        {(formik) => (
          <Form onSubmit={formik.handleSubmit}>
            <input type="file" onChange={onFileChange} />
            <Button disabled={loading} type="submit">
              {loading ? t('Importing') : t('Import')}
            </Button>
          </Form>
        )}
      </Formik>
      {errors.map((err) => (
        <p key={err}>
          <Error>{err}</Error>
        </p>
      ))}
      {successMessage && <span>{successMessage}</span>}
    </Page>
  )
}

export default ImportBase
