import React from 'react'
import { useTranslation } from 'react-i18next'
import Skeleton from 'react-loading-skeleton'
import ApplicationCard from '../components/ApplicationCard'
import Card from '../components/Card/Card'
import CardList from '../components/CardList'
import ErrorWithMessage from '../components/ErrorWithMessage'
import Html from '../components/Html'
import Page from '../components/Page'
import ApplicationListState from '../states/ApplicationListState'
import CollectionState from '../states/CollectionState'
import { IApplication, IApplicationListState, ICollectionState } from '../types'
import ApplicationSocketState from 'states/ApplicationSocketState'

const ApplicationList = () => {
  const { t } = useTranslation()

  return (
    <div>
      <ApplicationSocketState>
        {() => (
          <ApplicationListState>
            {({ results, isLoading, collectionId }: IApplicationListState) => (
              <Page>
                {collectionId !== undefined && collectionId > 0 && (
                  <CollectionState collectionId={collectionId}>
                    {({ collection, fetchingCollection }: ICollectionState) => (
                      <>
                        <h2>
                          {fetchingCollection ? <Skeleton /> : collection?.name}
                        </h2>
                        {fetchingCollection ? (
                          <Skeleton height="200px" />
                        ) : (
                          <Card>
                            <Html>{collection?.instructions}</Html>
                          </Card>
                        )}
                      </>
                    )}
                  </CollectionState>
                )}
                <h3>{t('Exams')}</h3>
                <CardList>
                  {isLoading && <Skeleton height={110} count={5} />}
                  {!isLoading && results.length === 0 && (
                    <ErrorWithMessage
                      message={t('There are no exams available at the moment.')}
                    />
                  )}
                  {results.map((d: IApplication) => (
                    <ApplicationCard key={d.id} application={d} />
                  ))}
                </CardList>
              </Page>
            )}
          </ApplicationListState>
        )}
      </ApplicationSocketState>
    </div>
  )
}

export default ApplicationList
