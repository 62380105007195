import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState
} from 'react'
import socketIOClient from 'socket.io-client'
import { SOCKET_HOST, VIDEO_RECORDING_ENABLED } from '../consts'
import {
  DashboardVideosSocketStateAction,
  ExamStatus,
  IDashboardVideosSocketState,
  SOCKET_EVENTS
} from '../types'
import { AuthContext } from './AuthState'

const reducer = (
  state: IDashboardVideosSocketState,
  action: DashboardVideosSocketStateAction
): IDashboardVideosSocketState => {
  switch (action.type) {
    case 'SOCKET_CONNECT_SUCCESS':
      return {
        ...state,
        socket: action.payload.socket,
        candidatesConnectionStatus: action.payload.candidatesInfo
      }
    case 'ROOM_PULSE_RECEIVED':
      return {
        ...state,
        candidatesConnectionStatus: action.payload.onlineStatus,
        candidatesFinishedStatus: action.payload.finishedStatus,
        candidatesMessageStatus: action.payload.messageStatus,
        candidatesPausedStatus: action.payload.pausedStatus,
        candidatesBreakInfo: action.payload.breakStatus,
        candidatesPermissionStatus: action.payload.permissionStatus
      }
    case 'CANDIDATE_CONNECT':
      const connectedCandidates = state.candidatesConnectionStatus
      connectedCandidates[action.payload.examsUserId] = true
      return { ...state, candidatesConnectionStatus: connectedCandidates }
    case 'CANDIDATE_DISCONNECT':
      const candidatesToDisconnect = state.candidatesConnectionStatus
      candidatesToDisconnect[action.payload.examsUserId] = false
      return { ...state, candidatesConnectionStatus: candidatesToDisconnect }
    case 'CANDIDATE_MESSAGE':
      const candidateMessageStatus = state.candidatesMessageStatus
      candidateMessageStatus[action.payload.examsUserId] =
        action.payload.message
      return { ...state, candidatesMessageStatus: candidateMessageStatus }
    case 'CANDIDATE_BREAK':
      const currentBreakStatus = state.candidatesBreakInfo
      currentBreakStatus[action.payload.examsUserId] = action.payload.status
      return { ...state, candidatesBreakInfo: currentBreakStatus }
    case 'CANDIDATE_ACTIVITY':
      const currentActivityStatus = state.candidatesActivityStatus
      currentActivityStatus[action.payload.examsUserId] = action.payload
      return { ...state, candidatesActivityStatus: currentActivityStatus }
    case 'CANDIDATE_FINISHED_EXAM':
      const currentFinishedstatus = state.candidatesFinishedStatus
      currentFinishedstatus[action.payload.examsUserId] = true
      return { ...state, candidatesFinishedStatus: currentFinishedstatus }
  }
}

const initialState: IDashboardVideosSocketState = {
  candidatesActivityStatus: {},
  candidatesBreakInfo: {},
  candidatesConnectionStatus: {},
  candidatesMessageStatus: {},
  candidatesFinishedStatus: {},
  candidatesPausedStatus: {},
  candidatesPermissionStatus: {},
  pauseCandidateExam: () => undefined,
  resumeCandidateExam: () => undefined,
  sendAlert: () => undefined,
  sendBreakResponse: () => undefined,
  setActivityAsRead: () => undefined,
  setMessageAsRead: () => undefined,
  getExamStatus: () => undefined,
  sendReloadRequest: () => undefined,
  joinRoomAsApplicator: () => undefined,
  socket: null
}

export const DashboardVideosSocketContext = createContext<
  IDashboardVideosSocketState
>(initialState)

type DashboardVideosSocketProps = {
  children: any
}

const DashboardVideosSocketState = ({
  children
}: DashboardVideosSocketProps) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const { user, hasGroup } = useContext(AuthContext)
  const [socket, setSocket] = useState<SocketIOClient.Socket>(null)

  const { candidatesActivityStatus } = state

  const handleSocketSuccess = useCallback(() => {
    if (!socket || !user) {
      return
    }

    const isAdmin = hasGroup('ADMINISTRADOR')
    socket.emit(
      SOCKET_EVENTS.AUTH,
      { examsUserId: user.id, role: isAdmin ? 'ADMINISTRATOR' : 'APPLICATOR' },
      (authResult) => {
        console.log(authResult)
        socket.emit(
          SOCKET_EVENTS.JOIN_ROOM,
          { roomId: user.provider.codename },
          (joinResult) => {
            dispatch({
              type: 'SOCKET_CONNECT_SUCCESS',
              payload: {
                socket,
                candidatesInfo: joinResult.data.candidatesInfo
              }
            })
            // attachSocketEvents()
          }
        )
      }
    )
  }, [socket, user])

  const attachSocketEvents = useCallback(() => {
    if (!socket) {
      return
    }

    socket.on(SOCKET_EVENTS.SERVER_PULSE, (payload) => {
      console.log(payload)
      dispatch({ type: 'ROOM_PULSE_RECEIVED', payload })
    })

    socket.on(SOCKET_EVENTS.CANDIDATE_CONNECT, (payload) => {
      console.log(payload)
      dispatch({ type: 'CANDIDATE_CONNECT', payload: payload })
    })

    socket.on(SOCKET_EVENTS.CANDIDATE_DISCONNECT, (payload) => {
      console.log(payload)
      dispatch({ type: 'CANDIDATE_DISCONNECT', payload: payload })
    })

    socket.on(SOCKET_EVENTS.CANDIDATE_MESSAGE, (payload) => {
      dispatch({
        type: 'CANDIDATE_MESSAGE',
        payload: {
          message: payload.content,
          examsUserId: payload.ownerExamsId
        }
      })
    })

    socket.on(SOCKET_EVENTS.CANDIDATE_BREAK, (payload) => {
      dispatch({
        type: 'CANDIDATE_BREAK',
        payload: { examsUserId: payload.examsUserId, status: 'PENDING' }
      })
    })

    socket.on(SOCKET_EVENTS.CANDIDATE_BACK_FROM_BREAK, (payload) => {
      dispatch({
        type: 'CANDIDATE_BREAK',
        payload: { examsUserId: payload.examsUserId, status: 'FINISHED' }
      })
    })

    socket.on(SOCKET_EVENTS.CANDIDATE_ACTIVITY, (payload) => {
      const currentReason = candidatesActivityStatus[payload.examsUserId] || {
        reason: ''
      }
      if (payload.reason === currentReason.reason) {
        return
      }
      dispatch({
        type: 'CANDIDATE_ACTIVITY',
        payload: {
          examsUserId: payload.examsUserId,
          reason: payload.reason,
          when: payload.when
        }
      })
    })

    socket.on(SOCKET_EVENTS.EXAM_STATUS_UPDATED, (payload) => {
      if (payload && payload.finished) {
        dispatch({
          type: 'CANDIDATE_FINISHED_EXAM',
          payload: { examsUserId: payload.examsUserId }
        })
      }
    })

    socket.on('reconnect', () => {
      handleSocketSuccess()
    })
  }, [socket])

  const pauseCandidateExam = (candidateId: number, reason?: string) => {
    socket && socket.emit(SOCKET_EVENTS.PAUSE, { candidateId, reason })
  }

  const resumeCandidateExam = (candidateId: number) => {
    socket && socket.emit(SOCKET_EVENTS.RESUME, { candidateId })
  }

  const sendAlert = (candidateId: number, content: string) => {
    socket && socket.emit(SOCKET_EVENTS.ALERT, { candidateId, content })
  }

  const setMessageAsRead = (candidateId: number) => {
    dispatch({
      type: 'CANDIDATE_MESSAGE',
      payload: { message: undefined, examsUserId: candidateId }
    })
    socket && socket.emit('message-read', { candidateId })
  }

  const setActivityAsRead = (candidateId: number) => {
    dispatch({
      type: 'CANDIDATE_ACTIVITY',
      payload: { reason: undefined, examsUserId: candidateId }
    })
  }

  const sendReloadRequest = (candidateId: number) => {
    socket.emit(SOCKET_EVENTS.RELOAD_REQUEST, { candidateId })
  }

  const getExamStatus = (candidateId: number): Promise<ExamStatus> => {
    return new Promise((resolve, reject) => {
      if (!socket) {
        reject(new Error())
      }
      socket.emit(
        SOCKET_EVENTS.GET_EXAM_STATUS,
        candidateId,
        (result: ExamStatus) => {
          resolve(result)
        }
      )
    })
  }

  const sendBreakResponse = (candidateId: number, result: boolean) => {
    dispatch({
      type: 'CANDIDATE_BREAK',
      payload: {
        examsUserId: candidateId,
        status: result ? 'CONFIRMED' : 'REJECTED'
      }
    })
    socket &&
      socket.emit(SOCKET_EVENTS.CANDIDATE_BREAK_RESPONSE, {
        examsUserId: candidateId,
        result
      })
  }

  const connectSocket = useCallback(() => {
    setSocket(socketIOClient(SOCKET_HOST))
  }, [])

  const joinRoomAsApplicator = (roomId: number) => {
    socket && socket.emit(SOCKET_EVENTS.SET_ROOM, { roomId })
  }

  useEffect(() => {
    if (VIDEO_RECORDING_ENABLED) {
      connectSocket()
    }
  }, [connectSocket])

  useEffect(() => {
    handleSocketSuccess()
  }, [handleSocketSuccess])

  useEffect(() => {
    attachSocketEvents()
  }, [attachSocketEvents])

  const contextValue: IDashboardVideosSocketState = {
    ...state,
    socket,
    pauseCandidateExam,
    resumeCandidateExam,
    sendAlert,
    sendBreakResponse,
    setActivityAsRead,
    setMessageAsRead,
    getExamStatus,
    sendReloadRequest,
    joinRoomAsApplicator
  }

  return (
    <DashboardVideosSocketContext.Provider value={contextValue}>
      {children}
    </DashboardVideosSocketContext.Provider>
  )
}

export default DashboardVideosSocketState
