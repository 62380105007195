import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import Skeleton from 'react-loading-skeleton'
import styled from 'styled-components'
import AnswerCountdown from '../components/ApplicationToolbarComponents/AnswerCountdown'
import ApplicationToolbar from '../components/ApplicationToolbarComponents/ApplicationToolbar'
import ChangeAnswerButtons from '../components/ChangeAnswerButtons'
import Item from '../components/Item/Item'
import ItemNavigation from '../components/ItemNavigation/ItemNavigation'
import RemainingTime from '../components/RemainingTime'
import Tag from '../components/Tag'
import AnswerState from '../states/AnswerState'
import { ApplicationContext } from '../states/ApplicationState'
import { ConfigContext } from '../states/ConfigState'
import { IAnswerState } from '../types'

type AnswerProps = {
  className?: string
}

const Answer = ({ className }: AnswerProps) => {
  const { secondsToTimeout, answers, remainingTimeInitialDate } = useContext(
    ApplicationContext
  )
  const { allowHideRemainingTimeAnswerPage } = useContext(ConfigContext)
  const { t } = useTranslation()

  return (
    <AnswerState>
      {({ answer, item, handleQuestionExpired }: IAnswerState) => {
        return (
          <div className={className}>
            <ItemNavigation />
            <div className="card answer-card">
              <div className="main-content">
                <ApplicationToolbar />
                {item && item.answerTimeLimit && (
                  <AnswerCountdown
                    itemId={item.id}
                    answer={answer}
                    secondsToCountdown={item.answerTimeLimit}
                    onFinish={handleQuestionExpired}
                  />
                )}
                <div className="card-header">
                  <p>
                    {answers.length === 0 ? (
                      <Skeleton height={18} width={125} />
                    ) : (
                      <span>
                        {t('Question {{position}} from {{total}}', {
                          position: answer?.position,
                          total: answers?.length
                        })}
                      </span>
                    )}
                    {item?.category === 'FREE_RESPONSE' && (
                      <Tag color="rebeccapurple">{t('Free response')}</Tag>
                    )}
                  </p>
                  <p>
                    {t('Remaining time')}:{' '}
                    <RemainingTime
                      initial={remainingTimeInitialDate}
                      secondsToTimeout={secondsToTimeout}
                      allowHideRemainingTime={allowHideRemainingTimeAnswerPage}
                    />
                  </p>
                </div>
                <Item />
                <ChangeAnswerButtons />
              </div>
            </div>
          </div>
        )
      }}
    </AnswerState>
  )
}

export default styled(Answer)`
  .main-content {
    margin: auto;
    max-width: 1000px;
    padding-top: 1rem;
    padding-bottom: 3rem;
  }

  .answer-card {
    min-height: 100vh;
  }

  .card {
    background-color: ${(props) => props.theme.colors.grayLight};
    font-size: 11pt;

    ${ChangeAnswerButtons}:last-of-type {
      margin-top: 20px;
    }

    &-header {
      color: ${(props) => props.theme.clientColors.text};
      font-size: 12pt;
      font-weight: bold;
      display: flex;
      justify-content: space-between;
      p {
        ${Tag} {
          margin-left: 10px;
        }
      }
    }
  }

  @media (max-width: 1200px) {
    .card {
      padding-left: 15%;
      padding-right: 15%;
    }
  }

  @media (max-width: 800px) {
    .card {
      padding-left: 10%;
      padding-right: 10%;
    }
  }

  @media (max-width: 768px) {
    .card {
      flex: 0 1 calc(50% - 1em) !important;
    }
  }

  @media (max-width: 480px) {
    .card {
      flex: 0 1 calc(100% - 1em);
    }
  }
`
