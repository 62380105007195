import React from 'react'
import styled from 'styled-components'

type ExamStatusIndicatorProps = {
  className?: string
  status: string
}

const ExamStatusIndicator = ({
  className,
  status
}: ExamStatusIndicatorProps) => (
  <div className={className}>
    <div className={`indicator ${status}`}></div>
  </div>
)

export default styled(ExamStatusIndicator)`
  .indicator {
    height: 13px;
    width: 13px;
    border-radius: 50%;
  }

  .icon {
    cursor: pointer;
    font-size: 20px;
  }

  .paused {
    background-color: #b15329;
  }

  .online {
    background-color: #528652;
  }

  .offline {
    background-color: #606060;
  }
`
