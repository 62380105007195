import React, { useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import Button from 'components/Button'

type MessageToCandidateProps = {
  className?: string
  onClose: () => void
  onMessageChosen: (content: string) => void
  candidateName: string
}

const fixedMessages = [
  'You are constantly looking away from the exam. This may lead to interruption of your application.',
  'You got up without authorization, this may lead to interruption of your application.',
  'It is prohibited to use any electronics during the exam aside from the one used for the exam.',
  'Your camera is not properly positioned in order to give full sight of your face, please reposition it.'
]

const MessageToCandidate = ({
  className,
  candidateName,
  onMessageChosen: onMessageChoosen,
  onClose
}: MessageToCandidateProps) => {
  const { t } = useTranslation()

  const [message, setMessage] = useState('')

  const handleMessageType = (value: string) => {
    setMessage(value)
  }

  return (
    <div
      data-testid="send-message-to-candidate-component"
      className={className}
    >
      <div className="top-container">
        <p>{candidateName}</p>
        <p onClick={onClose} className="close-button">
          X
        </p>
      </div>
      <div className="fixed-message-container">
        {fixedMessages.map((message, i) => {
          const translatedMessage = t(message)
          return (
            <div
              key={i}
              onClick={() => setMessage(translatedMessage)}
              className="fixed-message"
            >
              {translatedMessage}
            </div>
          )
        })}
      </div>
      <div className="textarea-container">
        <textarea
          data-testid="message-text"
          rows={3}
          value={message}
          onChange={(event) => handleMessageType(event.target.value)}
        ></textarea>
      </div>
      <div className="button-container">
        <Button
          className="send-button"
          action="secondary"
          onClick={() => {
            onMessageChoosen(message)
            onClose()
          }}
        >
          {t('Send answer')}
        </Button>
      </div>
    </div>
  )
}

export default styled(MessageToCandidate)`
  background-color: white;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 10% 45% 25% 20%;

  .top-container {
    padding: 5px;
    display: grid;
    grid-template-columns: 90% 10%;
  }

  .top-container > p {
    margin: 0;
  }

  .close-button {
    color: #5a5a5a;
    font-weight: bold;
    cursor: pointer;
  }

  .fixed-message-container {
    padding: 5px;
    display: flex;
    overflow-y: scroll;
    display: grid;
    margin: 3px 0 3px 0;
  }

  .fixed-message {
    border: 1px solid #23607a;
    border-radius: 4px;
    font-size: 12px;
    padding: 5px;
    max-height: 60px;
    margin-bottom: 5px;
  }

  .fixed-message:hover {
    background-color: #eee;
    cursor: pointer;
  }

  .textarea-container {
    display: flex;
    padding: 5px;
    justify-content: center;
  }

  textarea {
    align-self: flex-end;
    width: 100%;
    resize: none;
    border: 1px solid #c9c9c9;
    font-size: 10px;
    font-family: Open Sans;
  }

  .button-container {
    text-align: center;
  }
`
