import React, { ReactNode } from 'react'
import styled from 'styled-components'

const ModalFooter = ({
  className,
  children
}: {
  className?: string
  children: ReactNode
}) => (
  <div className={className}>
    {React.Children.map(children, (child) => {
      return <div className="item">{child}</div>
    })}
  </div>
)

export default styled(ModalFooter)`
  margin-top: 1rem;
  bottom: 0px;
  display: flex;
  flex-direction: row-reverse;
  .item {
    margin-left: 10px;
  }
`
