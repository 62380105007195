import axios from 'axios'
import { useEffect, useState } from 'react'
import { API_HOST } from '../consts'
import { PreferenceName } from '../types'

function usePreference<T>(preferenceName: PreferenceName): T | undefined {
  const [preference, setPreference] = useState<T | undefined>(undefined)

  useEffect(() => {
    axios
      .get<T>(`${API_HOST}/preferences/global/${preferenceName}/`)
      .then((response) => {
        setPreference(response.data)
      })
      .catch(() => {
        setPreference(undefined)
      })
  }, [preferenceName])

  return preference
}

export default usePreference
