import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import DropdownMenuButton from '../Buttons/DropdownMenuButton'
import styled from 'styled-components'
import { toast } from 'react-toastify'
import Modal from '../Modal/Modal'
import Textarea from '../Formik/Inputs/textarea'
import { Formik } from 'formik'
import { ApplicationSocketContext } from '../../states/ApplicationSocketState'

type ContactApplicatorButtonProps = {
  className?: string
}

const ContactApplicatorButton = ({
  className
}: ContactApplicatorButtonProps) => {
  const { t } = useTranslation()
  const { sendQuestion, askForBreak } = useContext(ApplicationSocketContext)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [actionBlocked, setActionBlocked] = useState(false)

  const maxMessageLength = 200

  const sendToilet = async () => {
    askForBreak()
    toast.success(
      t('Your request was sent. You will be notified when there is a response.')
    )
  }

  const validateMessageForm = (values) => {
    const errors: { [key: string]: string } = {}
    const requiredMsg = t('This field is required.')
    const { message, ...hierarchy } = values

    if (!message) {
      errors.message = requiredMsg
    }

    Object.entries(hierarchy).forEach((entry) => {
      const [key, value] = entry
      if (!value) {
        errors[key] = requiredMsg
      }
    })
    return errors
  }

  const handleSubmit = (formik) => {
    setActionBlocked(true)
    formik.handleSubmit()
    if (!formik.values.message) return
    setIsModalOpen(false)
  }

  return (
    <div className={className}>
      <Formik
        initialValues={{ message: '' }}
        onSubmit={(values) => {
          sendQuestion(values.message)
          setActionBlocked(false)
        }}
        validate={validateMessageForm}
      >
        {(formik) => (
          <Modal
            title={t('Send message')}
            onAction={() => {
              handleSubmit(formik)
              setTimeout(() => formik.resetForm(), 100)
            }}
            actionDisabled={actionBlocked}
            isOpen={isModalOpen}
            onClose={() => {
              setIsModalOpen(false)
              formik.resetForm()
            }}
            onCancel={() => {
              setIsModalOpen(false)
              formik.resetForm()
            }}
          >
            <Textarea name="message" maxLength={maxMessageLength} />
          </Modal>
        )}
      </Formik>
      <DropdownMenuButton
        action="secondary"
        label={t('Contact applicator')}
        actions={[
          { label: t('Bathroom break'), onClick: sendToilet },
          { label: t('Send message'), onClick: () => setIsModalOpen(true) }
        ]}
      />
    </div>
  )
}

export default styled(ContactApplicatorButton)``
