import React, { createContext } from 'react'
import { useTranslation } from 'react-i18next'
import swal from 'sweetalert'
import { API_HOST } from '../consts'
import useList, { initialState as useListInitialState } from '../hooks/useList'
import { ICollection, IIndividualReportCardListState } from '../types'

type IndividualReportCardListStateProps = {
  children: Function
}

const initialState: IIndividualReportCardListState = {
  ...useListInitialState,
  downloadCollectionReportCards: () => {
    // do nothing
  }
}

export const IndividualReportCardListContext = createContext(initialState)

export const IndividualReportCardListState = ({
  children
}: IndividualReportCardListStateProps) => {
  const state = useList({
    api: `${API_HOST}/v1/individual-collections`,
    defaultPageSize: 10,
    defaultOrdering: 'user__name'
  })
  const { t } = useTranslation()

  const downloadCollectionReportCards = (collection: ICollection) => {
    if (collection) {
      window.open(
        `${API_HOST}/v1/collections/${collection.id}/report_card_pdfs`
      )
    } else {
      swal({
        text: t('Filter by a collection to download all report cards from it.')
      })
    }
  }

  const contextValue = {
    ...state,
    downloadCollectionReportCards
  }

  return (
    <IndividualReportCardListContext.Provider value={contextValue}>
      {children(contextValue)}
    </IndividualReportCardListContext.Provider>
  )
}

export default IndividualReportCardListState
