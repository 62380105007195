import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import Button, { ButtonProps } from '../Button'

export interface ReviewButtonProps extends ButtonProps {
  canNavigateOnExamItems?: boolean
}

const ReviewButton = ({
  canNavigateOnExamItems,
  ...rest
}: ReviewButtonProps) => {
  const { t } = useTranslation()
  const { applicationId } = useParams()
  const history = useHistory()

  const textToRender = canNavigateOnExamItems
    ? t('review answers')
    : t('See feedback')

  return (
    <Button
      action="secondary"
      onClick={() => history.push(`/applications/${applicationId}/review`)}
      {...rest}
    >
      {textToRender}
    </Button>
  )
}

export default ReviewButton
