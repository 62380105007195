import React, { useContext, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import Skeleton from 'react-loading-skeleton'
import { AnswerContext } from '../../states/AnswerState'
import { ConfigContext } from '../../states/ConfigState'
import AlternativeGroup from '../AlternativeGroup/AlternativeGroup'
import FreeResponse from '../FreeResponse'
import Html from '../Html'
import styled from 'styled-components'
import { syncAnswers } from '../../hooks/useAnswerSync'
import debounce from 'debounce-promise'

type ItemProps = {
  className?: string
}

const Item = ({ className }: ItemProps) => {
  const { item, fetchingItem, fetchItemError } = useContext(AnswerContext)
  const { disableItemTextHighlight } = useContext(ConfigContext)
  const { t } = useTranslation()

  const callSyncAnswers = useCallback(
    debounce(async () => {
      return await syncAnswers()
    }, 1500),
    []
  )

  const onItemClick = () => {
    callSyncAnswers()
  }

  if (fetchItemError) {
    return (
      <>
        {t('An error ocurred while loading this question.')}{' '}
        {t('Please verify your Internet connection and try again.')}
      </>
    )
  }

  if (fetchingItem || !item) {
    return <Skeleton height={1000} />
  }

  return (
    <div className={className} onClick={onItemClick}>
      <div className="question-card">
        <Html noHighlight={disableItemTextHighlight}>{item.introduction}</Html>
        <Html noHighlight={disableItemTextHighlight}>{item.question}</Html>
      </div>
      {item.category === 'FREE_RESPONSE' ? (
        <FreeResponse />
      ) : (
        <AlternativeGroup item={item} />
      )}
    </div>
  )
}

export default styled(Item)`
  .question-card {
    background-color: white;
    padding: 20px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  }

  @media (max-width: 400px) {
    .question-card {
      img {
        max-width: 216px;
      }
    }
  }

  @media (min-width: 400px) and (max-width: 500px) {
    .question-card {
      img {
        max-width: 288px;
      }
    }
  }

  @media (min-width: 600px) {
    .question-card {
      img {
        max-width: 550px;
      }
    }
  }
`
