import React from 'react'
import { IItem } from '../../types'
import Alternative from './Alternative'

type AlternativeGroupProps = {
  item: IItem
}

const AlternativeGroup = ({ item }: AlternativeGroupProps) => {
  return (
    <div>
      <div className="radio-group">
        {item.alternatives
          .sort((a, b) => a.position - b.position)
          .map((d) => (
            <Alternative {...d} key={d.id} itemId={item.id} />
          ))}
      </div>
    </div>
  )
}

export default AlternativeGroup
