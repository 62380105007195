import classnames from 'classnames'
import React, { useContext } from 'react'
import styled from 'styled-components'
import { AnswerContext } from '../../states/AnswerState'
import { ApplicationContext } from '../../states/ApplicationState'
import { ConfigContext } from '../../states/ConfigState'
import { IAlternative } from '../../types'
import Html from '../Html'

interface AlternativeProps extends IAlternative {
  className?: string
  itemId: number
}

const Alternative = ({ className, itemId, ...rest }: AlternativeProps) => {
  const { answer, updateAnswer, alreadyAnswered } = useContext(AnswerContext)
  const { disableItemTextHighlight } = useContext(ConfigContext)
  const { secondsToTimeout, application } = useContext(ApplicationContext)
  const { id, letter, content } = rest

  const shouldUpdateAnswer =
    application?.exam?.canUpdateAnswer || !alreadyAnswered
  const isSelected = answer?.item.id === itemId && answer.alternative?.id === id
  const isDisabled =
    secondsToTimeout === 0 ||
    !shouldUpdateAnswer ||
    answer?.timeoutDate !== null

  return (
    <div className={className}>
      <label
        htmlFor={letter}
        className={classnames('radio-button', {
          selected: isSelected,
          disabled: isDisabled
        })}
      >
        <input
          readOnly
          type="radio"
          id={letter}
          checked={isSelected}
          disabled={isDisabled}
          onClick={() => !isDisabled && updateAnswer && updateAnswer(rest)}
        />
        <div className="radio-mark"></div>
        <div className="radio-button-content">
          <span className="letter">{`${letter.toUpperCase()}.`}</span>
          <Html noHighlight={disableItemTextHighlight}>{content}</Html>
        </div>
      </label>
    </div>
  )
}

export default styled(Alternative)`
  .radio-button {
    display: flex;
    position: relative;
    margin-top: 20px;
    background: white;
    padding: 0px 20px;
    cursor: pointer;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);

    &.disabled {
      cursor: not-allowed;
    }

    &-content {
      display: flex;
      line-height: 1.5rem;
      vertical-align: text-bottom;
      .letter {
        font-size: 16pt;
        margin-right: 1rem;
        font-weight: 600;
        align-self: center;
      }
    }

    &.selected {
      background-color: ${(props) => props.theme.colors.grayMedium};
    }

    &:hover:not(.disabled) {
      outline: ${(props) => props.theme.colors.grayDark} solid 1px;
    }

    .radio-mark {
      display: none;
    }

    input[type='radio'] {
      opacity: 0;
      position: absolute;
    }

    input:checked ~ .radio-mark:after {
      display: flex;
    }
  }
`
