import debounce from 'debounce-promise'
import React, { useContext, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { AnswerContext } from '../states/AnswerState'
import { ApplicationContext } from '../states/ApplicationState'
import TextArea from './TextArea'

type FreeResponseProps = {
  className?: string
}

const FreeResponse = ({ className }: FreeResponseProps) => {
  const { answer, updateFreeResponse, item, alreadyAnswered } = useContext(
    AnswerContext
  )
  const [responseValue, updateResponseValue] = useState(
    answer?.freeResponse || ''
  )
  const { secondsToTimeout, application } = useContext(ApplicationContext)
  const { t } = useTranslation()

  const updateResponse = debounce((responseText: string) => {
    updateFreeResponse(responseText)
  }, 300)

  useEffect(() => {
    updateResponseValue(answer?.freeResponse || '')
  }, [answer.position])

  const handleResponseChange = (value: string) => {
    updateResponseValue(value)
    updateResponse(value)
  }

  const shouldUpdateAnswer =
    application?.exam?.canUpdateAnswer || !alreadyAnswered
  const isDisabled =
    secondsToTimeout === 0 ||
    !shouldUpdateAnswer ||
    answer?.timeoutDate !== null

  return (
    <div className={className}>
      <TextArea
        disabled={isDisabled}
        maxLength={item?.freeResponseMaxLength}
        value={responseValue}
        onChange={(event) => handleResponseChange(event.target.value)}
        rows={5}
        placeholder={`${t('Write your answer')}...`}
      />
    </div>
  )
}

export default styled(FreeResponse)`
  ${TextArea} {
    margin-top: 20px;
  }
`
