import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import CardSubtitle from './ExamDashboardComponents/CardSubtitle'
import { ApplicationContext } from '../states/ApplicationState'
import AnswerReviewList from './AnswerReviewList'
import AnswerReviewListItem from './AnswerReviewListItem'
import Button from './Button'
import Error from './Error'
import RemainingTime from './RemainingTime'

type ReviewAnswersProps = {
  className?: string
}

const ReviewAnswers = ({ className }: ReviewAnswersProps) => {
  const {
    answers,
    application,
    resumeApplication,
    finishApplication,
    finishingApplication,
    finishApplicationError,
    remainingTimeInitialDate,
    secondsToTimeout
  } = useContext(ApplicationContext)
  const { t } = useTranslation()

  const getTextToRender = () => {
    if (!application || !application.exam) {
      return
    }
    const examIsBrowsable =
      application.exam.canBrowseAcrossItems && application.exam.canUpdateAnswer
    return examIsBrowsable
      ? t('Review your answers before submitting.')
      : t(
          'Here you can see what you answered, but you will not be able to change any answer.'
        )
  }

  return (
    <div className={className}>
      <RemainingTime
        initial={remainingTimeInitialDate}
        secondsToTimeout={secondsToTimeout}
        allowHideRemainingTime={false}
      />
      <CardSubtitle>{getTextToRender()}</CardSubtitle>
      <AnswerReviewList>
        {answers.map((answer) => (
          <AnswerReviewListItem
            key={answer.id}
            answer={answer}
            application={application}
          />
        ))}
      </AnswerReviewList>
      <div className="actions">
        <Button
          isLoading={finishingApplication}
          action="primary"
          onClick={() => {
            finishApplication()
          }}
        >
          {t('submit answers')}
        </Button>
        {!finishingApplication && (
          <Button action="secondary" onClick={() => resumeApplication()}>
            {t('go back')}
          </Button>
        )}
        {finishApplicationError && <Error>{finishApplicationError}</Error>}
      </div>
    </div>
  )
}

export default styled(ReviewAnswers)`
  padding: 20px;
  text-align: center;

  ${AnswerReviewList} {
    margin-bottom: 30px;
    margin-top: 30px;
  }

  ${CardSubtitle} {
    margin-top: 1rem;
  }

  .actions {
    justify-content: center;
    display: grid;
    grid-auto-rows: 100px;
    grid-template-rows: 1fr 1fr;
    gap: 20px;
  }
`
