import React, { useContext } from 'react'
import { ModalContext } from '../../states/ModalState'
import Modal from './Modal'

const ModalRootModal = ({ ...rest }) => {
  const { hideModal } = useContext(ModalContext)

  return <Modal isOpen onClose={hideModal} {...rest} />
}

export default ModalRootModal
