import axios, { AxiosResponse } from 'axios'
import React, {
  createContext,
  ReactElement,
  useCallback,
  useEffect,
  useReducer
} from 'react'
import { API_HOST } from '../consts'
import db from '../db'
import {
  AuthStateLogin,
  AuthStateProviderLoginArgs,
  childrenIsFunction,
  IAuthState,
  IAuthStateAction
} from '../types'
import {
  clearTheme,
  clearToken,
  getClientId,
  getTheme,
  getUser,
  getUserGroups,
  saveTheme,
  saveToken,
  saveUser
} from '../utils/auth'
import { configAuthorizationHeader } from '../utils/axios'
import { auth } from 'firebase'

type AuthStateProps = {
  children: Function | ReactElement
}

const FIREBASE_INEXISTENT_ERROR = 'auth/user-not-found'

const reducer = (state: IAuthState, action: IAuthStateAction): IAuthState => {
  switch (action.type) {
    case 'LOGIN':
      return { ...state, isSubmitting: true, loginError: false }
    case 'LOGIN_ERROR':
      return {
        ...state,
        isSubmitting: false,
        loginError: true,
        detailedErrors: action.payload
      }
    case 'LOGIN_SUCCESS':
      return {
        ...state,
        isSubmitting: false,
        loginError: false,
        ...action.payload
      }
    case 'UPDATE_USER':
      return { ...state, user: action.payload }
    case 'SHOW_NEW_SESSION_PAGE':
      return { ...state, ...action.payload, isSubmitting: false }
    case 'HIDE_NEW_SESSION_PAGE':
      return { ...state, showNewSessionPage: false }
    case 'UPDATE_LOCAL_STORAGE_OBJECTS':
      return { ...state, ...action.payload }
    case 'NETWORK_ERROR':
      return {
        ...state,
        loginError: false,
        networkError: true,
        isSubmitting: false
      }
    default:
      return state
  }
}

export const initialState: IAuthState = {
  user: undefined,
  token: undefined,
  theme: undefined,
  dispatch: () => {},
  isSubmitting: false,
  loginError: false,
  networkError: false,
  detailedErrors: undefined,
  login: () => Promise.resolve(false),
  providerLogin: () => Promise.resolve(false),
  logout: () => Promise.resolve(),
  hasGroup: () => false,
  showNewSessionPage: false,
  newSessionMessage: '',
  activeSession: undefined
}

export const AuthContext = createContext<IAuthState>(initialState)

const AuthState = ({ children }: AuthStateProps) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const hasGroup = (group: string): boolean => {
    const groups = getUserGroups()
    return groups.includes(group)
  }

  const authWithFirebase = async (username, password) => {
    const formatteduserName = `${username}@exams.com`
    try {
      await auth().signInWithEmailAndPassword(formatteduserName, password)
    } catch (e) {
      if (e.code && e.code === FIREBASE_INEXISTENT_ERROR) {
        await auth().createUserWithEmailAndPassword(formatteduserName, password)
      }
    }
  }

  const handleLoginError = (error: any) => {
    // We cannot and should not recover from a network error in this case.
    // When a network error occurs, axios throws a new Error('Network error'),
    // which does not have a status
    if (!error?.response?.status) {
      dispatch({ type: 'NETWORK_ERROR' })
      return
    }

    const status = error?.response?.status
    const data = error?.response?.data

    // in this case, the user is already logged in
    // on another session, so we show him a warning page
    if (status === 400 && data.clientId) {
      const [newSessionMessage, activeSession] = data.clientId
      dispatch({
        type: 'SHOW_NEW_SESSION_PAGE',
        payload: {
          showNewSessionPage: true,
          newSessionMessage,
          activeSession
        }
      })

      return
    }

    // if it's neither a network error, neither a session mismatch,
    // we just handle a generic loging error
    dispatch({ type: 'LOGIN_ERROR', payload: data })
  }

  const handleLogin = useCallback(
    async (loginUrl: string, params: any): Promise<boolean> => {
      dispatch({ type: 'LOGIN' })

      let response: AxiosResponse

      try {
        response = await axios.post(loginUrl, params)
        // intentionally uncaught NPE in case of response.data.user missing
        await authWithFirebase(response.data.user.id, params.password)
      } catch (error) {
        handleLoginError(error)
        return false
      }

      const { token, user, theme } = response.data
      const payload = { theme, user }
      dispatch({ type: 'LOGIN_SUCCESS', payload: payload })
      saveToken(token)
      saveUser(user)
      saveTheme(theme)
      configAuthorizationHeader()

      try {
        await db.delete()
        await db.open()
      } catch (_) {
        // Nothing
      }

      return true
    },
    []
  )

  const login: AuthStateLogin = useCallback(
    ({ username, password, setNewClient }) => {
      return handleLogin(`${API_HOST}/v1/login`, {
        username,
        password,
        setNewClient,
        clientId: getClientId()
      })
    },
    [handleLogin]
  )

  const providerLogin = useCallback(
    ({
      provider,
      providerToken,
      setNewClient = false
    }: AuthStateProviderLoginArgs) => {
      return handleLogin(`${API_HOST}/v1/provider/${provider}/login`, {
        setNewClient,
        token: providerToken,
        clientId: getClientId()
      })
    },
    [handleLogin]
  )

  const logout = async () => {
    try {
      await axios.post(`${API_HOST}/v1/logout`)
      await auth().signOut()
    } finally {
      clearToken()
      clearTheme()
      try {
        db.delete()
      } catch (_) {
        // Nothing
      }
    }
  }

  useEffect(() => {
    const savedUser = getUser()
    const savedTheme = getTheme()
    const payload = { theme: savedTheme, user: savedUser }
    dispatch({ type: 'UPDATE_LOCAL_STORAGE_OBJECTS', payload: payload })
  }, [])

  const contextValue = {
    ...state,
    dispatch,
    login,
    logout,
    providerLogin,
    hasGroup
  }
  return (
    <AuthContext.Provider value={contextValue}>
      {childrenIsFunction(children) ? children(contextValue) : children}
    </AuthContext.Provider>
  )
}

export default AuthState
