export const buildBlockingAlertOptions = (
  title,
  content,
  backgroundImage,
  backgroundImageAlt,
  showConfirmButton = false
) => {
  return {
    title: `<span style="color: white">${title}</span>`,
    showCloseButton: false,
    showConfirmButton: showConfirmButton,
    html: `<span style="color: white">${content}</span>`,
    backdrop: 'rgb(35, 96, 122, 1)',
    background: 'rgb(35, 96, 122, 1)',
    imageUrl: backgroundImage,
    imageAlt: backgroundImageAlt,
    allowOutsideClick: false,
    allowEnterKey: false,
    allowEscapeKey: false
  }
}
