import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

type CandidateBehaviourDisplayProps = {
  className?: string
  lookDirection?: 'RIGHT' | 'LEFT' | 'UP' | 'DOWN' | 'MISSING' | 'MULTIPLE' | ''
}

const CandidateBehaviourDisplay = ({
  className,
  lookDirection
}: CandidateBehaviourDisplayProps) => {
  const { t } = useTranslation()

  const [hasError, setHasError] = useState(false)
  const [text, setText] = useState('')

  const decideText = () => {
    const lookDirectionTextLookup = {
      LEFT: 'Looking left',
      RIGHT: 'Looking right',
      UP: 'Looking up',
      DOWN: 'Looking down',
      MISSING: 'Candidate not found',
      MULTIPLE: 'Multiple people found'
    }

    if (lookDirection) {
      setHasError(true)
      setText(lookDirectionTextLookup[lookDirection])
      return
    }

    setHasError(false)
    setText('Normal')
  }

  useEffect(() => {
    decideText()
  }, [decideText])

  return (
    <div className={className}>
      <p className={`inner-text  ${hasError ? 'error' : 'normal'}`}>
        {t(text)}
      </p>
    </div>
  )
}

export default styled(CandidateBehaviourDisplay)`
  background-color: #606060;
  text-align: center;
  opacity: 0.7;

  .inner-text {
    padding: 5px;
    margin: 0;
    font-size: 12px;
    font-weight: bold;
    color: white;
  }

  .error {
    border: 1px solid red;
  }

  .normal {
    border: 1px solid green;
  }
`
