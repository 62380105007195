import React, { ReactNode } from 'react'
import { Tooltip } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

type QuestionMarkProps = {
  title: ReactNode
}

const QuestionMark = ({ title }: QuestionMarkProps) => (
  <Tooltip title={title}>
    <span>
      <FontAwesomeIcon icon="question-circle" />
    </span>
  </Tooltip>
)

export default QuestionMark
