import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import Button from '../../components/Button'
import ToggleMenuButton from '../../components/Buttons/ToggleMenuButton'
import { DashboardVideosSocketContext } from '../../states/DashboardVideosSocketState'
import { IExamDashboardApplication } from '../../types'
import ExamInfoIndicator from './ExamInfoIndicator'
import ExamStatusIndicator from './ExamStatusIndicator'
import StreamingWrapper from './StreamingWrapper'
import VideoIconQueue from './VideoIconQueue'
import CandidateBehaviourDisplay from './CandidateBehaviourDisplay'
import MessageToCandidate from './MessageToCandidate'
import { AuthContext } from 'states/AuthState'

type CandidateVideoProps = {
  className?: string
  application: IExamDashboardApplication
}

export type CandidateOnlineStatus = {
  lastPingTimestamp: string
  exam_status: ExamStatus
  questions: CandidateMessage
  break: CandidateBreakPacket
}

export type ExamStatus = {
  status: 'PAUSED' | 'NORMAL' | 'FINISHED'
  reason: string
}

export type CandidateAlert = {
  message: string
  when: string
}

export type CandidateMessage = {
  message: string
  when: string
}

export type CandidateBreakPacket = {
  when: Date
  confirmedStatus: 'CONFIRMED' | 'REJECTED' | 'PENDING'
}

const CandidateVideo = ({ className, application }: CandidateVideoProps) => {
  const { t } = useTranslation()
  const { hasGroup } = useContext(AuthContext)
  const {
    pauseCandidateExam,
    resumeCandidateExam,
    sendAlert,
    sendReloadRequest,
    candidatesConnectionStatus,
    candidatesMessageStatus,
    candidatesActivityStatus,
    candidatesBreakInfo,
    candidatesFinishedStatus,
    candidatesPausedStatus,
    candidatesPermissionStatus
  } = useContext(DashboardVideosSocketContext)

  const [paused, setPaused] = useState(false)
  const [sendingMessage, setSendingMessage] = useState(false)

  const isAdmin = hasGroup('ADMINISTRADOR')
  const isOnline = candidatesConnectionStatus[application.user.id]
  const question = candidatesMessageStatus[application.user.id]
  const breakStatus = candidatesBreakInfo[application.user.id]
  const activity =
    candidatesActivityStatus[application.user.id] &&
    candidatesActivityStatus[application.user.id].reason
  const finished = candidatesFinishedStatus[application.user.id]
  const pauseStatus = candidatesPausedStatus[application.user.id]
  const permissionStatus = candidatesPermissionStatus[application.user.id]

  const sendPausePacket = () => {
    pauseCandidateExam(application.user.id, t('Paused by applicator'))
    setPaused(true)
  }

  const sendResumePacket = () => {
    resumeCandidateExam(application.user.id)
    setPaused(false)
  }

  const handleMessage = (content: string) => {
    if (!content) {
      return
    }
    sendAlert(application.user.id, content)
  }

  const shouldShowPaused = () => {
    if (paused) {
      return true
    }
    return pauseStatus === 'PAUSED'
  }

  return (
    <div className={className}>
      <VideoIconQueue
        application={application}
        breakStatus={breakStatus}
        question={question}
      />
      {isOnline && !finished && permissionStatus && (
        <StreamingWrapper user={application.user} />
      )}
      {/* {status === 'online' && <VideoWrapper onDisconnect={handleDisconnect} onConnect={handleConnect} application={application} />} */}
      {(!isOnline || finished) && (
        <div className="disconnected-info">
          <p>{t('Disconnected')}</p>
        </div>
      )}
      {!permissionStatus && (
        <div className="disconnected-info">
          <p style={{ textAlign: 'center' }}>
            {t('Camera permission pending')}
          </p>
        </div>
      )}

      {sendingMessage && (
        <MessageToCandidate
          candidateName={application.user.name}
          onClose={() => setSendingMessage(false)}
          className="message-choosing-container"
          onMessageChosen={handleMessage}
        />
      )}
      {isOnline && (
        <CandidateBehaviourDisplay
          className="candidate-behaviour-display"
          lookDirection={activity}
        ></CandidateBehaviourDisplay>
      )}
      <div className="video-footer">
        <div className="video-footer-content">
          <div className="video-footer-sub-section">
            <ExamStatusIndicator
              status={
                shouldShowPaused() ? 'paused' : isOnline ? 'online' : 'offline'
              }
            />
            <p className="candidate-name">{application.user.name}</p>
          </div>
          <div className="video-footer-sub-section">
            <ExamInfoIndicator
              application={application}
              status={
                finished
                  ? 'finished'
                  : paused
                  ? 'paused'
                  : isOnline
                  ? 'online'
                  : 'offline'
              }
            />
            <div className="kebab-menu">
              <ToggleMenuButton
                action="icon"
                data-testid="resume-toggle-menu-button"
                disabled={!isOnline || isAdmin}
              >
                <Button
                  action="unstyled"
                  type="button"
                  onClick={
                    shouldShowPaused() ? sendResumePacket : sendPausePacket
                  }
                >
                  {shouldShowPaused() ? t('Resume exam') : t('Pause exam')}
                </Button>
                <Button
                  action="unstyled"
                  type="button"
                  onClick={() => setSendingMessage(true)}
                >
                  {t('Send alert')}
                </Button>
                <Button
                  action="unstyled"
                  type="button"
                  onClick={() => sendReloadRequest(application.user.id)}
                >
                  {t('Send reload request')}
                </Button>
              </ToggleMenuButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default styled(CandidateVideo)`
  flex: 1 1 20%;
  height: 210px;
  display: grid;
  grid-template-rows: 80% 20%;
  border: 1px solid #707070;
  margin: 8px;
  position: relative;

  .candidate-behaviour-display {
    position: absolute;
    bottom: 20%;
    width: 100%;
  }

  .message-choosing-container {
    height: 100%;
    z-index: 2;
    position: absolute;
  }

  .video-footer {
    position: absolute;
    bottom: 0;
    height: 20%;
    width: 100%;
    background-color: #e3e3e3;
    align-self: flex-end;
    outline: 1px solid #d5d5d5;
  }

  .video-footer-content {
    display: flex;
    justify-content: space-between;
    height: 100%;
    align-items: center;
    margin-left: 10px;
    margin-right: 10px;
    font: Bold 12px Open Sans;
    color: #5a5a5a;
  }

  .video-footer-sub-section {
    display: flex;
    align-items: center;
  }

  .candidate-name {
    text-transform: capitalize;
    margin-left: 5px;
  }

  .kebab-menu > button {
    border: none;
    width: 1.5rem;
    margin-left: 5px;
  }

  .disconnected-info {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    color: ${(props) => props.theme.colors.text};
    background: ${(props) => props.theme.colors.grayMedium};

    &.bathroom {
      box-shadow: inset 0px 0px 0px 5px #528652;
      opacity: 0.61;
      background: ${(props) => props.theme.colors.grayDark};
      color: white;
    }
  }

  .disconnected-info > p {
    align-self: center;
    font-weight: bold;
  }

  .has-error {
    border: 1px solid red;
  }
`
