import React from 'react'
import styled from 'styled-components'
import { IReportCardItem } from 'types'

type ItemExtraProps = {
  item: IReportCardItem
  className?: string
}

const ItemExtra = ({ item, className }: ItemExtraProps) => {
  return (
    <div className={className}>
      {item.reportCardExtras.map((itemExtra) => (
        <div key={item.id + itemExtra.label}>
          <span className="exam-details">
            {itemExtra.values &&
              itemExtra.values.map((val) => (
                <li key={val} className="exam-details">
                  {val}
                </li>
              ))}
          </span>
        </div>
      ))}
    </div>
  )
}

export default styled(ItemExtra)`
  .exam-details {
    font-weight: 500;
    font-size: 12px;
    margin-left: 10px;
  }
`
