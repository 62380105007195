import React, {
  createContext,
  useEffect,
  useReducer,
  ReactElement
} from 'react'
import axios from 'axios'
import { EMAIL_API_HOST, EMAIL_PARTNER_TOKEN } from '../consts'
import { childrenIsFunction, IEmailState } from '../types'

type EmailStateProps = {
  children: Function | ReactElement
}

export const initialState: IEmailState = {
  templates: [],
  templateLoading: false
}

const reducer = (state: IEmailState, action) => {
  switch (action.type) {
    case 'SET_TEMPLATES':
      return { ...state, templates: action.payload, templateLoading: false }
    case 'SET_TEMPLATE_LOADING':
      return { ...state, templateLoading: action.payload }
    default:
      return state
  }
}

export const EmailContext = createContext<IEmailState>(initialState)

export const EmailState = ({ children }: EmailStateProps) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const getEmailHeaders = () => {
    return {
      Authorization: EMAIL_PARTNER_TOKEN
    }
  }

  const fetchTemplates = async () => {
    dispatch({ type: 'SET_TEMPLATE_LOADING', payload: true })
    const response = await axios.get(`${EMAIL_API_HOST}/v2/types`, {
      headers: getEmailHeaders()
    })
    dispatch({ type: 'SET_TEMPLATES', payload: response.data.body })
  }
  useEffect(() => {
    fetchTemplates()
  }, [])

  const contextValue = { ...state }

  return (
    <EmailContext.Provider value={contextValue}>
      {childrenIsFunction(children) ? children(contextValue) : children}
    </EmailContext.Provider>
  )
}

export default EmailState
