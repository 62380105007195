import MuiCheckbox from '@material-ui/core/Checkbox'
import { useFormikContext, useField } from 'formik'
import React, { ReactNode } from 'react'
import { FormLabel } from '@material-ui/core'

type CheckboxProps = {
  name: string
  label?: ReactNode
}

const Checkbox = ({ name, label }: CheckboxProps) => {
  const { setFieldValue } = useFormikContext()
  const [field, meta] = useField({ name })

  return (
    <FormLabel>
      <MuiCheckbox
        color="primary"
        onChange={(e) => setFieldValue(field.name, e.target.checked)}
        checked={field.checked}
        {...field}
        {...meta}
      />
      {label}
    </FormLabel>
  )
}

export default Checkbox
