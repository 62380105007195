import React, { useContext, useEffect } from 'react'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'
import FrameCaptureState from 'states/FrameCaptureState'
import ReviewAnswers from '../components/ReviewAnswers'
import db from '../db'
import useQuery from '../hooks/useQuery'
import ApplicationSocketState from '../states/ApplicationSocketState'
import ApplicationState from '../states/ApplicationState'
import { AuthContext } from '../states/AuthState'
import Answer from './Answer'
import Instructions from './Instructions'

const Application = () => {
  const query = useQuery()
  const match = useRouteMatch()
  const { user } = useContext(AuthContext)

  useEffect(() => {
    if (query.get('clear_database')) {
      db.delete().then(() => {
        window.location.href = '/'
      })
    }
  }, [query, user])

  return (
    <ApplicationSocketState>
      {() => (
        <FrameCaptureState>
          {() => (
            <ApplicationState>
              {() => (
                <div>
                  <Switch>
                    <Route
                      path={match.path + '/instructions'}
                      component={Instructions}
                    />
                    <Route
                      path={match.path + '/review'}
                      component={ReviewAnswers}
                    />
                    <Route
                      path={match.path + '/answers/:answerId'}
                      component={Answer}
                    />
                    <Redirect to={match.url + '/instructions'} />
                  </Switch>
                </div>
              )}
            </ApplicationState>
          )}
        </FrameCaptureState>
      )}
    </ApplicationSocketState>
  )
}

export default Application
