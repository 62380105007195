import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import EmailRecipientForm from '../components/Email/EmailRecipientForm'
import Stripe from '../components/Stripe'
import Page from '../components/Page'
import EmailState from '../states/EmailState'
import usePreference from 'hooks/usePreference'
import { IBooleanPreference } from 'types'
import { Redirect } from 'react-router-dom'

type EmailRecipientFormPageProps = {
  className?: string
  location: any
}

const EmailRecipientFormPage = ({
  className,
  location
}: EmailRecipientFormPageProps) => {
  const { t } = useTranslation()
  const { users } = location?.state
  const emailsEnabled = usePreference<IBooleanPreference>(
    'Application__EmailFlowEnabled'
  )

  if (emailsEnabled && !emailsEnabled.value) {
    return <Redirect to="/not-found"></Redirect>
  }

  return (
    <div className={className}>
      <Stripe title={t('Send emails')} />
      <Page>
        <EmailState>
          <EmailRecipientForm users={users} />
        </EmailState>
      </Page>
    </div>
  )
}

export default styled(EmailRecipientFormPage)``
