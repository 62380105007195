import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import { faFile } from '@fortawesome/free-solid-svg-icons'
import styled from 'styled-components'

type SubtitleDisplayIdentifierProps = {
  className?: string
  publicIdentifier: string
}

const SubtitleDisplayIdentifier = ({
  publicIdentifier,
  className
}: SubtitleDisplayIdentifierProps) => {
  const { t } = useTranslation()

  return (
    <span className={className}>
      <FontAwesomeIcon className="icon-space" icon={faFile} />
      {t('Identifier').toUpperCase()}: {publicIdentifier}
    </span>
  )
}

export default styled(SubtitleDisplayIdentifier)`
  font-size: 12px;

  .icon-space {
    margin-right: 10px;
  }
`
