import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { IReportCardItem } from '../../types'
import ItemExtra from './ItemExtra'

type ItemExtraProps = {
  items: IReportCardItem[]
  className?: string
}

const ReportCardItem = ({ items, className }: ItemExtraProps) => {
  const { t } = useTranslation()

  const getItemSituation = (item) => {
    if (item.isCancelled) {
      return (
        <span className="exam-details cancelled ml-1">
          {t('CANCELLED ITEM')}
        </span>
      )
    }
    if (item.answerIsCorrect) {
      return <span className="exam-details correct">{t('Correct answer')}</span>
    }
    if (item.answerIsCorrect === false) {
      return <span className="exam-details wrong">{t('Wrong answer')}</span>
    }
    return (
      <span className="exam-details not-answered">{t('Not answered')}</span>
    )
  }

  const getCorrectAnswer = (item) => {
    if (item.isCancelled) {
      return t('CANCELLED ITEM')
    }
    return item.correctAnswer || '-'
  }

  const getAnswered = (item) => {
    if (item.isCancelled) {
      return `${item.yourAnswer} - ${t('Considered right')}`
    }
    return item.yourAnswer || ''
  }

  const getAnswerKeyResults = (item) => {
    const correctAnswer = getCorrectAnswer(item)
    const answered = getAnswered(item)
    return `${t('Answer key')}: ${correctAnswer} | ${t(
      'Your answer'
    )}: ${answered}`
  }

  const getItemPosition = (item) => {
    if (item.reportCardExtras) {
      return ` ${item.position}:`
    }
    return ':'
  }

  return (
    <div className={className}>
      {items.map((item) => (
        <div key={item.id}>
          <span className="exam-details answer-key">
            <strong>
              • Item{getItemPosition(item)} {getAnswerKeyResults(item)} |
              {getItemSituation(item)}
            </strong>
          </span>
          {item.reportCardExtras ? <ItemExtra item={item} /> : ''}
        </div>
      ))}
    </div>
  )
}

export default styled(ReportCardItem)`
  .exam-details {
    font-weight: 500;
    font-size: 12px;
    margin-left: 10px;
  }

  .cancelled {
    color: #168cd5;
    font-weight: 600;
  }

  .wrong {
    color: #d61527;
    font-weight: 600;
  }

  .correct {
    color: #018821;
    font-weight: 600;
  }

  .not-answered {
    color: #d61527;
    font-weight: 600;
  }

  .answer-key {
    border-bottom: 0.05em solid #dddddd;
  }
`
