import * as firebase from 'firebase'
import { IUser } from './types'
import moment from 'moment'
import { FIREBASE_CONFIG } from './consts'
import { RollbarErrorTracking } from './utils/rollbar'

// this is sad, but necessary
declare let MediaRecorder: any

class VideoRecordingManager {
  private mediaRecorder
  private blob: any
  private storageInstance: firebase.storage.Storage
  private dbInstance: firebase.database.Database
  private user: IUser | undefined
  private canRequestData = true
  private currentStream: MediaStream | null = null
  private isRecording = false
  private firebaseUser: firebase.User
  private hasValidFirebaseConfig = false
  // records consistent chunks of 5 seconds
  private readonly DEFAULT_RECORDING_CHUNK_SIZE = 5000
  private VIDEO_START_TIMESTAMP = new Date()

  constructor() {
    let parsedConfig = null
    try {
      parsedConfig = JSON.parse(FIREBASE_CONFIG)
    } catch (e) {
      parsedConfig = null
    }
    if (!FIREBASE_CONFIG || !parsedConfig) {
      console.warn(
        'Firebase config missing or improperly configured, will not upload chunks.'
      )
      return
    }
    this.hasValidFirebaseConfig = true
    firebase.initializeApp(parsedConfig)
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.firebaseUser = user
        unsubscribe()
      }
    })
    this.storageInstance = firebase.storage()
    this.dbInstance = firebase.database()
  }

  public startRecordingForUser(stream: MediaStream, user: IUser) {
    if (this.isRecording || !user || !this.hasValidFirebaseConfig) {
      return
    }
    this.isRecording = true
    this.currentStream = stream
    this.user = user
    try {
      this.mediaRecorder = new MediaRecorder(stream, {
        mimeType: 'video/webm; codecs="vp8, opus"'
      })
      this.setUpMediaRecorderEvents()
      this.VIDEO_START_TIMESTAMP = new Date()
      this.mediaRecorder.start()
    } catch (e) {
      console.log(e)
    }
  }

  public destroy() {
    // It is expected that some tracks or the media recorder
    // itself fails to stop sometimes.
    try {
      if (this.mediaRecorder) {
        this.mediaRecorder.stop()
      }
      if (this.currentStream) {
        this.currentStream
          .getTracks() // get all tracks from the MediaStream
          .forEach((track) => track.stop()) // stop each of them
      }
    } catch (e) {
      RollbarErrorTracking.LogError(e)
    }
    this.isRecording = false
  }

  private setUpMediaRecorderEvents() {
    this.mediaRecorder.addEventListener('dataavailable', async (e) => {
      console.warn('Got data from dataavailable')
      if (e.data.size > 0) {
        this.blob = e.data
        await this.uploadChunk(new Date())
      }
    })

    this.requestInterval()
  }

  private requestInterval() {
    console.warn('request interval called')
    setTimeout(() => {
      console.log(this.mediaRecorder.state)
      if (this.mediaRecorder.state === 'inactive') {
        return
      }
      if (this.canRequestData) {
        this.canRequestData = false
        // this.mediaRecorder.requestData()
        this.mediaRecorder.stop()
        this.mediaRecorder.start()
      }
    }, this.DEFAULT_RECORDING_CHUNK_SIZE)
  }

  private async uploadVideo(data: any): Promise<string> {
    return new Promise((resolve) => {
      const fileReader = new FileReader()
      fileReader.onload = (event) => {
        if (!event.target || !this.user) {
          return
        }
        const fileName = moment(new Date()).format('DD-MM-YYYY hh:mm:ss a')
        const filePath = `/${this.firebaseUser.uid}/${fileName}.webm`
        const arrayBuffer = (event.target as any).result
        const fileTask = this.storageInstance.ref(filePath).put(arrayBuffer)
        fileTask.on('state_changed', null, null, async () => {
          resolve(await fileTask.snapshot.ref.getDownloadURL())
        })
      }
      fileReader.readAsArrayBuffer(data)
    })
  }

  private async uploadChunk(endTime: Date) {
    if (!this.blob || !this.user) {
      return
    }
    if (!this.firebaseUser) {
      console.warn(
        'Firebase authentication not found, chunk uploading will not work.'
      )
      return
    }
    const copy = this.blob
    const downloadURL = await this.uploadVideo(copy)
    if (this.user && this.user.provider) {
      const updatePayload = {
        filePath: downloadURL,
        userName: this.user.name,
        recordingStart: this.VIDEO_START_TIMESTAMP.toISOString(),
        recordingEnd: endTime.toISOString()
      }

      this.VIDEO_START_TIMESTAMP = endTime
      const dbInstancePath = `${this.firebaseUser.uid}/videos/`
      const result = await this.dbInstance
        .ref(dbInstancePath)
        .push(updatePayload)
      await this.dbInstance
        .ref(`/allVideos/${result.key}`)
        .update({ ...updatePayload, nodeKey: result.key })
      this.blob = null
      // this.mediaRecorder.start();
      this.canRequestData = true
      console.log(downloadURL + ' saved successfully.')
      this.requestInterval()
    }
  }
}

const videoManager = new VideoRecordingManager()
export default videoManager
