import styled from 'styled-components'

const InstructionsCard = styled.div`
  text-align: left;
  padding: 2rem;
  background-color: white;
  border: 0.5pt solid #bebebe;
  margin: 0.5rem;

  h3 {
    color: ${(props) => props.theme.clientColors.text};
    font-size: 18pt;
    margin-top: 0;
    font-weight: 600;
  }
`

export default InstructionsCard
