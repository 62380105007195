import { ChangeEvent, ReactNode } from 'react'

export const childrenIsFunction = function (
  children: Function | ReactNode
): children is Function {
  return typeof children === 'function'
}

export interface INestedResource {
  _url: string
  id: number
}

export interface IAlternative {
  id: number
  content: string
  letter: string
  position: number
}

export interface IItem {
  id: number
  introduction: string
  question: string
  alternatives: IAlternative[]
  category: 'FREE_RESPONSE' | 'MULTIPLE_CHOICE'
  freeResponseMaxLength: number | undefined
  answerTimeLimit: number | undefined
}

export interface IAnswerAlternative {
  id: number
  letter: string
  position: number
  content: undefined
}

export interface IAnswer {
  id: number
  position: number
  alternative?: IAnswerAlternative
  freeResponse: string
  item: INestedResource
  application: INestedResource
  lastAnswered: boolean
  seconds: number
  timeoutDate: string
  _changed: number // Must be number because booleans cannot be indexed
}

export interface ITimeWindow {
  startTime: string
  endTime: string
  maxDuration: number
}

export interface IExam {
  id: number
  name: string
  timeWindows?: ITimeWindow[]
  duration: number
  instructions?: string
  numItems: number
  canUpdateAnswer: boolean
  canBrowseAcrossItems: boolean
  shuffleItems: boolean
  collection: ICollection
}

export interface IReportCardItemExtra {
  label: string
  values?: string[]
}

export interface IReportCardItem {
  id: number
  position: number
  reportCardExtras: IReportCardItemExtra[]
  isCancelled: boolean
  answerIsCorrect: boolean
  correctAnswer: string
  yourAnswer: string
}

export interface IReportCardExam {
  id: number
  name: string
  items?: IReportCardItem[]
}

type TApplicationStatus =
  | 'STARTED'
  | 'FINISHED'
  | 'AVAILABLE'
  | 'AVAILABLE_SOON'
  | 'UNAVAILABLE'

export interface IApplication {
  id: number
  shouldUpdateAnswers: boolean
  forceItemUpdates: number[]
  exam: IExam
  timeWindows?: ITimeWindow[]
  isAvailable: boolean
  startedAt?: string
  finishedAt?: string
  title: string
  instructionsUrl: string
  status: TApplicationStatus
  // Be cautious when using this value, because it is only correct when
  // application is loaded
  secondsToTimeoutWhenLoaded?: number
  secondsToTimeout: number
  asCollection?: boolean
  reportCardId?: number
  roomId?: number
  reseted: boolean
}

export interface ApplicationResponse {
  id: number
  shouldUpdateAnswers: boolean
  forceItemUpdates: number[]
  exam: INestedResource
}

export interface IConfig {
  syncAnswerInterval: number
  fetchNotificationInterval: number
  syncApplicationsExamDashboardInterval: number
  allowHideRemainingTimeAnswerPage: boolean
  showSecondsInRemainingTime: boolean
  disableItemTextHighlight: boolean
}

export interface IListState {
  results: any[]
  isLoading: boolean
  hasError: boolean
  count: number
  next?: string
  previous?: string
  page: number
  pageSize: number
  filterParams: any
  numPages: number
  ordering: string
  search: string | undefined
  handleFilter: (params?: any) => void
  handlePageChange: (page: number) => void
  handlePageSizeChange: (pageSize: number) => void
}

export type ListStateAction =
  | { type: 'FETCH_LIST_SUCCESS'; payload: any }
  | { type: 'HANDLE_FILTER'; payload: any }
  | { type: 'CHANGE_PAGE'; payload: number }
  | { type: 'CHANGE_PAGE_SIZE'; payload: number }
  | { type: 'CHANGE_SEARCH'; payload: string | undefined }
  | { type: 'FETCH_LIST_ERROR' }
  | { type: 'FETCH_LIST' }

export interface IApplicationListState extends IListState {
  collectionId?: number
}

export type INetworkStateAction = {
  type: 'SET_OFFLINE_MESSAGE'
  payload: { message: string; isConnected: boolean }
}

export interface INetworkState {
  dispatch: (action: INetworkStateAction) => void
  offlineMessage: string
  isConnected: boolean
}

export type IApplicationStateAction =
  | { type: 'FETCH_APPLICATION' }
  | { type: 'FETCH_APPLICATION_ERROR' }
  | {
      type: 'FETCH_APPLICATION_SUCCESS'
      payload: {
        application: IApplication
        secondsToTimeout: number | undefined
      }
    }
  | { type: 'FETCH_ANSWERS' }
  | { type: 'FETCH_ANSWERS_ERROR' }
  | { type: 'FETCH_ANSWERS_SUCCESS'; payload: IAnswer[] }
  | { type: 'UPDATE_ANSWER_IN_ANSWERS'; payload: IAnswer }
  | { type: 'START_APPLICATION' }
  | { type: 'START_APPLICATION_SUCCESS'; payload: number }
  | { type: 'START_APPLICATION_ERROR'; payload: string }
  | { type: 'FINISH_APPLICATION' }
  | { type: 'FINISH_APPLICATION_SUCCESS' }
  | { type: 'FINISH_APPLICATION_ERROR'; payload: string }

export interface IApplicationState {
  application: IApplication | undefined
  answers: IAnswer[]
  fetchingApplication: boolean
  fetchingAnswers: boolean
  fetchApplicationError: boolean
  fetchAnswersError: boolean
  dispatch: (action: IApplicationStateAction) => void
  startApplication: () => void
  fetchAnswers: () => void
  resumeApplication: () => void
  finishApplication: () => void
  startingApplication: boolean
  startApplicationError: string
  finishingApplication: boolean
  finishApplicationError: string
  secondsToTimeout: number | undefined
  remainingTimeInitialDate: number | undefined
  handleTimeout: () => void
}

export const SOCKET_EVENTS = {
  AUTH: 'authenticate',
  JOIN_ROOM: 'join-room',
  PAUSE: 'pause-exam',
  RESUME: 'resume-exam',
  ALERT: 'alert',
  SERVER_PULSE: 'room-pulse',
  CANDIDATE_CONNECT: 'candidate-connected',
  CANDIDATE_DISCONNECT: 'candidate-disconnected',
  CANDIDATE_MESSAGE: 'candidate-message',
  CANDIDATE_BREAK: 'candidate-break',
  CANDIDATE_BREAK_RESPONSE: 'candidate-break-response',
  CANDIDATE_BACK_FROM_BREAK: 'candidate-return-from-break',
  CANDIDATE_ACTIVITY: 'candidate-activity',
  UPDATE_EXAM_STATUS: 'exam-status-update',
  GET_EXAM_STATUS: 'get-candidate-status',
  EXAM_STATUS_UPDATED: 'exam-status-updated',
  PERMISSION_STATUS_CHANGED: 'permission-status-changed',
  NEW_CAMERA_FRAME: 'new-camera-frame',
  RELOAD_REQUEST: 'reload-request',
  SET_ROOM: 'set-room'
}

export type AnswerStateAction =
  | { type: 'FETCH_ITEM' }
  | { type: 'FETCH_ITEM_ERROR'; payload: string }
  | { type: 'FETCH_ITEM_SUCCESS'; payload: IItem | undefined }
  | { type: 'FETCH_ANSWER' }
  | { type: 'FETCH_ANSWER_ERROR'; payload: string }
  | { type: 'FETCH_ANSWER_SUCCESS'; payload: IAnswer | undefined }
  | { type: 'UPDATE_ANSWER_ALTERNATIVE'; payload: IAnswer }
  | { type: 'UPDATE_FREE_RESPONSE'; payload: IAnswer }
  | { type: 'SET_ALREADY_ANSWERED'; payload: boolean }
  | { type: 'SET_CHANGE_ANSWER'; payload: boolean }
  | { type: 'PAUSE_APPLICATION'; payload: string }

export interface IVideoStreamingState {
  triedToAskForPermission: boolean
  hasPermissionError: boolean
  granted: boolean
  startStreaming: () => void
  permissionsAreOk: () => Promise<boolean>
  setPermissionGranted: (granted: boolean) => void
  isPermissionModalOpen: boolean
}

export type VideoStreamingStateAction =
  | { type: 'ASKING_FOR_PERMISSION'; payload: { isModalOpen: boolean } }
  | { type: 'CAMERA_PERMISSION_RESULT'; payload: { granted: boolean } }
  | { type: 'CAMERA_START_RESULT'; payload: { hasCameraError: boolean } }

export interface IAnswerState {
  answer: IAnswer | undefined
  item: IItem | undefined
  updateAnswer(alternative: IAlternative): void
  goAnswer(answer: IAnswer): void
  getAnswerFromPosition(position: number): IAnswer | undefined
  remainingTime: string
  previousAnswer: IAnswer | undefined
  nextAnswer: IAnswer | undefined
  fetchingItem: boolean
  fetchItemError: string
  fetchingAnswer: boolean
  fetchAnswerError: string
  alreadyAnswered: boolean
  changeAnswer: boolean
  updateFreeResponse(freeResponse: string): void
  isAnswered(ans: IAnswer): boolean
  handleQuestionExpired(): void
}

export interface IExtraField {
  name: string
  value: string
}

export interface IUser {
  id: number
  name: string
  email: string
  groups: string[]
  extra?: {
    hierarchy?: {
      [key: string]: IExtraField
    }
  }
  provider?: IProvider
  publicIdentifier: string
  access_url: string
}

export interface IHierarchyCategory {
  id?: number
  parentId?: number | null
  codename: string
  name: string
  description: string
  parent?: IHierarchyCategory
}

export interface IHierarchy {
  id: number
  name: string
  type: string
  value: string
}

export type FormValues = {
  // Hierarchy values, which are dynamic, but always have the name attribute
  [key: string]: IHierarchy | undefined
} & {
  collection?: ICollection
}

export interface ILoginError {
  nonFieldErrors: string[]
  token: string[]
}

export type ISession = {
  id: number
  clientId: string
  browser: string
  os: string
}

export type ShowNewSessionPage = {
  showNewSessionPage: boolean
  newSessionMessage: string
  activeSession: ISession
}

export type ILocalStorageObjects = {
  theme: ITheme | undefined
  user: IUser | undefined
}

export type IAuthStateAction =
  | { type: 'LOGIN' }
  | { type: 'LOGIN_ERROR'; payload: ILoginError | undefined }
  | { type: 'LOGIN_SUCCESS'; payload: ILocalStorageObjects }
  | { type: 'NETWORK_ERROR' }
  | { type: 'UPDATE_USER'; payload: IUser | undefined }
  | { type: 'SHOW_NEW_SESSION_PAGE'; payload: ShowNewSessionPage }
  | { type: 'HIDE_NEW_SESSION_PAGE' }
  | { type: 'UPDATE_LOCAL_STORAGE_OBJECTS'; payload: ILocalStorageObjects }

export type AuthStateLoginArgs = {
  username: string
  password: string
  setNewClient?: boolean
}

export type AuthStateLogin = (args: AuthStateLoginArgs) => Promise<boolean>

export type AuthStateProviderLoginArgs = {
  provider: string
  providerToken: string
  setNewClient?: boolean
}

export type AuthStateProviderLogin = (
  args: AuthStateProviderLoginArgs
) => Promise<boolean>

export interface IProvider {
  codename: string
}

export interface IAuthState {
  user: IUser | undefined
  token: string | undefined
  theme: any | undefined
  dispatch: (action: IAuthStateAction) => void
  isSubmitting: boolean
  loginError: boolean
  networkError: boolean
  detailedErrors: ILoginError | undefined
  login: AuthStateLogin
  providerLogin: AuthStateProviderLogin
  logout: () => Promise<void>
  showNewSessionPage: boolean
  newSessionMessage: string
  activeSession?: ISession
  hasGroup: (group: string) => boolean
}

export type INotificationStateAction =
  | { type: 'FLUSH_ITEMS'; payload: { items: number[] } }
  | {
      type: 'FLUSH_ANSWERS'
      payload: { applicationId: number; fetchAnswers: () => null }
    }

export interface IExamDashboardApplicationUser {
  id: number
  name: string
  publicIdentifier: string
  img: string
  provider: IProvider | null
}

export interface IExamDashboardApplication {
  id: number
  numAnswered: number
  user: IExamDashboardApplicationUser
  status: TApplicationStatus
  startedAt?: string
  finishedAt?: string
  grades: {
    correct: number
    wrong: number
    total: number
  }
  timeWindows: ITimeWindow[]
  exam: IExam
  reseted: boolean
}

export type ExamDashboardStateAction =
  | { type: 'FETCH_EXAM' }
  | { type: 'FETCH_EXAM_SUCCESS'; payload: IExam }
  | { type: 'FETCH_EXAM_ERROR'; payload: string }
  | { type: 'FETCH_APPLICATIONS' }
  | { type: 'FETCH_APPLICATIONS_SUCCESS'; payload: IExamDashboardApplication[] }
  | { type: 'FETCH_APPLICATIONS_ERROR'; payload: string }
  | { type: 'ON_SEARCH_CHANGE'; payload: string }
  | { type: 'ON_FILTER_CHANGE'; payload: any }

export interface IExamDashboardState extends IListState {
  exam: IExam | undefined
  applications: IExamDashboardApplication[]
  fetchingExam: boolean
  fetchExamError: string
  fetchingApplications: boolean
  fetchApplicationsError: string
  filterParams: any
  totalApplications: number | undefined
  onSearchChange(event: ChangeEvent<HTMLInputElement>): void
  onPageSizeChange(event: ChangeEvent<HTMLInputElement>): void
  onFilter: (params) => void
  appliedFilters: { [key: string]: { id: number; name: string } }
  setBulkTimeWindow: (values: any) => Promise<any>
  getVerboseFilters: () => string[]
  fetchApplications: (shouldDispatchFetching?: boolean) => Promise<void>
}

export type DashboardVideosStateAction =
  | { type: 'FETCH_APPLICATIONS' }
  | { type: 'FETCH_APPLICATIONS_SUCCESS'; payload: any }
  | { type: 'FETCH_APPLICATIONS_ERROR'; payload: string }

export type DashboardVideosSocketStateAction =
  | { type: 'SOCKET_CONNECT_SUCCESS'; payload: any }
  | { type: 'ROOM_PULSE_RECEIVED'; payload: any }
  | { type: 'CANDIDATE_CONNECT'; payload: any }
  | { type: 'CANDIDATE_DISCONNECT'; payload: any }
  | { type: 'CANDIDATE_MESSAGE'; payload: any }
  | { type: 'CANDIDATE_BREAK'; payload: any }
  | { type: 'CANDIDATE_ACTIVITY'; payload: any }
  | { type: 'CANDIDATE_FINISHED_EXAM'; payload: any }

export type CandidateConnectionInfo = {
  [key: number]: boolean
}

export type CandidateMessageInfo = {
  [key: number]: string
}

export type CandidatesBreakInfo = {
  [key: number]: 'PENDING' | 'REJECTED' | 'CONFIRMED' | 'FINISHED'
}

export type CandidatesPauseInfo = {
  [key: number]: 'PAUSED' | 'NORMAL'
}

export type CandidateFinishedStatus = {
  [key: number]: boolean
}

export type CandidatesActivityStatusInfo = {
  [key: number]: {
    reason?: 'RIGHT' | 'LEFT' | 'UP' | 'DOWN' | 'MISSING' | 'MULTIPLE' | ''
    when: string
  }
}

export interface IDashboardVideosState {
  applications: IExamDashboardApplication[]
  fetching: boolean
  fetchError: string
  roomId: number
  roomData: any
}

export interface IDashboardVideosSocketState {
  socket: SocketIOClient.Socket
  pauseCandidateExam: (candidateId: number, reason?: string) => void
  resumeCandidateExam: (candidateId: number) => void
  sendAlert: (candidateId: number, content: string) => void
  candidatesConnectionStatus: CandidateConnectionInfo
  candidatesMessageStatus: CandidateMessageInfo
  candidatesBreakInfo: CandidatesBreakInfo
  candidatesActivityStatus: CandidatesActivityStatusInfo
  candidatesFinishedStatus: CandidateFinishedStatus
  candidatesPausedStatus: CandidatesPauseInfo
  candidatesPermissionStatus: CandidateConnectionInfo
  setMessageAsRead: (candidateId: number) => void
  setActivityAsRead: (candidateId: number) => void
  sendBreakResponse: (candidateId: number, result: boolean) => void
  getExamStatus: (candidateId: number) => Promise<ExamStatus>
  sendReloadRequest: (candidateId: number) => void
  joinRoomAsApplicator: (roomId: number) => void
}

export type ExamStatus = {
  currentQuestion?: number
  finished?: boolean
  examStartDate?: string
}

export type IApplicationConfiguration = {
  requiresVideo: boolean
  timeToReturnFromBreakInSeconds: number
}

export interface IApplicationSocketState {
  socket: SocketIOClient.Socket
  pauseReason: string
  status: 'NORMAL' | 'PAUSED' | 'BREAK'
  alertMessage: string
  connected: boolean
  sendQuestion: (content: string) => void
  askForBreak: () => void
  hasPendingBreak: boolean
  setHasPending: (hasPending: boolean) => void
  joinRoomAsCandidate: (roomId: number) => void
  returnFromBreak: () => void
  updateExamStatus: (examStatus: ExamStatus) => void
  setCameraAccepted: (accepted: boolean) => void
  sendCameraFrame: (base64Frame: string) => void
  forcePause: boolean
}

export type IApplicationSocketStateAction =
  | { type: 'SOCKET_CONNECTED'; payload: any }
  | { type: 'PAUSE_APPLICATION'; payload: string }
  | { type: 'RESUME_APPLICATION' }
  | { type: 'ALERT'; payload: string }
  | { type: 'BREAK_ACCEPTED'; payload: any }

export interface ICollection {
  id: number
  instructions?: string
  name: string
  applicationConfiguration: number
  startTime: string
  endTime: string
}

export interface ICollectionState {
  collection?: ICollection
  fetchingCollection: boolean
  fetchCollectionError: string
}

export type CollectionStateAction =
  | { type: 'FETCH_COLLECTION' }
  | { type: 'FETCH_COLLECTION_SUCCESS'; payload: ICollection }
  | { type: 'FETCH_COLLECTION_ERROR'; payload: string }

export interface ITheme {
  name: string
  primary: string
  secondary: string
  secondaryDark: string
  text: string
  grayLight: string
  grayMedium: string
  grayDark: string
  danger: string
  AVAILABLE: string
  STARTED: string
  FINISHED: string
  AVAILABLE_SOON: string
  UNAVAILABLE: string
  UNKNOWN: string
  STOPPED: string
  logoImg: string
  errorImg: string
  footerMessage: string
}

export interface IIndividualExamReportCard {
  id: number
  generalGrade: number
  classGrade: number
  totalItens: number
  exam: IReportCardExam
}

export interface IIndividualReportCard {
  id: number
  generalGrade: number
  classGrade: number
  totalItens: number
  exams: IIndividualExamReportCard[]
  collection: ICollection
  user: IUser
  pdfUrl?: string | null
}

export interface IIndividualReportCardState {
  individualReportCard?: IIndividualReportCard
  fetchingIndividualReportCard: boolean
  fetchIndividualReportCardError: string
}

export interface IDashboardPreFilterState {
  isLoading: boolean
  totalResults: number
  results: DashboardPreFilter[]
  pageSize: number
  numPages: number
  onSearchChange(event: ChangeEvent<HTMLInputElement>): void
  handlePageChange(page: number): void
  onPageSizeChange(event: ChangeEvent<HTMLInputElement>): void
}

export type DashboardPreFilter = {
  id: number
  name: string
  totalExams: number
  totalApplications: number
  startTime: Date
  endTime: Date
}

export type DashboardPreFilterAction =
  | { type: 'FETCH' }
  | { type: 'FETCH_SUCCESS'; payload: any }
  | { type: 'FETCH_ERROR'; payload: string }
  | { type: 'ON_SEARCH_CHANGE'; payload: string }

export type IndividualReportCardStateAction =
  | { type: 'FETCH_INDIVIDUAL_REPORT_CARD' }
  | {
      type: 'FETCH_INDIVIDUAL_REPORT_CARD_SUCCESS'
      payload: IIndividualReportCard
    }
  | { type: 'FETCH_INDIVIDUAL_REPORT_CARD_ERROR'; payload: string }

export interface IIndividualReportCardListState extends IListState {
  downloadCollectionReportCards: (collection: ICollection) => void
}

export interface IImportExam {
  submitting: boolean
  file: Blob
}

export type PreferenceName =
  | 'Application__PublishFinishedEvent'
  | 'Application__RequiredFiltersCollectionResult'
  | 'Application__RequiredFiltersSetBulkTimeWindow'
  | 'Application__EmailFlowEnabled'

export interface IPreference {
  section: string
  name: string
  identifier: string
  verboseName: string | null
  helpText: string | null
  additionalData: any
}

export interface IStringPreference extends IPreference {
  value: string | null
}

export interface IBooleanPreference extends IPreference {
  value: boolean | null
}

export interface IEmailTemplate {
  id: number
  code: string
  description?: string
  body: string
  sender: string
  subject: string
  partnerId: number
  createdAt: string
  updatedAt: string
}

export interface IEmailState {
  templates: IEmailTemplate[]
  templateLoading?: boolean
  children?: any
}

export type IRoom = {
  id: number
  name: string
  opened: boolean
  openedAt: Date
  closedAt: Date
  capacity: number
}

export type RoomRecord = {
  id: number
  description: string
  createdAt: Date
  updatedAt: Date
  room: IRoom
  user: IUser
}
