import { faCalendar } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { IExamDashboardApplication } from '../../types'
import { formatDateTimeString } from '../../utils/date'

type NextTimeWindowProps = {
  className?: string
  application?: IExamDashboardApplication
}

const NextTimeWindow = ({ className, application }: NextTimeWindowProps) => {
  const { t } = useTranslation()

  // if the user already started the application,
  // relay the rendering of this information
  // to the card footer
  if (application?.startedAt) {
    return null
  }

  const getClosestTimeWindow = () => {
    const timeWindow = application?.timeWindows?.length
      ? application.timeWindows
      : application?.exam.timeWindows
    if (!timeWindow || !timeWindow.length) {
      return t('No time window available')
    }

    // safe get at position zero since we checked previously
    const closestTimeWindow = timeWindow.sort((a, b) => {
      const aDate = new Date(a.startTime)
      const bDate = new Date(b.startTime)
      return aDate.getTime() - bDate.getTime()
    })[0]

    return (
      formatDateTimeString(closestTimeWindow.startTime) +
      ' - ' +
      formatDateTimeString(closestTimeWindow.endTime)
    )
  }

  return (
    <span className={className}>
      <FontAwesomeIcon
        className="icon-space"
        icon={faCalendar}
      ></FontAwesomeIcon>
      {getClosestTimeWindow()}
    </span>
  )
}

export default styled(NextTimeWindow)`
  .icon-space {
    margin-right: 10px;
  }
`
