import axios from 'axios'
import React, { createContext, ReactNode, useEffect, useReducer } from 'react'
import { API_HOST } from '../consts'
import {
  childrenIsFunction,
  IIndividualReportCardState,
  IndividualReportCardStateAction
} from '../types'
import { useHistory } from 'react-router-dom'

type IndividualReportCardStateProps = {
  children: ReactNode
  IndividualReportCardId: string | undefined
}

const reducer = (
  state: IIndividualReportCardState,
  action: IndividualReportCardStateAction
): IIndividualReportCardState => {
  switch (action.type) {
    case 'FETCH_INDIVIDUAL_REPORT_CARD':
      return {
        ...state,
        fetchingIndividualReportCard: true,
        fetchIndividualReportCardError: ''
      }
    case 'FETCH_INDIVIDUAL_REPORT_CARD_SUCCESS':
      return {
        ...state,
        individualReportCard: action.payload,
        fetchingIndividualReportCard: false,
        fetchIndividualReportCardError: ''
      }
    case 'FETCH_INDIVIDUAL_REPORT_CARD_ERROR':
      return {
        ...state,
        fetchingIndividualReportCard: false,
        fetchIndividualReportCardError: action.payload
      }
    default:
      return state
  }
}

const initialState: IIndividualReportCardState = {
  individualReportCard: undefined,
  fetchingIndividualReportCard: false,
  fetchIndividualReportCardError: ''
}

export const IndividualReportCardContext = createContext<
  IIndividualReportCardState
>(initialState)

const IndividualReportCardState = ({
  children,
  IndividualReportCardId
}: IndividualReportCardStateProps) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const history = useHistory()

  useEffect(() => {
    ;(async () => {
      dispatch({ type: 'FETCH_INDIVIDUAL_REPORT_CARD' })
      try {
        const response = await axios.get(
          `${API_HOST}/v1/individual-collections/${IndividualReportCardId}`
        )
        dispatch({
          type: 'FETCH_INDIVIDUAL_REPORT_CARD_SUCCESS',
          payload: response.data
        })
      } catch (e) {
        dispatch({ type: 'FETCH_INDIVIDUAL_REPORT_CARD_ERROR', payload: e })
        history.push('/error')
      }
    })()
  }, [IndividualReportCardId, history])

  const contextValue = {
    ...state,
    dispatch
  }
  return (
    <IndividualReportCardContext.Provider value={contextValue}>
      {childrenIsFunction(children) ? children(contextValue) : children}
    </IndividualReportCardContext.Provider>
  )
}

export default IndividualReportCardState
