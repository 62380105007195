import React, { createContext } from 'react'
import { API_HOST } from '../consts'
import useList, { initialState as useListInitialState } from '../hooks/useList'

type EmailRecipientListStateProps = {
  children: Function
}

const initialState = useListInitialState

export const EmailRecipientListContext = createContext(initialState)

export const EmailRecipientListState = ({
  children
}: EmailRecipientListStateProps) => {
  const state = useList({
    api: `${API_HOST}/v1/users`,
    defaultPageSize: 10,
    defaultOrdering: 'user__name'
  })

  return (
    <EmailRecipientListContext.Provider value={state}>
      {children(state)}
    </EmailRecipientListContext.Provider>
  )
}

export default EmailRecipientListState
