import AgoraRTC from 'agora-rtc-sdk'
import React, { useEffect, useState } from 'react'
import { AGORA_API_KEY } from '../../consts'
import { IExamDashboardApplicationUser } from '../../types'
import { RollbarErrorTracking } from '../../utils/rollbar'
import VideoVolume from './VideoVolume'

type StreamingWrapperProps = {
  user: IExamDashboardApplicationUser
}

const StreamingWrapper = ({ user }: StreamingWrapperProps) => {
  const client = AgoraRTC.createClient({ mode: 'live', codec: 'h264' })
  const [currentStream, setCurrentStream] = useState(undefined)
  let channelName = ''
  if (user) {
    channelName = `${user.provider.codename}_${user.id}`
  }

  const onStreamAdded = (obj) => {
    const stream = obj.stream
    const currentVideo = document.getElementById(`${channelName}_container`)
    if (currentVideo && currentVideo.innerHTML) {
    } else {
      client.subscribe(stream)
      setCurrentStream(stream)
      stream.play(`${channelName}_container`)
      stream.muteAudio()
    }
  }

  const onPeerLeave = (obj) => {
    const stream = obj.stream
    client.unsubscribe(stream)
    const currentVideo = document.getElementById(`${channelName}_container`)
    if (currentVideo) {
      currentVideo.innerHTML = ''
    }
  }

  const onToggleMute = (muted: boolean) => {
    if (currentStream === undefined) {
      return
    }

    if (muted) {
      currentStream.muteAudio()
    } else {
      currentStream.unmuteAudio()
    }
  }

  useEffect(() => {
    if (!AGORA_API_KEY) {
      return
    }
    client.init(AGORA_API_KEY, () => {
      client.join(
        null,
        channelName,
        null,
        () => {
          client.on('stream-added', onStreamAdded)
          client.on('peer-leave', onPeerLeave)
          client.on('stream-removed', onPeerLeave)
        },
        (e) => {
          RollbarErrorTracking.LogError(e)
        }
      )
    })

    return () => {
      try {
        client.unsubscribe(currentStream)
        client.leave(channelName)
      } catch (e) {
        RollbarErrorTracking.LogError(e)
      }
    }
  }, [user])

  return (
    <div
      id={channelName}
      style={{ width: '100%', height: '100%', position: 'relative' }}
    >
      <VideoVolume
        onToggleMute={onToggleMute}
        style={{ position: 'absolute', right: 5, top: 5, zIndex: 1 }}
      />
      <div
        id={`${channelName}_container`}
        style={{ width: '100%', height: '100%' }}
      />
    </div>
  )
}

export default StreamingWrapper
