import React, { useState } from 'react'
import { faVolumeUp, faVolumeMute } from '@fortawesome/free-solid-svg-icons'
import styled from 'styled-components'
import { CSSProperties } from '@material-ui/styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

type VideoVolumeProps = {
  className?: string
  style?: CSSProperties
  onToggleMute: (muted: boolean) => void
}

const VideoVolume = ({ className, style, onToggleMute }: VideoVolumeProps) => {
  const [muted, setMuted] = useState(true)

  return (
    <div className={className}>
      <FontAwesomeIcon
        style={style}
        className="icon"
        onClick={() => {
          setMuted(!muted)
          onToggleMute(!muted)
        }}
        icon={muted ? faVolumeMute : faVolumeUp}
      />
    </div>
  )
}

export default styled(VideoVolume)`
  cursor: pointer;
  color: white;

  .icon {
    border-radius: 50%;
    background: #9d9d9d;
    height: 1.5rem;
    width: 1.5rem;
    padding: 5px;
  }
`
