import React from 'react'
import Skeleton from 'react-loading-skeleton'
import styled from 'styled-components'
import CandidateVideo from '../components/ExamDashboardComponents/CandidateVideo'
import DashboardVideosSocketState from '../states/DashboardVideosSocketState'
import DashboardVideosState from '../states/DashboardVideosState'
import { IDashboardVideosState } from '../types'

type DashboardVideosProps = {
  className?: string
  roomId: number
}

const DashboardVideos = ({ className, roomId }: DashboardVideosProps) => {
  return (
    <div>
      {roomId > 0 && (
        <DashboardVideosSocketState>
          <DashboardVideosState roomId={roomId}>
            {({ fetching, applications }: IDashboardVideosState) => {
              if (fetching) {
                return <Skeleton />
              }
              const addedCandidates = {}
              const videos = applications.map((application) => {
                if (addedCandidates[application.user.name]) {
                  return null
                }

                addedCandidates[application.user.name] = true
                return (
                  <CandidateVideo
                    key={application.id}
                    application={application}
                  ></CandidateVideo>
                )
              })
              return <div className={className}>{videos}</div>
            }}
          </DashboardVideosState>
        </DashboardVideosSocketState>
      )}
    </div>
  )
}

export default styled(DashboardVideos)`
  height: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
`
