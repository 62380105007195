import { BarChart } from 'd3plus-plot'
import React, { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Skeleton from 'react-loading-skeleton'
import styled, { ThemeContext } from 'styled-components'
import { IndividualReportCardContext } from '../../states/IndividualReportCardState'

type ComparativeChartProps = {
  className?: string
}

interface IChartData {
  id: string
  x: string
  y: number
  color: string
}

const ComparativeChart = ({ className }: ComparativeChartProps) => {
  const { individualReportCard, fetchingIndividualReportCard } = useContext(
    IndividualReportCardContext
  )
  const { t } = useTranslation()
  const theme = useContext(ThemeContext)

  useEffect(() => {
    const data: IChartData[] = []

    if (!individualReportCard) {
      return
    }
    individualReportCard.exams.forEach((e, i) => {
      let currentItem = i + 1
      // Add class grade
      data.push({
        id: t('Students overall score'),
        x: `P${currentItem}`,
        y: e.classGrade,
        color: 'neutral'
      })
      // Add user grade
      data.push({
        id: t('You'),
        x: `P${currentItem}`,
        y: e.generalGrade,
        color: 'principal'
      })
    })

    new BarChart()
      .select('.chart')
      .data(data)
      .groupBy('id')
      .groupPadding(40)
      .height(400)
      .shapeConfig({
        label: false,
        fill: function (d) {
          return theme.reportColors[d.color]
        },
        width: 30
      })
      .tooltipConfig({
        title: function (d) {
          return `${d.id}: ${d.y}`
        }
      })
      .xConfig({
        title: t('Exam')
      })
      .yConfig({
        title: t('Scores quantity')
      })
      .render()
  }, [individualReportCard, t, theme.reportColors])

  const renderChartLegend = () => {
    if (!individualReportCard) {
      return
    }
    return (
      <div
        style={{ backgroundColor: '#F5F5F5', padding: '3px 20px 10px 20px' }}
      >
        <h2>{t('Chart legend')}</h2>
        {individualReportCard.exams.map((e, i) => (
          <p style={{ fontSize: '12px', lineHeight: '10px' }}>
            <strong>• P{i + 1}:</strong> {e.exam.name}
          </p>
        ))}
      </div>
    )
  }

  return (
    <>
      <h2>{t('Comparative Chart')}</h2>
      {fetchingIndividualReportCard && <Skeleton height={500} />}
      <div className={`${className} chart`} />
      {fetchingIndividualReportCard ? (
        <Skeleton height={500} />
      ) : (
        <div className={`${className}`}>{renderChartLegend()}</div>
      )}
    </>
  )
}

export default styled(ComparativeChart)`
  max-width: 75%;
  margin-left: auto;
  margin-right: auto;
`
