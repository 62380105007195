import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import styled, { ThemeContext } from 'styled-components'
import Button from '../components/Button'
import Page from '../components/Page'

type ErrorProps = {
  className?: string
}

const Error = ({ className }: ErrorProps) => {
  const { t } = useTranslation()
  const history = useHistory()
  const theme = useContext(ThemeContext)

  return (
    <Page title={t('Ops!')} className={className}>
      <p>{t('An unexpected error occured.')}</p>
      <img alt="error" src={theme.errorImg} />
      <Button action="primary" onClick={() => history.push('/applications')}>
        {t('RETURN TO ORIGINAL PAGE')}
      </Button>
    </Page>
  )
}

export default styled(Error)`
  text-align: center;
  h1 {
    font-weight: 600;
    color: ${(props) => props.theme.clientColors.text};
  }

  img {
    width: 80%;
    max-width: 400px;
    margin: auto;
    margin-top: 2rem;
    display: block;
  }

  ${Button} {
    margin-top: 2rem;
  }
`
