import { FormControlLabel, Radio } from '@material-ui/core'
import React, { ReactNode } from 'react'

type RadioInputProps = {
  value: string
  label: ReactNode
}

const RadioInput = ({ value, label }: RadioInputProps) => (
  <FormControlLabel
    value={value}
    control={<Radio color="primary" />}
    label={label}
  />
)

export default RadioInput
