import Axios from 'axios'
import { useCallback, useContext, useEffect, useState } from 'react'
import { API_HOST } from '../consts'
import { AuthContext } from '../states/AuthState'
import { IHierarchyCategory } from '../types'

const useHierarchyCategories = () => {
  const { user } = useContext(AuthContext)
  const providerCodename = user?.provider?.codename
  const [hierarchyCategories, setHierarchyCategories] = useState<
    IHierarchyCategory[]
  >([])

  const fetchHierarchyCategories = useCallback(async () => {
    try {
      const response = await Axios.get<IHierarchyCategory[]>(
        `${API_HOST}/v1/provider/${providerCodename}/hierarchy_category`
      )
      setHierarchyCategories(
        response.data.map((category) => ({
          ...category,
          parent: response.data.find((c) => c.id === category.parentId)
        }))
      )
    } catch (_) {}
  }, [providerCodename])

  useEffect(() => {
    fetchHierarchyCategories()
  }, [fetchHierarchyCategories])

  return [hierarchyCategories]
}

export default useHierarchyCategories
