import { IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import styled from 'styled-components'

type BadgeProps = {
  className?: string
  type: 'positive' | 'negative'
}

interface IIconType {
  positive: [IconPrefix, IconName]
  negative: [IconPrefix, IconName]
}

interface IIconColor {
  positive: string
  negative: string
}

const iconType: IIconType = {
  positive: ['fas', 'thumbs-up'],
  negative: ['fas', 'thumbs-down']
}

const Badge = ({ className, type = 'positive' }: BadgeProps) => (
  <FontAwesomeIcon className={className} icon={iconType[type]} />
)

export default styled(Badge)`
  border-radius: 50%;
  padding: 10px;
  background-color: ${(props) => props.theme.reportColors[props.type]};
  color: white;
`
