import { faInfo } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { DashboardVideosSocketContext } from '../../states/DashboardVideosSocketState'
import { ExamStatus, IExamDashboardApplication } from '../../types'
import ToggleMenuButton from '../Buttons/ToggleMenuButton'

type ExamInfoIndicatorProps = {
  className?: string
  status: string
  application: IExamDashboardApplication
  refreshInterval?: number
}

const ExamInfoIndicator = ({
  className,
  status,
  application,
  refreshInterval = 5000
}: ExamInfoIndicatorProps) => {
  const { t } = useTranslation()
  const { getExamStatus } = useContext(DashboardVideosSocketContext)
  const isOffline = status === 'offline'
  const [examStatus, setExamStatus] = useState<ExamStatus>(null)

  const getIconClass = () => {
    if (isOffline) {
      return 'disabled'
    }

    return ''
  }

  const refreshExamStatus = useCallback(async () => {
    const examStatus = await getExamStatus(application.user.id)
    setExamStatus(examStatus)
  }, [setExamStatus, getExamStatus, application.user.id])

  useEffect(() => {
    const interval = setInterval(() => refreshExamStatus(), refreshInterval)

    return () => {
      clearInterval(interval)
    }
  }, [refreshExamStatus, refreshInterval])

  return (
    <div className={className}>
      <ToggleMenuButton
        action="unstyled"
        className={`button ${getIconClass()}`}
        icon={faInfo}
      >
        <div className="toggle-content">
          {status === 'online' && (
            <div className="content-item">
              {t('Exam started')}:{' '}
              {examStatus
                ? moment(examStatus.examStartDate).fromNow()
                : t('Loading...')}
            </div>
          )}
          {status === 'finished' && (
            <div className="content-item">{t('Exam finished')}</div>
          )}
          {status !== 'finished' && (
            <div className="content-item">
              {t('Total answered')}:{' '}
              {application.grades.correct + application.grades.wrong}
            </div>
          )}
          {['online', 'finished'].includes(status) && (
            <div className="content-item">
              {status === 'finished'
                ? t('Last item acessed')
                : t('Current item')}
              : {examStatus ? examStatus.currentQuestion : t('Loading...')}
            </div>
          )}
          {status !== 'finished' && status !== 'online' && (
            <div className="content-item">
              {t('This candidate is not currently on the exam')}
            </div>
          )}
        </div>
      </ToggleMenuButton>
    </div>
  )
}

export default styled(ExamInfoIndicator)`
  .button {
    height: 0.7rem;
    width: 0.7rem;
    border: 2px solid ${(props) => props.theme.colors.text};
    border-radius: 50%;
    padding: 2px;
    transition: all 0.5s;

    &:hover {
      box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
      background-color: #f5f5f5;
    }

    &.disabled {
      border-color: ${(props) => props.theme.colors.grayMedium};
    }

    &.paused {
      border-color: #d2cc45;
      color: #d2cc45;
    }
  }

  .toggle-content {
    display: grid;
  }

  .content-item {
    font-size: 10px;
  }
`
