import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import Input from '../Formik/Inputs/Input'
import HierarchyFilter from '../HierarchyFilter'
import HierarchyInputAsync from '../Inputs/HierarchyInputAsync'

type NewStudentFormProps = {
  providerCodename?: string
  className?: string
}

const NewStudentForm = ({
  providerCodename,
  className
}: NewStudentFormProps) => {
  const { t } = useTranslation()

  return (
    <form className={className}>
      <Input required label={t('Name')} name="name" />
      <div className="grid">
        <Input label={t('Email')} name="email" />
        <Input
          required
          label={t('Public Identifier')}
          name="public_identifier"
        />
        <HierarchyFilter distinct={false} providerCodename={providerCodename} />
      </div>
    </form>
  )
}

export default styled(NewStudentForm)`
  ${Input}, ${HierarchyInputAsync} {
    margin-bottom: 1rem;
  }

  .grid {
    display: grid;
    grid-template-columns: calc(50% - 0.5rem) calc(50% - 0.5rem);
    column-gap: 1rem;
  }
`
