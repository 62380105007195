import React from 'react'
import { Redirect } from 'react-router-dom'
import { getUserGroups } from '../utils/auth'

// TODO: Remove this on create login
const UserRedirect = () => {
  const userGroups = getUserGroups()

  if (
    userGroups.includes('ADMINISTRADOR') ||
    userGroups.includes('PROCTOR') ||
    userGroups.includes('COORDINATOR')
  ) {
    return <Redirect to="/exams/dashboard" />
  }

  return <Redirect to="/applications" />
}

export default UserRedirect
