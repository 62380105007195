import styled from 'styled-components'

const Tag = styled.span`
  background-color: ${(props) => props.color};
  padding: 5px 1rem;
  font-size: 14px;
  border-radius: 4px;
  color: white;
`

export default Tag
