import { groupBy, max, sortBy } from 'lodash'
import React, { createContext, useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { API_HOST, VIDEO_RECORDING_ENABLED } from '../consts'
import useList from '../hooks/useList'
import useQuery from '../hooks/useQuery'
import { IApplication, IApplicationConfiguration } from '../types'
import { VideoStreamingContext } from './VideoStreamingState'
import axios from 'axios'
import { ApplicationSocketContext } from './ApplicationSocketState'

type ApplicationListStateProps = {
  children: Function
}

const getApplicationsByCollection = (applications: IApplication[]) => {
  const sortedApplications = sortBy(
    applications,
    (d: IApplication) => d.exam.collection.id
  )
  const applicationsByCollection: {
    [key: number]: IApplication[]
  } = groupBy(sortedApplications, (d: IApplication) => d.exam.collection.id)
  return applicationsByCollection
}

const mapApplicationsByCollection = (applications: IApplication[]) => {
  const application = applications[0]

  // Gets max finish date
  let maxFinishDate: string | undefined
  const finishedAll = applications.every((d) => d.finishedAt)
  if (finishedAll) {
    const finishDates = applications.map((d) => d.finishedAt)
    maxFinishDate = max(finishDates)
  }

  // Gets status
  const hasStartedApplication = applications.some((d) => d.status === 'STARTED')
  const status = hasStartedApplication ? 'STARTED' : application.status

  // Gets title and instructions
  let title: string
  let instructionsUrl: string

  // ### REQUESTED BY PRISCILLA IN 04/2020 ###
  // If the collection has only one application,
  // the system should redirect straight to
  // the instructions page.
  if (applications.length > 1) {
    const { collection } = application.exam
    title = collection.name
    instructionsUrl = `/applications?collection=${collection.id}`
  } else {
    // If collection has only one application, goes straight to
    // application instructions
    title = application.exam.name
    instructionsUrl = `/applications/${application.id}`
  }

  return {
    ...application,
    finishedAt: maxFinishDate,
    title,
    instructionsUrl,
    status,
    secondsToTimeoutWhenLoaded: application.secondsToTimeout
  }
}

const sortApplicationsByStatus = (applications: IApplication[]) => {
  return applications.sort((a, b) => {
    // Applications should be displayed in the following order
    const priority = [
      'STARTED',
      'AVAILABLE',
      'FINISHED',
      'STOPPED',
      'UNAVAILABLE',
      'UNKNOWN'
    ]

    const aPriority = priority.findIndex((d) => d === a.status)
    const bPriority = priority.findIndex((d) => d === b.status)
    return aPriority - bPriority
  })
}

const filterByCollection = (
  applications: IApplication[],
  collectionId: number
) => {
  return applications.filter((d) => d.exam.collection.id === collectionId)
}

const requiresVideo = async (applications: any) => {
  const configurationMapping = {}
  for (const application of applications) {
    const applicationConfigurationId =
      application.exam.collection.applicationConfiguration
    if (!applicationConfigurationId) {
      continue
    }

    if (configurationMapping[applicationConfigurationId]) {
      continue
    }

    try {
      const result = await axios.get(
        `${API_HOST}/v1/application_configuration/${applicationConfigurationId}`
      )
      const configuration = result.data as IApplicationConfiguration
      configurationMapping[applicationConfigurationId] = configuration
      if (configuration.requiresVideo) {
        return true
      }
    } catch (e) {
      configurationMapping[applicationConfigurationId] = {}
    }
  }
  return false
}

export const ApplicationListContext = createContext({})

export const ApplicationListState = ({
  children
}: ApplicationListStateProps) => {
  const state = useList({
    api: `${API_HOST}/v1/applications`,
    fetchInterval: 10000,
    defaultPageSize: 200
  })
  const history = useHistory()
  const query = useQuery()
  const collectionId = +(query.get('collection') || '')

  const { startStreaming, permissionsAreOk } = useContext(VideoStreamingContext)

  const { setCameraAccepted, joinRoomAsCandidate } = useContext(
    ApplicationSocketContext
  )

  let applications
  if (collectionId) {
    applications = filterByCollection(state.results, collectionId).map((d) => ({
      ...d,
      title: d.exam.name,
      instructionsUrl: `/applications/${d.id}`,
      secondsToTimeoutWhenLoaded: d.secondsToTimeout
    }))
  } else {
    const applicationsByCollection = getApplicationsByCollection(state.results)
    applications = Object.values(applicationsByCollection).map(
      mapApplicationsByCollection
    )
  }

  const checkVideo = async () => {
    const isRequired = await requiresVideo(applications)
    if (isRequired) {
      startStreaming()
    }

    const permissionsOk = await permissionsAreOk()
    if (permissionsOk) {
      setCameraAccepted(true)
    } else {
      setCameraAccepted(false)
    }

    if (VIDEO_RECORDING_ENABLED && applications && applications.length) {
      const first = applications[0]
      joinRoomAsCandidate(first.roomId)
    }
  }

  useEffect(() => {
    checkVideo()
    if (collectionId && applications.length === 1) {
      history.push('/applications')
    }
  }, [applications, collectionId, history, checkVideo])

  applications = sortApplicationsByStatus(applications)

  const value = {
    ...state,
    results: applications,
    collectionId
  }

  return (
    <ApplicationListContext.Provider value={value}>
      {children(value)}
    </ApplicationListContext.Provider>
  )
}

export default ApplicationListState
