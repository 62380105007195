import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { IAnswer } from 'types'

type AnswerCountdownProps = {
  className?: string
  secondsToCountdown: number
  onFinish: () => void
  itemId: number
  answer: IAnswer
}

const AnswerCountdown = ({
  className,
  secondsToCountdown,
  onFinish,
  itemId,
  answer
}: AnswerCountdownProps) => {
  const initialsecondsToCountdown =
    secondsToCountdown - answer.seconds >= 0
      ? secondsToCountdown - answer.seconds
      : 0

  const { t } = useTranslation()
  const [formattedRemainingTime, setFormattedRemainingTime] = useState(
    initialsecondsToCountdown
  )
  const [dangerClass, setDangerClass] = useState('')

  useEffect(() => {
    setFormattedRemainingTime(initialsecondsToCountdown)
  }, [itemId, secondsToCountdown])

  useEffect(() => {
    const intervalId = setInterval(() => {
      const remaining = formattedRemainingTime - 1

      if (remaining <= 0) {
        clearInterval(intervalId)
        onFinish()
        if (remaining === 0) {
          setFormattedRemainingTime(remaining)
        }
        return
      }

      setFormattedRemainingTime(remaining)
    }, 1000)

    return () => {
      clearInterval(intervalId)
    }
  }, [
    formattedRemainingTime,
    setFormattedRemainingTime,
    dangerClass,
    setDangerClass,
    onFinish
  ])

  return (
    <div className={className}>
      <p>
        {t('Question remaining time')}:{' '}
        <span className={formattedRemainingTime <= 10 && 'near-end'}>
          {formattedRemainingTime} {t('seconds')}
        </span>
      </p>
    </div>
  )
}

export default styled(AnswerCountdown)`
    text-align: right;

    p {
        font-size: 12pt
        font-weight: bold;
        color: #32506F
    }

    .near-end {
        animation: blink 1s infinite;
        color: red;
        font-size: 15px;
    }
`
