import moment from 'moment'
import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css'
import './App.css'
import ErrorBoundaryRollbar from './components/ErrorBoundaryRollbar'
import ExamDashboardHeader from './components/ExamDashboardComponents/Header'
import PrivateRoute from './components/PrivateRoute'
import StyledToastContainer from './components/StyledToastContainer'
import UserRedirect from './components/UserRedirect'
import useAnswerSync from './hooks/useAnswerSync'
import useGoogleAnalytics from './hooks/useGoogleAnalytics'
import Application from './pages/Application'
import ApplicationList from './pages/ApplicationList'
import BrowserCompatibility from './pages/BrowserCompatibility'
import Error from './pages/Error'
import CameraPermission from './pages/CameraPermission'
import GradesDownload from './pages/Grades/Download'
import ImportExamPage from './pages/ImportExamPage'
import ImportStudentPage from './pages/ImportStudentPage'
import IndividualReportCard from './pages/IndividualReportCard'
import IndividualReportCardList from './pages/IndividualReportCardList'
import EmailRecipientList from './pages/EmailRecipientList'
import EmailRecipientFormPage from './pages/EmailRecipientFormPage'
import Login from './pages/Login'
import ProviderLogin from './pages/ProviderLogin'
import './register-fa-icons'
import AuthState from './states/AuthState'
import ConfigState from './states/ConfigState'
import ModalState from './states/ModalState'
import NetworkState from './states/NetworkState'
import DefaultTheme from './themes/DefaultTheme'
import DashboardPreFilter from 'pages/DashboardPreFilter'
import VideoStreamingState from 'states/VideoStreamingState'
import { COORDINATOR_ALLOWED_IN_DASHBOARD } from 'consts'

function Routes() {
  useGoogleAnalytics()
  useAnswerSync()

  moment.locale('pt-BR')

  const dashboardPermissions = ['ADMINISTRADOR', 'PROCTOR']
  if (COORDINATOR_ALLOWED_IN_DASHBOARD) {
    dashboardPermissions.push('COORDINATOR')
  }

  return (
    <Switch>
      <Route path="/login" component={Login} />
      <PrivateRoute exact path="/" render={() => <UserRedirect />} />
      <PrivateRoute exact path="/applications" component={ApplicationList} />
      <PrivateRoute
        path="/applications/:applicationId(\d+)"
        component={Application}
      />
      <PrivateRoute
        path="/exams/dashboard"
        component={ExamDashboardHeader}
        allowedGroups={dashboardPermissions}
      />
      <PrivateRoute
        path="/exams/dashboard-pre-filter"
        component={DashboardPreFilter}
        allowedGroups={['ADMINISTRADOR', 'COORDINATOR', 'PROCTOR']}
      />
      <PrivateRoute
        path="/report-cards/individual/:reportCardId(\d+)"
        component={IndividualReportCard}
        allowedGroups={['ADMINISTRADOR', 'ALUNO', 'COORDINATOR']}
      />
      <PrivateRoute
        path="/report-cards/individual"
        component={IndividualReportCardList}
        allowedGroups={['ADMINISTRADOR', 'COORDINATOR']}
      />
      <PrivateRoute
        path="/grades/download"
        component={GradesDownload}
        allowedGroups={['ADMINISTRADOR', 'COORDINATOR']}
      />
      <PrivateRoute
        path="/imports/exam"
        component={ImportExamPage}
        allowedGroups={['ADMINISTRADOR']}
      />
      <PrivateRoute
        path="/imports/students"
        component={ImportStudentPage}
        allowedGroups={['ADMINISTRADOR']}
      />
      <PrivateRoute
        path="/camera-permission"
        component={CameraPermission}
        allowedGroups={['ADMINISTRADOR', 'ALUNO', 'COORDINATOR']}
      />
      <PrivateRoute
        exact
        path="/emails/recipients"
        component={EmailRecipientList}
        allowedGroups={['ADMINISTRADOR']}
      />
      <PrivateRoute
        path="/emails/recipients/form"
        component={EmailRecipientFormPage}
        allowedGroups={['ADMINISTRADOR']}
      />
      <Route
        path="/external-auth/:provider/token/:token"
        exact
        component={ProviderLogin}
      />
      <Route path="*" component={Error} />
    </Switch>
  )
}

export type AppProps = {
  browserIsCompatible: boolean | number
}

const App = ({ browserIsCompatible }: AppProps) => {
  if (browserIsCompatible) {
    return (
      <ErrorBoundaryRollbar>
        <BrowserRouter>
          <ConfigState>
            <AuthState>
              {() => (
                <>
                  <DefaultTheme>
                    <VideoStreamingState>
                      <ModalState>
                        <NetworkState>
                          <>
                            <StyledToastContainer autoClose={8000} />
                            <Routes />
                          </>
                        </NetworkState>
                      </ModalState>
                    </VideoStreamingState>
                  </DefaultTheme>
                </>
              )}
            </AuthState>
          </ConfigState>
        </BrowserRouter>
      </ErrorBoundaryRollbar>
    )
  } else {
    return (
      <ErrorBoundaryRollbar>
        <DefaultTheme>
          <BrowserCompatibility />
        </DefaultTheme>
      </ErrorBoundaryRollbar>
    )
  }
}

export default App
