import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { ButtonHTMLAttributes, ReactNode } from 'react'
import Button from './Button'

interface CardButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  icon: IconProp
  children?: ReactNode
}

const CardButton = ({ icon, children, ...rest }: CardButtonProps) => (
  <Button action="icon" type="button" {...rest}>
    <FontAwesomeIcon icon={icon} /> <span>{children}</span>
  </Button>
)

export default CardButton
