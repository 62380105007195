import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useContext, useState } from 'react'
import Countdown from 'react-countdown'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { ApplicationContext } from '../states/ApplicationState'
import { ConfigContext } from '../states/ConfigState'
import Button from './Button'

type RemainingTimeProps = {
  className?: string
  secondsToTimeout: number | undefined
  initial: number | undefined
  allowHideRemainingTime?: boolean
}

const RemainingTime = ({
  className,
  initial,
  secondsToTimeout,
  allowHideRemainingTime = true
}: RemainingTimeProps) => {
  const [hide, setHide] = useState(false)
  const { showSecondsInRemainingTime } = useContext(ConfigContext)
  const { handleTimeout } = useContext(ApplicationContext)
  const { t } = useTranslation()

  if (secondsToTimeout === undefined || !initial) {
    return (
      <span className={className}>
        <span>{t('Unavailable')}</span>
      </span>
    )
  }

  return (
    <Countdown
      date={initial + secondsToTimeout * 1000}
      onComplete={handleTimeout}
      renderer={({ ...time }) => {
        let label = ''

        if (time.days) {
          label += `${time.days}d `
        }

        label += time.hours.toString().padStart(2, '0')
        label += ':'
        label += time.minutes.toString().padStart(2, '0')

        if (showSecondsInRemainingTime) {
          label += ':'
          label += time.seconds.toString().padStart(2, '0')
        }

        const alertClass =
          time.minutes <= 10 && time.hours === 0 && time.days === 0
        const warningClass =
          time.minutes <= 2 && time.hours === 0 && time.days === 0

        return (
          <span className={className}>
            <span
              className={`${alertClass && 'alert'}${
                warningClass ? '-blink' : ''
              }`}
            >
              {hide ? '-' : label}
            </span>
            {allowHideRemainingTime && (
              <Button action="icon" onClick={() => setHide(!hide)}>
                <FontAwesomeIcon icon={hide ? 'eye' : 'eye-slash'} />
              </Button>
            )}
          </span>
        )
      }}
    />
  )
}

export default styled(RemainingTime)`
  color: ${(props) => props.theme.colors.grayDark};

  .alert {
    color: red;

    &-blink {
      color: red;
      animation: blink 1s infinite;
    }
  }

  @keyframes blink {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  ${Button} {
    margin-left: 10px;
  }
`
