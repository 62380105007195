import { Formik, FieldArray } from 'formik'
import { get, isEmpty } from 'lodash'
import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Skeleton from 'react-loading-skeleton'
import styled from 'styled-components'
import CardList from '../components/CardList'
import Button from '../components/Button'
import HierarchyFilterBox from '../components/HierarchyFilterBox'
import ListPage from '../components/ListPage'
import PaginationFooter from '../components/PaginationFooter'
import RecipientCard from '../components/Email/RecipientCard'
import Stripe from '../components/Stripe'
import { AuthContext } from '../states/AuthState'
import EmailRecipientListState from '../states/EmailRecipientListState'
import { FormValues, IHierarchy } from 'types'

type EmailRecipientListProps = {
  className?: string
}

const EmailRecipientList = ({ className }: EmailRecipientListProps) => {
  const { t } = useTranslation()
  const { user } = useContext(AuthContext)
  const history = useHistory()
  const formInitialValues: FormValues = {
    collection: undefined
  }

  const mapHierarchy = (hierarchy: {
    [key: string]: IHierarchy | undefined
  }) => {
    // Maps hierarchy filter to how the api expects
    if (!hierarchy) {
      return undefined
    }

    const value = Object.entries(hierarchy)
      .filter((entry) => entry[1])
      .reduce(
        (acc, entry) => ({
          ...acc,
          [entry[0]]: { name: get(entry[1], 'name') }
        }),
        {}
      )

    // If no value selected, returns undefined
    // so it is not added in query string by axios
    return isEmpty(value) ? undefined : value
  }

  const mapFilters = (values: FormValues) => {
    // Maps filters from how they are saved in form to how the api expects
    const { collection, user, ...hierarchy } = values
    return {
      collection: get(collection, 'id'),
      user: get(user, 'id'),
      hierarchy: mapHierarchy(hierarchy)
    }
  }

  return (
    <EmailRecipientListState>
      {({
        results,
        numPages,
        handleFilter,
        handlePageChange,
        handlePageSizeChange,
        count,
        isLoading,
        pageSize
      }) => (
        <div className={className}>
          <Stripe title={t('Select the recipients')} />
          <ListPage>
            <Formik
              initialValues={formInitialValues}
              onSubmit={(values) => handleFilter(mapFilters(values))}
            >
              {(formik) => (
                <>
                  <HierarchyFilterBox
                    providerCodename={user?.provider?.codename}
                  />
                  <div>
                    <CardList>
                      {isLoading ? (
                        <Skeleton height={110} count={pageSize} />
                      ) : (
                        <FieldArray
                          name="users"
                          render={() => (
                            <div>
                              {results.map((user) => (
                                <RecipientCard key={user.id} user={user} />
                              ))}
                            </div>
                          )}
                        />
                      )}
                    </CardList>
                    <PaginationFooter
                      pageSize={pageSize}
                      pageCount={numPages}
                      onPageChange={handlePageChange}
                      onPageSizeChange={handlePageSizeChange}
                    >
                      <p>
                        Mostrando {results.length} de {count} registros.
                      </p>
                    </PaginationFooter>
                    <div className="button-div">
                      <Button
                        onClick={() =>
                          history.push({
                            pathname: '/emails/recipients/form',
                            state: {
                              users: get(formik, 'values.users', []).filter(
                                (value) => value
                              )
                            }
                          })
                        }
                        action="primary"
                      >
                        Enviar emails
                      </Button>
                    </div>
                  </div>
                </>
              )}
            </Formik>
          </ListPage>
        </div>
      )}
    </EmailRecipientListState>
  )
}

export default styled(EmailRecipientList)`
  .button-div {
    float: right;
  }
`
