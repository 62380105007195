import { createContext } from 'react'
import { initialState as useListInitialState } from '../hooks/useList'
import { IExamDashboardState } from '../types'

export const initialState = {
  applications: [],
  exam: undefined,
  fetchingExam: false,
  fetchingApplications: false,
  fetchApplicationsError: '',
  fetchExamError: '',
  search: '',
  filterParams: undefined,
  totalApplications: undefined,
  onSearchChange: () => undefined,
  onPageSizeChange: () => undefined,
  onPageChange: () => undefined,
  onFilter: () => undefined,
  setBulkTimeWindow: () => Promise.resolve(),
  appliedFilters: {},
  getVerboseFilters: () => [],
  fetchApplications: () => Promise.resolve(),
  ...useListInitialState
}

const ExamDashboardContext = createContext<IExamDashboardState>(initialState)

export default ExamDashboardContext
