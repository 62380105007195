import React, {
  createContext,
  useReducer,
  ReactElement,
  useCallback,
  useContext,
  useEffect
} from 'react'

import { VIDEO_RECORDING_ENABLED } from '../consts'
import videoManager from '../fileChunkUploader'
import { isAuthenticated } from '../utils/auth'
import { AuthContext } from './AuthState'
import {
  IVideoStreamingState,
  childrenIsFunction,
  VideoStreamingStateAction
} from '../types'
import Modal from '../components/Modal/Modal'
import { useTranslation } from 'react-i18next'
import withReactContent from 'sweetalert2-react-content'
import swal from 'sweetalert2'
import { useHistory } from 'react-router-dom'
import streamingManager from 'videoStreaming'

type VideoStreamingStateProps = {
  children: ReactElement
}

const reducer = (
  state: IVideoStreamingState,
  action: VideoStreamingStateAction
): IVideoStreamingState => {
  switch (action.type) {
    case 'ASKING_FOR_PERMISSION':
      return {
        ...state,
        isPermissionModalOpen: action.payload.isModalOpen,
        triedToAskForPermission: true
      }
    case 'CAMERA_PERMISSION_RESULT':
      return {
        ...state,
        granted: action.payload.granted,
        isPermissionModalOpen: false,
        triedToAskForPermission: true
      }
    case 'CAMERA_START_RESULT':
      return {
        ...state,
        isPermissionModalOpen: false,
        hasPermissionError: action.payload.hasCameraError,
        triedToAskForPermission: true
      }
  }
}

const initialState: IVideoStreamingState = {
  triedToAskForPermission: false,
  hasPermissionError: undefined,
  granted: undefined,
  startStreaming: () => undefined,
  permissionsAreOk: async () => undefined,
  setPermissionGranted: () => undefined,
  isPermissionModalOpen: false
}

export const VideoStreamingContext = createContext<IVideoStreamingState>(
  initialState
)

const VideoStreamingState = ({ children }: VideoStreamingStateProps) => {
  const { user, hasGroup } = useContext(AuthContext)
  const [state, dispatch] = useReducer(reducer, initialState)
  const { t } = useTranslation()
  const history = useHistory()
  const ReactSwal = withReactContent(swal)

  const {
    isPermissionModalOpen,
    granted,
    hasPermissionError,
    triedToAskForPermission
  } = state

  const handleSuccess = useCallback(
    (stream) => {
      streamingManager.startStreamingForUser(user)
      videoManager.startRecordingForUser(stream, user)
      dispatch({
        type: 'CAMERA_START_RESULT',
        payload: { hasCameraError: false }
      })
    },
    [user]
  )

  const handleError = useCallback(() => {
    ReactSwal.fire({
      icon: 'error',
      text: t(
        'You refused to grant the necessary permissions, therefore you cannot proceed on the exams.'
      )
    }).then(() => {
      dispatch({
        type: 'CAMERA_START_RESULT',
        payload: { hasCameraError: true }
      })
    })
  }, [])

  const initializeStreamingChannels = () => {
    navigator.mediaDevices
      .getUserMedia({
        audio: true,
        video: {
          width: 320,
          height: 180
        }
      })
      .then(handleSuccess)
      .catch(handleError)
  }

  const startStreaming = useCallback(() => {
    if (
      !VIDEO_RECORDING_ENABLED ||
      !isAuthenticated() ||
      !hasGroup('ALUNO') ||
      isPermissionModalOpen ||
      granted !== undefined
    ) {
      return
    }
    dispatch({ type: 'ASKING_FOR_PERMISSION', payload: { isModalOpen: true } })
  }, [
    hasGroup,
    handleSuccess,
    handleError,
    user,
    isPermissionModalOpen,
    granted
  ])

  const setPermissionGranted = (granted: boolean) => {
    dispatch({ type: 'CAMERA_PERMISSION_RESULT', payload: { granted } })
  }

  const permissionsAreOk = async () => {
    try {
      const result = await navigator.permissions.query({ name: 'camera' })
      return result.state === 'granted' && !hasPermissionError && granted
    } catch (e) {
      console.warn(e)
      return !hasPermissionError && granted
    }
  }

  useEffect(() => {
    if (granted) {
      initializeStreamingChannels()
    }
  }, [user, granted])

  if (isPermissionModalOpen) {
    return (
      <Modal
        isOpen={isPermissionModalOpen}
        cancelText="Cancelar"
        actionText="OK"
        onAction={() => {
          dispatch({
            type: 'ASKING_FOR_PERMISSION',
            payload: { isModalOpen: false }
          })
          history.push('/camera-permission')
        }}
        onCancel={() => setPermissionGranted(false)}
        title={t('Permission to use camera and microphone')}
      >
        {t(
          'You have been assigned one or more exams which require camera and microphone access as a mandatory permission. If you refuse to grant, you will not be able to proceed.'
        )}
      </Modal>
    )
  }

  const contextValue = {
    isPermissionModalOpen,
    granted,
    hasPermissionError,
    triedToAskForPermission,
    startStreaming,
    permissionsAreOk,
    setPermissionGranted
  }

  return (
    <VideoStreamingContext.Provider value={contextValue}>
      {childrenIsFunction(children) ? children(contextValue) : children}
    </VideoStreamingContext.Provider>
  )
}

export default VideoStreamingState
