import React, {
  ReactNode,
  useContext,
  useState,
  useCallback,
  useEffect
} from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink, useRouteMatch, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { VIDEO_RECORDING_ENABLED, API_HOST } from '../../consts'
import { AuthContext } from '../../states/AuthState'
import { ICollection } from '../../types'
import Button from '../Button'
import Page from '../Page'
import AddStudentModal from './AddStudentModal'
import DashboardRouter from './DashboardRouter'
import Separator from './Separator'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowAltDown,
  faArrowAltUp
} from '@fortawesome/pro-regular-svg-icons'
import axios from 'axios'
import { RollbarErrorTracking } from 'utils/rollbar'
import RoomRecordModal from './RoomRecordModal'
import useQuery from 'hooks/useQuery'
import moment from 'moment'
import ExamDashboardExamInfo from './ExamDashboardExamInfo'

type HeaderProps = {
  children?: ReactNode
  className?: string
}

const Header = ({ className }: HeaderProps) => {
  const { t } = useTranslation()
  const { url } = useRouteMatch()
  const location = useLocation()
  const [additionalInfoVisible, setAdditionalInfoVisible] = useState(false)
  const query = useQuery()

  const { user, hasGroup } = useContext(AuthContext)
  const [roomId, setRoomId] = useState(-1)
  const [rooms, setRooms] = useState([])
  const [isAddStudentModalOpen, setIsAddStudentModalOpen] = useState(false)
  const [isRoomRecordModalOpen, setIsRoomRecordModalOpen] = useState(false)
  const [collection, setCollection] = useState<ICollection>(undefined)

  const isOnVideos = location.pathname.includes('/videos')

  const collectionId = +(query.get('collection') || '')

  const handleRoomSelect = (event) => {
    setRoomId(event.target.value)
  }

  const fetchRooms = useCallback(async () => {
    try {
      const response = await axios.get(`${API_HOST}/v1/rooms`)
      const rooms = response.data.results
      setRooms(rooms)
      if (rooms.length > 0) {
        setRoomId(rooms[0].id)
      }
    } catch (e) {
      RollbarErrorTracking.LogError(e)
    }
  }, [])

  const fetchCollection = useCallback(async () => {
    try {
      const response = await axios.get(
        `${API_HOST}/v1/collections/${collectionId}`
      )
      const collection = response.data
      setCollection(collection)
    } catch (e) {
      RollbarErrorTracking.LogError(e)
    }
  }, [collectionId])

  const collectionIsFinished = () => {
    if (!collection) {
      return false
    }

    const collectionFinishDate = collection.endTime
    const now = moment(new Date())
    const collectionEnd = moment(collectionFinishDate)

    return collectionEnd.isSameOrBefore(now)
  }

  useEffect(() => {
    fetchRooms()
  }, [fetchRooms])

  useEffect(() => {
    fetchCollection()
  }, [fetchCollection])

  return (
    <Page className={className} customWidth={1366}>
      <div className="sub-info">
        <ExamDashboardExamInfo collection={collection} />
        <div className="nav-links">
          {VIDEO_RECORDING_ENABLED && (
            <NavLink
              activeClassName="active"
              to={`${url}/videos?collection=${collectionId}`}
            >
              {t('See videos')}
            </NavLink>
          )}
          {/* <NavLink activeClassName="active" to={`${url}/statistics?collection=${collectionId}`}>{t('See statistics')}</NavLink> */}
          <NavLink
            activeClassName="active"
            to={`${url}/list?collection=${collectionId}`}
          >
            {t('See list')}
          </NavLink>
        </div>
        <div>
          {!collectionIsFinished() && isOnVideos && (
            <Button
              action="secondary"
              onClick={() => setIsRoomRecordModalOpen(true)}
            >
              {t('Records history')}
            </Button>
          )}
        </div>
        {hasGroup('ADMINISTRADOR') && (
          <Button
            className="show-lower-row"
            action="secondary"
            onClick={() => setAdditionalInfoVisible(!additionalInfoVisible)}
          >
            <FontAwesomeIcon
              data-testid="lower-row-trigger"
              icon={additionalInfoVisible ? faArrowAltUp : faArrowAltDown}
            ></FontAwesomeIcon>
          </Button>
        )}
      </div>
      {additionalInfoVisible && (
        <div className="lower-row">
          <div className="action-buttons">
            {!collectionIsFinished() && hasGroup('ADMINISTRADOR') && (
              <Button
                action="secondary"
                onClick={() => setIsAddStudentModalOpen(true)}
              >
                {t('Add Student')}
              </Button>
            )}
          </div>
          {isOnVideos && (
            <div>
              <label>{t('Room')} </label>
              <select
                style={{ width: '250px', height: '30px' }}
                onChange={handleRoomSelect}
                value={roomId}
                placeholder="Select a room"
              >
                {rooms.map((element) => {
                  return (
                    <option key={element.id} value={element.id}>
                      {element.name}
                    </option>
                  )
                })}
              </select>
            </div>
          )}
        </div>
      )}
      <Separator className="main-separator" />
      <AddStudentModal
        isOpen={isAddStudentModalOpen}
        setIsOpen={setIsAddStudentModalOpen}
        providerCodename={user?.provider?.codename}
      />
      <RoomRecordModal
        isOpen={isRoomRecordModalOpen}
        setIsOpen={setIsRoomRecordModalOpen}
        roomId={roomId}
      ></RoomRecordModal>
      <DashboardRouter roomId={roomId} />
    </Page>
  )
}

export default styled(Header)`
  .sub-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .lower-row {
    display: flex;
    justify-content: space-between;
    grid-template-columns: none;
  }

  .nav-links {
    /* Changed display from flex to none to deploy with this disabled in order for other work to be made */
    display: flex;
    font-weight: 600;
    font-size: 16px;

    .active {
      color: ${(props) => props.theme.clientColors.secondary};
      &::before {
        visibility: visible;
        transform: scaleX(1);
      }
    }

    a {
      color: ${(props) => props.theme.colors.grayDark};
      text-decoration: none;
      position: relative;
      margin-left: 1rem;
      &:hover {
        color: ${(props) => props.theme.clientColors.secondary};
        &::before {
          visibility: visible;
          transform: scaleX(1);
        }
      }

      &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 4px;
        bottom: -5px;
        left: 0;
        background-color: ${(props) => props.theme.clientColors.secondary};
        visibility: hidden;
        transform: scaleX(0);
        transition: all 0.3s ease-in-out 0s;
      }
    }
  }

  .show-lower-row {
    max-width: 100px;
  }

  .main-separator {
    margin-top: 1rem;
    margin-bottom: 2rem;
  }

  @media screen and (max-width: 800px) {
    .sub-info {
      flex-direction: column;
      align-items: unset;
    }

    .nav-links {
      flex-direction: column;

      a {
        margin-top: 8px;
        margin-bottom: 8px;
      }
    }
  }
`
