import { useField, useFormikContext } from 'formik'
import React from 'react'
import ReactSelect, { Props } from 'react-select'
import styled from 'styled-components'

interface InputSelectProps extends Props {
  label?: string
  className?: string
  name: string
  placeholder?: string
  loading?: boolean
}

const InputSelect = ({
  label,
  className,
  placeholder,
  name,
  loading,
  ...rest
}: InputSelectProps) => {
  const { setFieldValue } = useFormikContext()
  const [field] = useField({
    name
  })

  return (
    <div className={className}>
      {label && <span className="input-label">{label}</span>}
      <ReactSelect
        {...field}
        isClearable
        placeholder={placeholder || 'Buscar...'}
        loadingMessage={() => 'Carregando...'}
        isLoading={loading}
        noOptionsMessage={() => 'Nenhum resultado encontrado'}
        onChange={(value) => {
          setFieldValue(field.name, value)
        }}
        {...rest}
      />
    </div>
  )
}

export default styled(InputSelect)`
  .input-label {
    font-weight: bolder;
  }
`
