import axios from 'axios'
import React, { createContext, ReactNode, useEffect, useReducer } from 'react'
import { API_HOST } from '../consts'
import {
  childrenIsFunction,
  CollectionStateAction,
  ICollectionState
} from '../types'

type CollectionStateProps = {
  children: ReactNode
  collectionId: number
}

const reducer = (
  state: ICollectionState,
  action: CollectionStateAction
): ICollectionState => {
  switch (action.type) {
    case 'FETCH_COLLECTION':
      return { ...state, fetchingCollection: true, fetchCollectionError: '' }
    case 'FETCH_COLLECTION_SUCCESS':
      return {
        ...state,
        collection: action.payload,
        fetchingCollection: false,
        fetchCollectionError: ''
      }
    case 'FETCH_COLLECTION_ERROR':
      return {
        ...state,
        fetchingCollection: false,
        fetchCollectionError: action.payload
      }
    default:
      return state
  }
}

const initialState: ICollectionState = {
  collection: undefined,
  fetchingCollection: false,
  fetchCollectionError: ''
}

export const CollectionContext = createContext<ICollectionState>(initialState)

const CollectionState = ({ children, collectionId }: CollectionStateProps) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    ;(async () => {
      dispatch({ type: 'FETCH_COLLECTION' })
      try {
        const response = await axios.get(
          `${API_HOST}/v1/collections/${collectionId}`
        )
        dispatch({ type: 'FETCH_COLLECTION_SUCCESS', payload: response.data })
      } catch (e) {
        dispatch({ type: 'FETCH_COLLECTION_ERROR', payload: e })
      }
    })()
  }, [collectionId])

  const contextValue = {
    ...state,
    dispatch
  }
  return (
    <CollectionContext.Provider value={contextValue}>
      {childrenIsFunction(children) ? children(contextValue) : children}
    </CollectionContext.Provider>
  )
}

export default CollectionState
