import axios from 'axios'
import debounce from 'debounce-promise'
import { useFormikContext } from 'formik'
import React, { Fragment, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { OptionTypeBase } from 'react-select'
import styled from 'styled-components'
import { API_HOST } from '../../consts'
import { IHierarchyCategory } from '../../types'
import InputAsync from './InputAsync'

interface HierarchyInputAsyncProps extends OptionTypeBase {
  category: IHierarchyCategory
  distinct?: boolean
  parentId?: number | null
}

const HierarchyInputAsync = ({
  category,
  parentId,
  ...rest
}: HierarchyInputAsyncProps) => {
  const { t } = useTranslation()
  const formik = useFormikContext()
  const apiURL = () => {
    return `${API_HOST}/v1/hierarchies`
  }

  const fetchHierarchies = useCallback(
    debounce(async (search) => {
      formik.setFieldValue(category.codename, null)

      // If category has a parent, but it has not been selected yet,
      // the fetch should not be made.
      if (category.parentId && !parentId) {
        return Promise.resolve([])
      }

      const response = await axios.get(apiURL(), {
        params: {
          search,
          type: category.codename,
          parent_id: parentId
        }
      })
      return response.data.results
    }, 500),
    [category, parentId]
  )

  const getNoOptionsMessage = () => {
    if (!category.parent) {
      return t('No results found')
    }

    return t('Filter by "{{name}}" first', { name: category.parent.name })
  }

  return (
    <Fragment key={parentId || ''}>
      <InputAsync
        defaultOptions
        loadOptions={fetchHierarchies}
        label={category.name}
        name={category.codename}
        getOptionLabel={(d) => d.name}
        getOptionValue={(d) => d.name}
        noOptionsMessage={getNoOptionsMessage}
        {...rest}
      />
    </Fragment>
  )
}

export default styled(HierarchyInputAsync)``
