import jwt from 'jsonwebtoken'
import uuid from 'uuid'
import { ITheme, IUser } from '../types'

export const saveToken = (token: string) => {
  localStorage.setItem('token', token)
}

export const clearToken = () => {
  localStorage.removeItem('token')
}

export const getToken = (): string | null => {
  return localStorage.getItem('token')
}

export const isAuthenticated = (): boolean => {
  return !!getToken()
}

export const saveUser = (user: IUser) => {
  localStorage.setItem('user', JSON.stringify(user))
}

export const saveTheme = (theme: ITheme) => {
  localStorage.setItem('theme', JSON.stringify(theme))
}

export const getUser = () => {
  try {
    return JSON.parse(localStorage.getItem('user') || '')
  } catch (_) {
    return undefined
  }
}

export const getTheme = () => {
  try {
    return JSON.parse(localStorage.getItem('theme') || '')
  } catch (_) {
    return undefined
  }
}

export const clearUser = () => {
  localStorage.removeItem('user')
}

export const clearTheme = () => {
  localStorage.removeItem('theme')
}

export const getClientId = () => {
  const clientId = localStorage.getItem('clientId') || uuid.v4()
  localStorage.setItem('clientId', clientId)
  return clientId
}

export const getUserGroups = (): string[] => {
  const token = localStorage.getItem('token')
  if (token === null) {
    return []
  }
  const { groups } = jwt.decode(token)

  if (groups === undefined) {
    return []
  }

  return groups
}
