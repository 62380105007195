import { faBars } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import React, { useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import Stripe from './Stripe'

type IItem = {
  label: string
  path?: string
  disabled?: boolean
  children: IItem[]
}

type BreadcrumbProps = {
  className?: string
  items: Array<IItem>
}

const Breadcrumb = ({ className, items }: NonNullable<BreadcrumbProps>) => {
  const [menuOpening, setMenuOpening] = useState({})
  const [customMenuClass, setCustomMenuClass] = useState('responsive-menu')
  const history = useHistory()
  const location = useLocation()

  const setMenuOpen = (key: string, open: boolean) => {
    setMenuOpening((prevState) => ({
      ...prevState,
      [key]: open
    }))
  }

  const handleClick = (path: string | undefined, item: IItem) => {
    if (!path) {
      return
    }

    setMenuOpen(item.label, false)

    if (location.pathname === path) {
      window.location.href = path
    } else {
      history.push(path)
    }
  }

  const changeMenuClass = () => {
    let current = customMenuClass
    if (current === 'responsive-menu') {
      current = 'responsive-menu mobile-show'
    } else {
      current = 'responsive-menu'
    }
    setCustomMenuClass(current)
  }

  // if there are no items, no point in
  // going ahead
  if (items.length === 0) {
    return <Stripe className={className}></Stripe>
  }

  return (
    <Stripe className={className}>
      <FontAwesomeIcon
        onClick={changeMenuClass}
        className="responsive-icon"
        icon={faBars}
      ></FontAwesomeIcon>
      <ul className={customMenuClass}>
        {items &&
          items.map((item) => {
            return (
              <li
                key={item.label}
                onMouseEnter={() => setMenuOpen(item.label, true)}
                onMouseLeave={() => setMenuOpen(item.label, false)}
              >
                {item.label.toUpperCase()}
                {menuOpening[item.label] && (
                  <div className="menu">
                    {item.children.map((child) => {
                      return (
                        <span
                          key={child.label}
                          onClick={() => handleClick(child.path, item)}
                          className={classNames('menu-children', {
                            disabled: !!child.disabled
                          })}
                        >
                          {child.label}
                        </span>
                      )
                    })}
                  </div>
                )}
              </li>
            )
          })}
      </ul>
    </Stripe>
  )
}

export default styled(Breadcrumb)`
  padding-bottom: 4px;

  ul {
    margin: 0px;
    list-style: none;
    font-weight: bold;
    font-size: 14px;
    grid-template-columns: 20% 20% 20% 20% 20% !important;
  }

  li {
    color: white;
    padding: 0px;
    display: inline;
    cursor: pointer;
    font-size: 15px;
    width: 150px;
    text-align: left;

    a {
      color: white;
    }
  }

  .menu {
    position: absolute;
    display: grid;
    margin-top: 0px;
    background-color: white;
    color: gray;
    padding: 0px;
    text-align: left;
    border-radius: 5px;
    border: 1px solid #ccc;
  }

  .menu-children {
    cursor: pointer;
    padding: 10px;
    font-size: 12px;

    :first-child {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }

    :last-child {
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }

    :not(:last-child) {
      border-bottom: 1px solid #ccc;
    }

    &.disabled {
      opacity: 50%;
      cursor: not-allowed;
    }
  }

  .menu-children:hover {
    background-color: #f4f4f4;
    /* border-radius: 5px; */
  }

  .responsive-icon {
    display: none;
    color: white;
    font-size: 25px;
    margin-top: -5px;
    cursor: pointer;
  }

  @media screen and (max-width: 800px) {
    .responsive-menu {
      display: block;
    }
    .responsive-menu li {
      display: none;
    }
    .responsive-icon {
      float: right;
      display: block;
    }
  }

  @media screen and (max-width: 800px) {
    .responsive-menu.mobile-show {
      position: relative;
    }
    .responsive-menu.mobile-show li {
      float: none;
      display: block;
      text-align: left;
      padding: 20px;
    }
  }
`
