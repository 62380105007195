import React from 'react'
import { browserName } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import LogoPage from '../components/LogoPage'
import IconButton from '../components/IconButton'

type BrowserCompatibilityProps = {
  className?: string
}

const BrowserCompatibility = ({ className }: BrowserCompatibilityProps) => {
  const { t } = useTranslation()
  return (
    <LogoPage className={className}>
      <>
        <h1>
          {t('Your browser is incompatible with our system', { browserName })}
        </h1>
        <p>
          {t(
            'Download the latest version of Google Chrome, Safari, Firefox or Opera.'
          )}
        </p>
        <div className="browsers">
          <IconButton
            label="Chrome"
            link={`https://www.google.com/intl/${t('en_us')}/chrome/`}
            icon={['fab', 'chrome']}
          />

          <IconButton
            label="Firefox"
            link={t(`https://www.mozilla.org/${t('en-US')}/firefox/new/`)}
            icon={['fab', 'firefox-browser']}
          />

          <IconButton
            label="Safari"
            link={t('https://www.apple.com/safari/')}
            icon={['fab', 'safari']}
          />

          <IconButton
            label="Opera"
            link={t('https://www.opera.com/')}
            icon={['fab', 'opera']}
          />
        </div>
      </>
    </LogoPage>
  )
}

export default styled(BrowserCompatibility)`
  text-align: center;
  max-width: 100%;

  img {
    margin-bottom: 0;
  }
  .browsers {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
  }
`
