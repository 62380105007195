import React from 'react'
import { IDashboardPreFilterState } from 'types'
import styled from 'styled-components'
import devices from 'utils/devices'
import DashboardPreFilterState from 'states/DashboardPrefilterState'
import Page from 'components/Page'
import Skeleton from 'react-loading-skeleton'
import CardList from 'components/CardList'
import CardListToolbar from 'components/CardListToolbar'
import ResultsFound from 'components/ResultsFound'
import Separator from 'components/ExamDashboardComponents/Separator'
import DashBoardPreFilterCard from 'components/DashboardPreFilterCard'
import SearchBarWrapper from 'components/ExamDashboardComponents/SearchBarWrapper'
import PaginationFooter from 'components/PaginationFooter'

type DashboardPreFilterProps = {
  className?: string
}

const DashboardPreFilter = ({ className }: DashboardPreFilterProps) => {
  return (
    <Page customWidth={1366}>
      <Separator className="main-separator" />
      <DashboardPreFilterState>
        {({
          isLoading,
          results,
          totalResults,
          onSearchChange,
          pageSize,
          numPages,
          handlePageChange,
          onPageSizeChange
        }: IDashboardPreFilterState) => (
          <div className={className}>
            <SearchBarWrapper
              placeholder="Search..."
              onSearchChange={onSearchChange}
            />
            <div className="parent-wrapper">
              <div className="card-list-wrapper">
                <CardList>
                  {isLoading && <Skeleton height={150} count={5} />}
                  <CardListToolbar>
                    {totalResults !== undefined && (
                      <ResultsFound resultsFound={totalResults} />
                    )}
                  </CardListToolbar>
                  {!isLoading &&
                    results.map((collectionPreFilter) => (
                      <DashBoardPreFilterCard
                        key={collectionPreFilter.id}
                        dashboardPreFilter={collectionPreFilter}
                      />
                    ))}
                </CardList>
                <PaginationFooter
                  pageSize={pageSize}
                  pageCount={numPages}
                  onPageChange={handlePageChange}
                  onPageSizeChange={onPageSizeChange}
                >
                  <p>
                    Mostrando {results.length} de {totalResults} registros.
                  </p>
                </PaginationFooter>
              </div>
            </div>
          </div>
        )}
      </DashboardPreFilterState>
    </Page>
  )
}

export default styled(DashboardPreFilter)`
  .parent-wrapper {
    display: flex;
  }
  @media (max-width: ${devices.mobileL}) {
    .parent-wrapper {
      flex-direction: column;
      row-gap: 20px;
      column-gap: 0;
    }
  }

  .filter-wrapper {
    max-width: 400px;

    @media (min-width: ${devices.tablet}) {
      min-width: 400px;
      column-gap: 20px;
    }
  }

  .card-list-wrapper {
    flex-grow: 6;
  }
`
