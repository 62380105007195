import React from 'react'
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom'
import ExamDashboard from '../../pages/ExamDashboard'
import DashboardVideos from '../../pages/DashboardVideos'
import { VIDEO_RECORDING_ENABLED } from '../../consts'
import useQuery from 'hooks/useQuery'

type DashboardRouterProps = {
  roomId: number
}

const DashboardRouter = ({ roomId }: DashboardRouterProps) => {
  const { path } = useRouteMatch()
  const query = useQuery()
  const collectionId = +(query.get('collection') || '')

  if (!collectionId) {
    return <Redirect to="/exams/dashboard-pre-filter"></Redirect>
  }

  return (
    <Switch>
      <Route exact path={path}>
        <Redirect to={`${path}/list`} />
      </Route>
      <Route path={`${path}/list`} component={ExamDashboard} />
      {/* <Route path={`${path}/statistics`} component={DashboardStatistics} /> */}
      {VIDEO_RECORDING_ENABLED && (
        <Route
          path={`${path}/videos`}
          render={() => <DashboardVideos roomId={roomId}></DashboardVideos>}
        />
      )}
    </Switch>
  )
}

export default DashboardRouter
