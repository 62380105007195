import React, { Fragment, ReactNode, ErrorInfo } from 'react'
import { RollbarErrorTracking } from '../utils/rollbar'
import Catch from '../utils/error-boundary'
import { Redirect } from 'react-router-dom'

type Props = {
  children: ReactNode
}

const ErrorBoundaryRollbar = Catch(function ErrorBoundaryRollbar(
  props: Props,
  error?: Error,
  info?: ErrorInfo
) {
  if (error) {
    RollbarErrorTracking.LogError(error)
    return <Redirect to="/error" />
  } else if (info) {
    RollbarErrorTracking.logInfo(info)
  } else {
    return <Fragment>{props.children}</Fragment>
  }
})

export default ErrorBoundaryRollbar
