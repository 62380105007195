import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import Page from '../components/Page'
import ComparativeBoard from '../components/ReportCard/ComparativeBoard'
import ComparativeChart from '../components/ReportCard/ComparativeChart'
import ExamsTable from '../components/ReportCard/ExamsTable'
import ReportCardHeader from '../components/ReportCard/Header'
import Stripe from '../components/Stripe'
import IndividualReportCardState from '../states/IndividualReportCardState'

type IndividualReportCardamDashboardProps = {
  className?: string
}

const IndividualReportCard = ({
  className
}: IndividualReportCardamDashboardProps) => {
  const { reportCardId } = useParams()
  const { t } = useTranslation()

  return (
    <IndividualReportCardState IndividualReportCardId={reportCardId}>
      <div className={className}>
        <Stripe title={t('Individual Report Card')} />
        <Page>
          <ReportCardHeader />
          <ComparativeBoard />
          <ExamsTable />
          <ComparativeChart />
        </Page>
      </div>
    </IndividualReportCardState>
  )
}

export default styled(IndividualReportCard)`
  span {
    font-size: 11pt;
  }
  h2 {
    text-align: center;
    font-weight: 700;
    font-size: 14pt;
  }
`
