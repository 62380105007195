import React, {
  createContext,
  useEffect,
  useReducer,
  ReactElement
} from 'react'
import { useTranslation } from 'react-i18next'
import {
  INetworkState,
  INetworkStateAction,
  childrenIsFunction
} from '../types'

type NetworkProps = {
  children: Function | ReactElement
}

const reducer = (
  state: INetworkState,
  action: INetworkStateAction
): INetworkState => {
  switch (action.type) {
    case 'SET_OFFLINE_MESSAGE':
      return {
        ...state,
        offlineMessage: action.payload.message,
        isConnected: action.payload.isConnected
      }
    default:
      return state
  }
}

const initialState: INetworkState = {
  dispatch: () => {},
  offlineMessage: '',
  isConnected: true
}

export const NetworkContext = createContext<INetworkState>(initialState)

const NetworkState = ({ children }: NetworkProps) => {
  const { t } = useTranslation()
  const [state, dispatch] = useReducer(reducer, initialState)
  const { offlineMessage } = state

  const changeNetworkStatus = () => {
    if (navigator.onLine && offlineMessage) {
      const message = t('Internet connection successfully reestablished')
      dispatch({
        type: 'SET_OFFLINE_MESSAGE',
        payload: { message, isConnected: true }
      })
      setTimeout(
        () =>
          dispatch({
            type: 'SET_OFFLINE_MESSAGE',
            payload: { message: '', isConnected: true }
          }),
        5000
      )
    } else if (!offlineMessage) {
      const message = t(
        'Lost internet connection. Please re-establish your connection to proceed.'
      )
      dispatch({
        type: 'SET_OFFLINE_MESSAGE',
        payload: { message, isConnected: false }
      })
    }
  }

  // Add listeners to check network status
  useEffect(() => {
    window.addEventListener('online', changeNetworkStatus)
    window.addEventListener('offline', changeNetworkStatus)
    return () => {
      window.removeEventListener('online', changeNetworkStatus)
      window.removeEventListener('offline', changeNetworkStatus)
    }
  })

  const contextValue = { ...state }

  return (
    <NetworkContext.Provider value={contextValue}>
      {childrenIsFunction(children) ? children(contextValue) : children}
    </NetworkContext.Provider>
  )
}

export default NetworkState
