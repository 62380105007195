import Carousel from '@brainhubeu/react-carousel'
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classnames from 'classnames'
import React, { useContext, useEffect, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import styled from 'styled-components'
import { AnswerContext } from '../../states/AnswerState'
import { ApplicationContext } from '../../states/ApplicationState'
import CircledButton from '../CircledButton'

type ItemNavigationProps = {
  className?: string
}

const ItemNavigation = ({ className }: ItemNavigationProps) => {
  const { answers, application } = useContext(ApplicationContext)
  const { answer, goAnswer, getAnswerFromPosition, isAnswered } = useContext(
    AnswerContext
  )
  const [position, setPosition] = useState((answer?.position || 1) - 1)
  const [slidesPerPage, setSlidesPerPage] = useState(0)

  useEffect(() => {
    if (!answer) {
      return
    }

    setPosition(answer.position - 1)
  }, [answer])

  const toAnswer = (position: number) => {
    if (position !== undefined || position !== null) {
      const newAnswer = getAnswerFromPosition(position)
      goAnswer(newAnswer)
    }
  }

  const updateDimensions = () => {
    const arrowWidth = 42
    const itemWidth = 70
    setSlidesPerPage(
      Math.floor((window.innerWidth - 2 * arrowWidth) / itemWidth)
    )
  }

  useEffect(() => {
    updateDimensions()
    window.addEventListener('resize', updateDimensions)
    return () => {
      window.removeEventListener('resize', updateDimensions)
    }
  }, [])

  if (answers.length === 0) {
    return <Skeleton width="100%" height={62} />
  }

  return (
    <div className={className}>
      <Carousel
        centered
        clickToChange
        arrows
        addArrowClickHandler
        value={position}
        slidesPerScroll={Math.floor(slidesPerPage / 2)}
        slidesPerPage={slidesPerPage}
        onChange={setPosition}
        arrowLeft={
          <CircledButton>
            <FontAwesomeIcon icon={faAngleLeft} />
          </CircledButton>
        }
        arrowRight={
          <CircledButton>
            <FontAwesomeIcon icon={faAngleRight} />
          </CircledButton>
        }
      >
        {answers.map((ans) => (
          <CircledButton
            key={ans.id}
            className={classnames({
              active: ans.position === answer?.position,
              highlighted: isAnswered(ans)
            })}
            onClick={() => toAnswer(ans.position)}
            disabled={!application?.exam.canBrowseAcrossItems}
          >
            {ans.position}
          </CircledButton>
        ))}
      </Carousel>
    </div>
  )
}

export default styled(ItemNavigation)`
  max-width: 1000px;
  margin: auto;
  > div {
    padding-top: 15px;
    padding-bottom: 15px;
  }
`
