import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import ReactPaginate from 'react-paginate'
import styled from 'styled-components'

type PaginateProps = {
  pageCount: number
  initialPage?: number
  onPageChange: (page: number) => void
  className?: string
}

const Paginate = ({
  pageCount,
  initialPage = 0,
  onPageChange,
  className
}: PaginateProps) => {
  const handlePageChange = (selectedItem: { selected: number }) => {
    onPageChange(selectedItem.selected + 1)
  }

  const previousLabel = <FontAwesomeIcon icon="arrow-left" />
  const nextLabel = <FontAwesomeIcon icon="arrow-right" />

  return (
    <div className={className}>
      <ReactPaginate
        pageCount={pageCount}
        pageRangeDisplayed={3}
        marginPagesDisplayed={1}
        containerClassName="pagination"
        nextLinkClassName="nav"
        previousLinkClassName="nav"
        initialPage={initialPage}
        previousLabel={previousLabel}
        nextLabel={nextLabel}
        breakClassName="break-me"
        onPageChange={handlePageChange}
        activeLinkClassName="active"
        pageLinkClassName="page"
        breakLinkClassName="page"
        disabledClassName="disabled"
      />
    </div>
  )
}

export default styled(Paginate)`
  .nav {
    font-size: 14px;
    color: ${(props) => props.theme.clientColors.secondary};
    box-shadow: 0px 1px 1px #00000029;
    padding: 5px 15px;
    background: #ffffff 0% 0% no-repeat padding-box;
    z-index: -2;
    font-weight: 600;
    &:hover {
      cursor: pointer;
      box-shadow: 0px 5px 5px #00000029;
    }
  }

  .disabled {
    color: ${(props) => props.theme.colors.lightGray};
  }

  .page {
    z-index: -1;
    font-size: 14px;
    color: black;
    font-weight: bold;
    background: white 0% 0% no-repeat padding-box;
    outline: 1px solid #d0d0d0;
    padding: 5px 15px;
    &:hover {
      cursor: pointer;
      box-shadow: 0px 5px 5px #00000029;
    }
  }

  .active {
    background: #f8f8f8 0% 0% no-repeat padding-box;
  }

  .pagination {
    @extend %default-transition;
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    list-style: none;
    font-size: 14px;
    margin: none;
    padding: none;
  }
`
